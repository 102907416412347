import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { defaultSocketConfig, REQUEST_CODE, SOCKET_URL } from '../../configs/socketConfig';
import {
    useAppDispatch,
    useAppSelector,
} from '../../providers/ReduxProvider/app/hooks';
import { setTickers, TickersOrderBy, TickersSortingOrder } from '../../providers/ReduxProvider/app/store';
import { useCustomWebSocket } from './useCustomWebSocket';

const TICKERS_RESPONSE_TYPE = ['tickers', 'tickers.volume.asc', 'tickers.volume.desc', 'tickers.price_change.asc', 'tickers.price_change.desc'];

export function useTickers(path?: string) {
    const dispatch = useAppDispatch();
    const router = useRouter();
    const currentMarket = useAppSelector(
        (state) => state.markets.currentMarket
    );

    const reconnect = useAppSelector((state) => state.globalSettings.wsReconnect);
    const orderBy = useAppSelector((state) => state.tickers.orderBy);
    const sortingOrder = useAppSelector((state) => state.tickers.sortingOrder);

    const [prevOrderBy, setPrevOrderBy] = useState<TickersOrderBy>(TickersOrderBy.UNSPECIFIED); 
    const [prevSortingOrder, setPrevSortingOrder] = useState<TickersSortingOrder>(TickersSortingOrder.UNSPECIFIED);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [prevMarketId, setPrevMarketId] = useState<string>('');

    const { sendJsonMessage, lastJsonMessage, socketURL } = useCustomWebSocket(
        'use tickers',
        SOCKET_URL,
        defaultSocketConfig,
    );

    const paths = ['/trading/[marketID]', '/balances', path].filter(Boolean);

    const marketOrderIncrement = useMemo(() => {
        if (currentMarket) 
            return [`${currentMarket.id}.ob-inc`];
        return [];
    }, [currentMarket]);

    const getTickersOrderBy = (orderBy: TickersOrderBy) => {
        switch (orderBy) {
            case TickersOrderBy.PRICE_CHANGE:
                return '.price_change';
            case TickersOrderBy.VOLUME:
                return '.volume';
            case TickersOrderBy.UNSPECIFIED:
            default:
                return '';
        }
    };

    const getTickersSortingOrder = (sortingOrder: TickersSortingOrder) => {
        switch (sortingOrder) {
            case TickersSortingOrder.ASCENDING:
                return '.asc';
            case TickersSortingOrder.DESCENDING:
                return '.desc';
            case TickersSortingOrder.UNSPECIFIED:
            default:
                return '';
        }
    };

    useEffect(() => {
        if (prevOrderBy === orderBy && prevSortingOrder === sortingOrder && loaded && prevMarketId === currentMarket?.id) {
            return;
        }

        const subscribeStreamInfo = ['public', [`tickers${getTickersOrderBy(orderBy)}${getTickersSortingOrder(sortingOrder)}`, ...marketOrderIncrement]];
        const unsubscribeStreamInfo = ['public', [`tickers${getTickersOrderBy(prevOrderBy)}${getTickersSortingOrder(prevSortingOrder)}`, ...marketOrderIncrement]];

        socketURL && paths.includes(router?.pathname) && marketOrderIncrement.length != 0 && sendJsonMessage([REQUEST_CODE, 1, 'subscribe', subscribeStreamInfo as any]);

        setPrevOrderBy(orderBy);
        setPrevSortingOrder(sortingOrder);
        setPrevMarketId(currentMarket?.id || '');

        socketURL && paths.includes(router?.pathname) && !loaded && setLoaded(true);

        return () => {
            currentMarket && socketURL && paths.includes(router?.pathname) && marketOrderIncrement.length != 0 && loaded && sendJsonMessage([REQUEST_CODE, 1, 'unsubscribe', unsubscribeStreamInfo as any])
        }
    }, [marketOrderIncrement, reconnect, socketURL, currentMarket, router?.pathname, prevOrderBy, orderBy, prevSortingOrder, sortingOrder, loaded]);

    useEffect(() => {
        if (!lastJsonMessage) {
            return;
        }
        // response from websocket will look like this: [3,"tickers",[["wbtcusdt",1642702103.211,0.001019370978882791,0.14780879193800467,0.13659571117029398,0.13145361480357345,10,15.290564683241865,0,-3.91171925884593]]]
        // so we listen to it and save the result into Redux store
        const [, responseType, result = []] = lastJsonMessage as any;

        if (TICKERS_RESPONSE_TYPE.find(type => type === responseType)) {
            dispatch(setTickers(result));
        }
    }, [lastJsonMessage]);
}
