import {
    BACKGROUND_COLOR_GROUP,
    CTA_COLOR_GROUP,
    SIDEBAR_COLOR_GROUP,
    TEXT_COLOR_GROUP,
} from './ColorTitles'
import { convert2rgb, rgb2hex } from './convert'
import { ColorValues, Shade, Tint } from './types'

const Values = require('values.js')
const ColorContrastChecker = require('color-contrast-checker')

export const RATIO = 4.5

const WEIGHT_STEP = 5;
let rootElement: any;

const getTintAndShade = (values: any, hex: string, step: number) => {
    values.setColor(hex)

    const pallete = values.all(step)
    const tints = pallete.filter((item: any) => item.type === 'tint').reverse()
    const shades = pallete.filter((item: any) => item.type === 'shade')
    const main = pallete.filter((item: any) => item.weight === 0)

    return {
        main,
        tints,
        shades,
    }
}

const findByTint = (tints: ColorValues['tints'], step: number) =>
    tints.find((item: Tint) => item.weight === step)

const findByShade = (shades: ColorValues['shades'], step: number) =>
    shades.find((item: Shade) => item.weight === step)

const applyNewColors = (
    colorGroup: any[],
    colors: ColorValues,
    hex: string,
) => {
    if (rootElement) {
        colorGroup.map((colorGroupItem: any) => {
            if (colorGroupItem.type === 'tint') {
                colors.tints.map((tint: Tint) => {
                    if (colorGroupItem.weight === tint.weight) {
                        rootElement.style.setProperty(
                            `--${colorGroupItem.key}`,
                            rgb2hex(convert2rgb(tint)),
                        )
                    }
                })
            } else if (colorGroupItem.type === 'shade') {
                colors.shades.map((shade: Shade) => {
                    if (colorGroupItem.weight === shade.weight) {
                        rootElement.style.setProperty(
                            `--${colorGroupItem.key}`,
                            rgb2hex(convert2rgb(shade)),
                        )
                    }
                })
            } else {
                rootElement.style.setProperty(`--${colorGroupItem.key}`, hex)
            }
        })
    }
}

const applyNewLayerColors = (colorGroup: any[], ctaColor: string) => {
    const contrastChecker = new ColorContrastChecker()

    const WHITE = '#ffffff'
    const BLACK = '#000000'

    if (rootElement) {
        if (contrastChecker.isLevelCustom(ctaColor.slice(0, 7), WHITE, RATIO)) {
            colorGroup.map((item: any) => {
                rootElement.style.setProperty(`--${item.key}`, WHITE)
            })
        } else {
            colorGroup.map((item: any) => {
                rootElement.style.setProperty(`--${item.key}`, BLACK)
            })
        }
    }
}

const generateNewColors = (colorName: string, hex: string, newRootElement: any, contrastBaseColor: string) => {
    const values = new Values()

    rootElement = newRootElement;

    // IMPORTANT: not change the root element, we getting data for BE from these elements
    rootElement && rootElement.style.setProperty(
        `--${colorName}`,
        hex,
    )

    switch (colorName) {
        case 'app-main-background-color':
            rootElement && BACKGROUND_COLOR_GROUP.map((colorTitle: any) => {
                rootElement.style.setProperty(`--${colorTitle.key}`, hex)
            })

            break
        case 'app-navbar-color':
            let sidebarColorGroupLayer = SIDEBAR_COLOR_GROUP.layerLight
            let sidebarColorGroupBackground = SIDEBAR_COLOR_GROUP.backgroundLight

            if (contrastBaseColor !== 'light') {
                sidebarColorGroupLayer = SIDEBAR_COLOR_GROUP.layerDark
                sidebarColorGroupBackground = SIDEBAR_COLOR_GROUP.backgroundDark
            }

            const navbarColors = getTintAndShade(values, hex, WEIGHT_STEP)

            const rgbNavbarControlBg = contrastBaseColor === 'light' ? findByTint(navbarColors.tints, 45) : navbarColors.main[0]
            const navbarControlBgColor = rgb2hex(
                convert2rgb(rgbNavbarControlBg),
            )
            const navbarControlBgColors = getTintAndShade(
                values,
                navbarControlBgColor,
                WEIGHT_STEP,
            )

            applyNewColors(sidebarColorGroupLayer, navbarColors, hex)
            applyNewColors(
                sidebarColorGroupBackground,
                navbarControlBgColors,
                navbarControlBgColor,
            )

            break
        case 'app-text-color':
            let textColorGroup = TEXT_COLOR_GROUP.textColorLight
            let neutralControlColorGroup = TEXT_COLOR_GROUP.neutralControlLight
            let neutralContolLayerGroup = TEXT_COLOR_GROUP.neutralContolLayerLight
            let dividerGroup = TEXT_COLOR_GROUP.dividerLight

            const textColors = getTintAndShade(values, hex, WEIGHT_STEP)

            const rgbNeutralControl = contrastBaseColor === 'light' ? findByTint(textColors.tints, 75) : findByShade(textColors.shades, 60)
            const neutralControlColor = rgb2hex(convert2rgb(rgbNeutralControl))
            const neutralControlColors = getTintAndShade(
                values,
                neutralControlColor,
                WEIGHT_STEP,
            )

            const rgbNeutralControlLayer = contrastBaseColor === 'light' ? findByShade(textColors.shades, 45) : findByTint(textColors.tints, 5)
            const neutralControlLayerColor = rgb2hex(
                convert2rgb(rgbNeutralControlLayer),
            )
            const neutralControlLayerColors = getTintAndShade(
                values,
                neutralControlLayerColor,
                WEIGHT_STEP,
            )

            const rgbDivider = contrastBaseColor === 'light' ? findByTint(textColors.tints, 60) : findByShade(textColors.shades, 65)
            const dividerColor = rgb2hex(convert2rgb(rgbDivider))
            const dividerColors = getTintAndShade(values, dividerColor, WEIGHT_STEP)

            if (contrastBaseColor !== 'light') {
                textColorGroup = TEXT_COLOR_GROUP.textColorDark
                neutralControlColorGroup = TEXT_COLOR_GROUP.neutralControlDark
                neutralContolLayerGroup = TEXT_COLOR_GROUP.neutralContolLayerDark
                dividerGroup = TEXT_COLOR_GROUP.dividerDark
            }

            applyNewColors(textColorGroup, textColors, hex)
            applyNewColors(
                neutralControlColorGroup,
                neutralControlColors,
                neutralControlColor,
            )
            applyNewColors(
                neutralContolLayerGroup,
                neutralControlLayerColors,
                neutralControlLayerColor,
            )
            applyNewColors(
                dividerGroup,
                dividerColors,
                dividerColor,
            )

            break
        case 'app-primary-cta-color':
            let ctaColorGroupPrimary = CTA_COLOR_GROUP.primaryLight

            if (contrastBaseColor !== 'light') {
                ctaColorGroupPrimary = CTA_COLOR_GROUP.primaryDark
            }

            const ctaColor = getTintAndShade(values, hex, WEIGHT_STEP)

            applyNewColors(ctaColorGroupPrimary, ctaColor, hex)
            applyNewLayerColors(CTA_COLOR_GROUP.primaryLayer, hex)

            break
        case 'app-secondary-cta-color':
            let ctaColorGroupSecondary = CTA_COLOR_GROUP.secondaryLight

            if (contrastBaseColor !== 'light') {
                ctaColorGroupSecondary = CTA_COLOR_GROUP.secondaryDark
            }

            const ctaSecondaryColor = getTintAndShade(values, hex, WEIGHT_STEP)

            applyNewColors(ctaColorGroupSecondary, ctaSecondaryColor, hex)
            applyNewLayerColors(CTA_COLOR_GROUP.secondaryLayer, hex)

            break
        default:
            break
    }
}

export default generateNewColors;
