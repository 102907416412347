import Image from 'next/image';
import React from 'react';
import fetch from 'node-fetch';

interface CryptoSVGProps {
    name: string;
    className?: string;
    style?: 'black' | 'color' | 'icon' | 'white';
    width?: number;
    height?: number;
}

export const CryptoSVG: React.FC<CryptoSVGProps> = ({
    name,
    style = 'color',
    height = 32,
    width = 32,
    className,
}: CryptoSVGProps) => {
    const DEFAULT_IMG_SRC = '/images/defaultCryptoIcon.svg';
    const [path, setPath] = React.useState<string>('');

    const iconUrl = React.useMemo(() => {
        return `https://cdn.jsdelivr.net/npm/cryptocurrency-icons@latest/svg/${style}/${name}.svg`;
    }, [style, name]);

    React.useEffect(() => {
        fetch(iconUrl).then((response) => {
            if (response.ok) {
                setPath(iconUrl);
            } else {
                setPath(DEFAULT_IMG_SRC);
            }
        });
    }, [iconUrl]);

    return path ? (
        <Image
            src={path}
            height={height}
            width={width}
            className={className}
            quality={100}
            alt={`${name} icon`}
        />
    ) : (
        <div style={{ width: `${width}px`, height: `${height}px` }} />
    );
};
