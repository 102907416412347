import React, { FC } from 'react';
import classNames from 'classnames';
import { Footer, FooterProps } from '../Footer'
import { Header, HeaderProps } from '../Header'
import { Sidebar, SidebarProps } from '../Sidebar';

export interface LayoutProps {
    /**
     * Footer menu content
     * all props which was declared
     * inside footer component
     */
    footerProps?: FooterProps;
    /**
     * Header menu content
     * all props which was declared
     * inside header component
     */
    headerProps?: HeaderProps;
    /**
    * Sidebar content
    * all props which was declared
    * inside sidebar component
    */
    sidebarProps?: SidebarProps;
    /**
     * Application code
     */
    children?: React.ReactNode;
    /**
     * Container class name
     */
    containerClassName?: string;
    /**
     * Custom footer component
     */
    customFooter?: React.ReactNode;
    /**
     * Custom header component
     */
    customHeader?: React.ReactNode;
    /**
     * Main class name
     */
    mainClassName?: string;
    /**
     * Main and Header container classname
     */
    wrapperClassname?: string
}

export const Layout: FC<LayoutProps> = ({
    sidebarProps,
    footerProps,
    children,
    containerClassName,
    headerProps,
    customFooter,
    customHeader,
    mainClassName = 'flex-1 flex flex-col relative overflow-y-auto focus:outline-none',
    wrapperClassname = 'flex flex-col w-0 flex-1',
}: LayoutProps) => {
    return (
        <div className="flex">
            <Sidebar {...sidebarProps} />
            <div className={wrapperClassname}>
                {customHeader || <Header {...headerProps} />}
                <main className={mainClassName}>
                    <div className={classNames('flex-grow', containerClassName)}>
                        {children}
                    </div>
                    {customFooter || <Footer {...footerProps} />}
                </main>
            </div>
        </div>
    );
};
