import classnames from 'classnames';
import React from 'react';

export interface SearchBarProps {
    /**
     * Class names for main box of SearchBar
     * Multiple class names can be separated by spaces
     */
    mainClassName?: string;
    /**
     * Class names for box, where icon allocated
     * Multiple class names can be separated by spaces
     */
    iconClassName?: string;
    /**
     * Class names for input field
     * Multiple class names can be separated by spaces
     */
    inputClassName?: string;
    /**
     * Placeholder in input field
     */
    placeholder?: string | React.ReactNode;
    /**
     * Icon Element
     * default: search loop
     */
    icon?: JSX.Element;
    /**
     * Function that calls on each update of input field
     */
    onChange?: (value: string) => void;
    /**
     * Enable reset search
     */
    enableReset?: boolean;
    /**
     * Icon for reset search input
     */
    resetIcon?: JSX.Element;
    /**
     * Class names for reset search input icon
     */
    resetIconClassName?: string;
    /**
     * Callback function after click on reset icon
     */
    onReset?: () => void;
    /**
     * Autofocus search input
     */
    autoFocus?: boolean;
    /**
     * Regular expression to validate input values
     */
    inputRegex?: RegExp;
    /**
     * Default search value
     */
    defaultValue?: string;
}

export const SearchBar = ({
    mainClassName = 'rounded-md relative shadow-sm duration-100',
    iconClassName = 'absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none',
    inputClassName = 'focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 py-2 sm:text-md rounded-md placeholder-gray-500',
    placeholder = '',
    icon = (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.77344 13.0859C8.5026 13.8151 9.38802 14.1797 10.4297 14.1797C11.4714 14.1797 12.3568 13.8151 13.0859 13.0859C13.8151 12.3568 14.1797 11.4714 14.1797 10.4297C14.1797 9.38802 13.8151 8.5026 13.0859 7.77344C12.3568 7.04427 11.4714 6.67969 10.4297 6.67969C9.38802 6.67969 8.5026 7.04427 7.77344 7.77344C7.04427 8.5026 6.67969 9.38802 6.67969 10.4297C6.67969 11.4714 7.04427 12.3568 7.77344 13.0859ZM15.4297 14.1797L19.5703 18.3203L18.3203 19.5703L14.1797 15.4297V14.7656L13.9453 14.5312C12.9557 15.3906 11.7839 15.8203 10.4297 15.8203C8.91927 15.8203 7.63021 15.2995 6.5625 14.2578C5.52083 13.2161 5 11.9401 5 10.4297C5 8.91927 5.52083 7.64323 6.5625 6.60156C7.63021 5.53385 8.91927 5 10.4297 5C11.9401 5 13.2161 5.53385 14.2578 6.60156C15.2995 7.64323 15.8203 8.91927 15.8203 10.4297C15.8203 10.9766 15.6901 11.6016 15.4297 12.3047C15.1693 12.9818 14.8698 13.5286 14.5312 13.9453L14.7656 14.1797H15.4297Z"
                fill="#5B6488"
            />
        </svg>
    ),
    onChange = () => {},
    enableReset,
    resetIconClassName = 'absolute inset-y-0 right-0 pr-2 flex items-center hover:cursor-pointer',
    resetIcon = (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM6.70711 5.29289C6.31658 4.90237 5.68342 4.90237 5.29289 5.29289C4.90237 5.68342 4.90237 6.31658 5.29289 6.70711L6.58579 8L5.29289 9.29289C4.90237 9.68342 4.90237 10.3166 5.29289 10.7071C5.68342 11.0976 6.31658 11.0976 6.70711 10.7071L8 9.41421L9.29289 10.7071C9.68342 11.0976 10.3166 11.0976 10.7071 10.7071C11.0976 10.3166 11.0976 9.68342 10.7071 9.29289L9.41421 8L10.7071 6.70711C11.0976 6.31658 11.0976 5.68342 10.7071 5.29289C10.3166 4.90237 9.68342 4.90237 9.29289 5.29289L8 6.58579L6.70711 5.29289Z"
                fill="#9199B1"
            />
        </svg>
    ),
    onReset,
    autoFocus = false,
    inputRegex = /^[\p{L}\p{Z}\p{Sm}\p{Sc}\p{Nd}\p{Nl}\p{P}]*$/u,
    defaultValue,
}: SearchBarProps) => {
    const [value, setValue] = React.useState(defaultValue || '');

    const handleChange = (e: any) => {
        if (inputRegex && !inputRegex.test(e.target.value)){
            return
        }
        onChange && onChange(e.target.value);
        setValue(e.target.value)
    }

    const cnInput = classnames(inputClassName, {
        'pr-10': !!resetIcon && enableReset,
    });

    return (
        <form
            role="search"
            className="w-full"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <div className={mainClassName}>
                <div className={iconClassName}>{icon}</div>
                <input
                    autoFocus={autoFocus}
                    type="text"
                    className={cnInput}
                    placeholder={placeholder?.toString()}
                    onChange={handleChange}
                    value={value}
                />
                {enableReset && (
                    <div className={resetIconClassName} onClick={onReset}>
                        {resetIcon}
                    </div>
                )}
            </div>
        </form>
    );
};
