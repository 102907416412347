import { CloudUploadIcon } from 'assets/images/cloudUploadIcon'
import classnames from 'classnames'
import { ImageCrop } from 'components/ImageCrop'
import { formatBytes } from 'helpers/formatBytes'
import Image from 'next/image'
import React from 'react'
import 'react-image-crop/dist/ReactCrop.css'

const MAX_COVER_WIDTH = 1920
const MAX_COVER_HEIGHT = 1080
const MAX_COVER_SIZE = 819200
const COVER_FORMATS = ['image/jpg', 'image/jpe', 'image/jpeg', 'image/png', 'image/webp']
const SUPPORTED_FORMATS = COVER_FORMATS.map(mimeType => mimeType.replace('image/', ''))

interface EditorImageUploaderProps {
  coverFile: File
  setCoverFile: any
  coverURL: string
  setCoverURL: any
  setIsCoverValid?: any
  cropApplied: boolean
  setCropApplied: any
  withImageCrop?: boolean
  isCoverValid: any
}

export const EditorImageUploader: React.FC<EditorImageUploaderProps> = ({
  coverFile,
  setCoverFile,
  coverURL,
  setCoverURL,
  setIsCoverValid,
  isCoverValid,
  withImageCrop = true,
  cropApplied,
  setCropApplied,
}: EditorImageUploaderProps) => {
  const [cropOpen, setCropOpen] = React.useState<boolean>(false)
  const [coverWidth, setCoverWidth] = React.useState<number>(0)
  const [coverHeight, setCoverHeight] = React.useState<number>(0)
  const [coverSize, setCoverSize] = React.useState<number>(0)
  const [coverDefault, setCoverDefault] = React.useState(coverURL ? coverURL : '')
  const [defaultCoverSize, setDefaultCoverSize] = React.useState<any>({
    height: 0,
    width: 0,
    size: 0,
  })
  const [coverType, setCoverType] = React.useState<string>('')

  const uploadRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    const CoverValid =
      (COVER_FORMATS.includes(coverType) || coverType == '') &&
      coverWidth <= MAX_COVER_WIDTH &&
      coverHeight <= MAX_COVER_HEIGHT &&
      coverSize <= MAX_COVER_SIZE &&
      (coverWidth
        ? Math.ceil((coverWidth / coverHeight) * 100) / 100 <= 1.83 &&
          Math.ceil((coverWidth / coverHeight) * 100) / 100 >= 1.75
        : true)

    setIsCoverValid(CoverValid)
  }, [coverWidth, coverHeight, coverSize, coverType, isCoverValid])

  const renderUploadRule = React.useMemo(() => {
    const dimensionValid = coverWidth <= MAX_COVER_WIDTH && coverHeight <= MAX_COVER_HEIGHT
    const sizeValid = coverSize <= MAX_COVER_SIZE
    const mimeTypeValid = coverFile && COVER_FORMATS.includes(coverType)
    const ratioValid = coverFile
      ? Math.ceil((coverWidth / coverHeight) * 100) / 100 <= 1.83 &&
        Math.ceil((coverWidth / coverHeight) * 100) / 100 >= 1.75
      : true

    const cnDimensionValid = classnames({
      'text-text-color-60': dimensionValid,
      'text-system-red-60': !dimensionValid,
    })

    const cnSizeValid = classnames({
      'text-text-color-60': sizeValid,
      'text-system-red-60': !sizeValid,
    })

    const cnTypeValid = classnames(coverFile && !mimeTypeValid ? 'text-system-red-60' : 'text-text-color-60')

    const cnRatioValid = classnames(ratioValid ? 'text-text-color-60' : 'text-system-red-60')

    return (
      <div className="text-sm font-metro-semibold">
        <div className={cnTypeValid}>File format: {SUPPORTED_FORMATS.join(', ')}</div>
        <div className={cnDimensionValid}>Image size: {`${MAX_COVER_WIDTH}x${MAX_COVER_HEIGHT}`}</div>
        <div className={cnSizeValid}>Max size: {`${formatBytes(MAX_COVER_SIZE)}`}</div>
        <div className={cnRatioValid}>Image ratio: 16/9</div>
      </div>
    )
  }, [coverFile, coverWidth, coverHeight, coverSize])

  const handleResetCrop = () => {
    setCropApplied(false)
    setCoverURL(coverDefault)
    setCoverHeight(defaultCoverSize.height)
    setCoverWidth(defaultCoverSize.width)
    setCoverSize(defaultCoverSize.size)
  }

  const handleRemoveCover = React.useCallback(() => {
    setCropApplied(false)
    setCoverFile(null)
    setCoverURL('')
    setCoverDefault('')
    setCoverHeight(0)
    setCoverWidth(0)
    setCoverSize(0)
    setCoverType('')
    setDefaultCoverSize({ width: 0, height: 0, size: 0 })
    uploadRef.current ? (uploadRef.current.value = '') : null
  }, [])

  const uploadNewImage = React.useCallback((currentRef: any) => {
    if (currentRef && currentRef.current) {
      currentRef.current.click()
    }
  }, [])

  const renderRemoveCoverButton = React.useCallback(() => {
    return (
      <button className="font-semibold text-system-red-60 text-sm" onClick={handleRemoveCover}>
        Remove
      </button>
    )
  }, [])

  const handleUploadScan = React.useCallback((uploadEvent: any) => {
    const allFiles: File[] = uploadEvent.target.files
    const maxDocsCount = 1
    const filesList =
      Array.from(allFiles).length > maxDocsCount ? Array.from(allFiles).slice(0, maxDocsCount) : Array.from(allFiles)

    if (!filesList.length) {
      return
    }

    setCropOpen(true)
    const newFile = filesList[0]

    const img = document.createElement('img')

    img.onload = e => {
      const target = e.target as HTMLImageElement

      setDefaultCoverSize({
        height: target.height,
        width: target.width,
        size: newFile.size,
      })
      setCoverWidth(target.width)
      setCoverHeight(target.height)
    }

    img.src = URL.createObjectURL(newFile)

    setCoverDefault(URL.createObjectURL(newFile))
    setCoverURL(URL.createObjectURL(newFile))

    setCoverSize(newFile.size)
    setCoverFile(newFile)
    setCoverType(newFile.type)
  }, [])

  const wrapperClassname = React.useMemo(
    () =>
      classnames('my-4 mr-4 ml-[18px] md:my-7 overflow-hidden flex items-center relative shrink-0 h-[42px]', {
        'mr-0': coverURL,
      }),
    [coverURL],
  )

  const uploadWrapperClassname = React.useMemo(() => classnames('flex items-center'), [coverURL])

  const imagePreviewClassname = React.useMemo(
    () =>
      classnames('relative w-32 h-24 my-4 ml-[18px]', {
        hidden: !coverURL,
        'hover:cursor-pointer': withImageCrop,
      }),
    [coverURL],
  )

  return (
    <div className="md:flex items-center w-full z-10 border-b-2 border-b-divider-color-20 border-dashed overflow-visible">
      {cropOpen && withImageCrop ? (
        <ImageCrop
          coverURL={coverDefault}
          setCoverURL={setCoverURL}
          setCropOpen={setCropOpen}
          setCoverFile={setCoverFile}
          setCropApplied={setCropApplied}
          setCoverWidth={setCoverWidth}
          setCoverHeight={setCoverHeight}
          setCoverSize={setCoverSize}
          coverWidth={coverWidth}
          coverHeight={coverHeight}
          coverSize={coverSize}
          setIsCoverValid={setIsCoverValid}
          defaultCoverSize={defaultCoverSize}
          coverFile={coverFile}
        />
      ) : null}
      {isCoverValid && coverURL && coverURL.includes('http') && (
        <div className={imagePreviewClassname} onClick={() => setCropOpen(true)}>
          <Image alt="preview image" src={coverURL || ''} layout="fill" objectFit="contain" />
        </div>
      )}
      <div className={wrapperClassname}>
        <div className={uploadWrapperClassname}>
          <input
            ref={uploadRef}
            type="file"
            accept={COVER_FORMATS.join(',')}
            draggable
            name="files[]"
            id="file"
            multiple={false}
            className="w-[162px] text-sm h-full opacity-0 z-20 cursor-pointer invisible"
            onChange={(uploadEvent: any) => {
              handleUploadScan(uploadEvent)
            }}
          />
          <button
            className="absolute bg-neutral-control-color-0 border border-neutral-control-color-70 rounded h-[42px] hover:bg-neutral-control-color-10"
            onClick={() => uploadNewImage(uploadRef)}
          >
            <div className="flex justify-center gap-1 cursor-pointer px-3">
              <span className="text-neutral-control-layer-color-70 text-sm font-metro-semibold">
                {!coverURL ? 'Add cover image' : 'Change cover'}
              </span>
              <CloudUploadIcon />
            </div>
          </button>
        </div>
      </div>
      <div className="flex flex-row mr-4 my-4 ml-[18px] md:ml-0">
        {cropApplied && isCoverValid ? (
          <div className="text-sm font-metro-semibold text-text-color-60 mr-3 shrink">
            <button className="font-semibold text-system-red-60 text-sm" onClick={handleResetCrop}>
              Reset
            </button>
          </div>
        ) : null}
        {!isCoverValid && coverFile ? (
          <div className="text-sm font-metro-semibold text-text-color-60 mr-3 shrink">
            <button className="font-semibold text-system-red-60 text-sm" onClick={() => setCropOpen(true)}>
              Edit
            </button>
          </div>
        ) : null}
        {coverURL ? (
          <div className="text-sm font-metro-semibold text-text-color-60 mr-3 shrink">{renderRemoveCoverButton()}</div>
        ) : null}
        {isCoverValid && !coverURL && renderUploadRule}
      </div>
      <div className="my-4 ml-[18px] md:ml-0">{!isCoverValid && coverURL && renderUploadRule}</div>
    </div>
  )
}
