import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { isBrowser } from 'web-sdk/helpers/isBrowser';
import { Dropdown } from 'web-sdk/components/Dropdown';

export interface UserSelectorWidgetProps {
    /**
     * Classes for button
     */
    buttonClassName?: string;
    /**
     * Classes for list option items
     */
    optionColorClassName?: string;
    /**
     * Classes for selected list option item
     */
    optionColorSelectedClassName?: string;
    /**
     * Classes for active list option item
     */
    optionColorActiveClassName?: string;
    /**
     * Classes for list options
     */
    listOptionClassName?: string
    /**
     * Color for selector icon
     */
    selectorIconColor?: string

    selectorIconClass?: string

    optionColorClass?: string

    optionColorSelectedClass?: string

    optionColorActiveClass?: string
}

export const UserSelectorWidget: React.FC<UserSelectorWidgetProps> = ({
    buttonClassName = 'relative w-full text-neutral-control-layer-color-90 bg-input-background-color border border-divider-color-20 rounded-md shadow-sm pl-3 pr-12 text-left cursor-default focus:outline-none h-10',
    selectorIconColor = 'text-gray-400',
    selectorIconClass = 'ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none',
    optionColorClass = 'text-neutral-control-layer-color-70 text-xs',
    optionColorSelectedClass = 'text-white bg-indigo-600 text-xs',
    optionColorActiveClass = 'text-white bg-indigo-600 text-xs',
    listOptionClassName = 'bg-dropdown-background-color shadow-sm border border-divider-color-20',
}: UserSelectorWidgetProps) => {
    const router = useRouter()

    const [dropdownSelected, setDropdownSelected] = useState<string>('User');
    const [isAdminNavigationSelected, setIsAdminNavigationSelected] =
        useState<boolean>(router?.asPath.split('/')[1] === 'super' || router?.asPath.split('/')[1] === 'activation')

    useEffect(() => {
        if (isBrowser()) {
            const selectedUser = (isAdminNavigationSelected ? 'Admin' : 'User');

            setDropdownSelected(selectedUser);
        }
    }, [isBrowser, isAdminNavigationSelected]);

    useEffect(() => {
        setIsAdminNavigationSelected(router?.asPath.split('/')[1] === 'super' || router?.asPath.split('/')[1] === 'activation')
    }, [router])

    const handleChangeUser = useCallback((item: any) => {
        setDropdownSelected(item);
        if (item == 'User')
            router.push('/')
        else if (item == 'Admin')
            router.push('/admin/project/default')
    }, []);

    const dropdownList = () => {
        return [
            'User',
            'Admin'
        ]
    };

    return (
        <Dropdown
            list={dropdownList()}
            selected={dropdownSelected}
            onSelect={handleChangeUser}
            buttonClassNames={buttonClassName}
            selectorIconClass={selectorIconClass}
            customSelectorIcon={<ChevronDownIcon className="h-5 w-5 text-neutral-control-layer-color-70"/>}
            customButtonHeight
            optionColorClass={optionColorClass}
            optionColorSelectedClass={optionColorSelectedClass}
            optionColorActiveClass={optionColorActiveClass}
        />
    );
};
