import { uploadImageToBucket } from 'components/MarkdownEditor/helpers'
import ShortUniqueId from 'short-unique-id'
import slugify from 'slugify'

// TODO: rename formatArticleData
export const formatArticleData = async (
  // TODO: apply types
  articleData: any,
  coverFile: any,
  id?: number,
  updateCover?: boolean,
  isCoverValid?: boolean,
  noUpdateSlug?: boolean,
) => {
  const uid = new ShortUniqueId({
    dictionary: 'alphanum_lower',
    length: 12,
  })

  const slug = `${slugify(articleData.title, { lower: true })}`

  let imageURL = ''

  if (coverFile && isCoverValid) {
    const result = await uploadImageToBucket(coverFile.name, coverFile)

    if (result?.startsWith('http')) {
      imageURL = result
    }
  }

  if (id) {
    return {
      ...articleData,
      id,
      ...(updateCover && { image_url: imageURL }),
      ...(!noUpdateSlug && { slug: slug }),
    }
  }

  return {
    ...articleData,
    slug,
    image_url: imageURL,
  }
}
