import { store } from '../../../providers/ReduxProvider/app/store';
import { toggleChartRebuild } from '../../../providers/ReduxProvider/features/globalSettings/globalSettingsSlice';
import { isBrowser } from '../../../helpers';
import { CustomizationSettingsInterface } from '../presets';
import { AVAILABLE_COLOR_TITLES, AVAILABLE_COLORS_VARIABLES } from './ColorTitles';
import generateNewColors from './generateNewColors';
import { TailwindBorderRadiusClassNames, BorderRadius } from '../Corners';

/*
1. Remove all color variables from root and body elements
*/
export const clearCustomizationSettings = () => {
    const rootElement = document.documentElement;
    const bodyElement = document.querySelector<HTMLElement>('body')!;

    const clearSettings = (result: any, item: any) => {
        rootElement.style.removeProperty(`--${item.key}`);
        result && rootElement.style.removeProperty(`--${result.key}`);

        if (bodyElement) {
            result && bodyElement.style.removeProperty(`--${result.key}`);
            bodyElement.style.removeProperty(`--${item.key}`);
        }

        return result;
    }

    if (rootElement) {
        AVAILABLE_COLORS_VARIABLES.reduce(clearSettings)
        AVAILABLE_COLOR_TITLES.reduce(clearSettings)
        store.dispatch(toggleChartRebuild());
    }
}

export const applyGlobalCoefficient = (borderRadius: number) => {
    const rootElement = document.documentElement;

    TailwindBorderRadiusClassNames.map((item: BorderRadius) => {
        if (item.name !== 'rounded-none' && item.name !== 'rounded-full') {
          rootElement && rootElement.style.setProperty(
              `--${item.name}`,
              `${+item.radius * borderRadius}${item.type}`,
          )
        }
    });
};

/*
1. Set color style variables of root and body elements
*/
export const applyCustomizationSettingsColors = (
    customization?: CustomizationSettingsInterface,

) => {
    const rootElement = document.documentElement;
    const bodyElement = document.querySelector<HTMLElement>('body')!;
    let shouldChartRebuild = false;

    if (rootElement) {
        if (customization?.borderRadius) {
            applyGlobalCoefficient(+customization?.borderRadius);
        }

        AVAILABLE_COLOR_TITLES.reduce((result, item) => {
            let darkModeColor = undefined;
            let lightModeColor = undefined;

            if (rootElement && customization?.theme_colors?.light) {
                lightModeColor = customization.theme_colors.light.find((color => color.key === item.key));
                lightModeColor && generateNewColors(item.key, lightModeColor.value, rootElement, 'light');
                shouldChartRebuild = true;
            }

            if (bodyElement && customization?.theme_colors?.dark) {
                darkModeColor = customization.theme_colors.dark.find((color => color.key === item.key));
                darkModeColor && generateNewColors(item.key, darkModeColor.value, bodyElement, 'dark');
                shouldChartRebuild = true;
            }

            return result;
        }, {});
    }

    if (shouldChartRebuild) {
        store.dispatch(toggleChartRebuild());
    }
};

/*
1. Remove all color variables from root and body elements
2. Set color style variables of root and body elements and rebuild chart
*/
export const applyCustomizationSettings = (
    customization?: CustomizationSettingsInterface,
) => {
    if (isBrowser()) {
        clearCustomizationSettings();
        applyCustomizationSettingsColors(customization);
    }
};
