import React, { FC, useCallback } from 'react';
import { DEFAULT_USER_MENU, USER_MENU } from './UserMenu.default';

export interface UserMenuProps  {
    /**
     * Title name for menu
     */
    title?: string;
    /**
     * List of user menu items
     */
    userMenu?: USER_MENU[],
    /**
     * What class name to use
     */
    classNames?: string;
    /**
     * What class name to use for option in menu
     */
    classNamesOption?: string;
    /**
     * What class name to use for option in menu
     */
    classNamesTitle?: string;
    /**
     * Ref
     */
    ref?: React.Ref<HTMLDivElement>,
}

/**
 * UserMenu component
 */
export const UserMenu: FC<UserMenuProps> = React.forwardRef(({
    title = '',
    userMenu = DEFAULT_USER_MENU,
    classNames = 'bg-dropdown-background-color shadow-lg-updated w-48 p-2 absolute bottom-36 z-50',
    classNamesOption = 'flex p-2 text-cta-contrast-color hover:bg-gray-50 hover:text-gray-900 font-medium cursor-pointer items-center',
    classNamesTitle = 'p-2 font-medium',
}: UserMenuProps, ref?: React.Ref<HTMLDivElement>) => {
    const renderItemForUserMenu = useCallback((item: any, index: any) => {
        const MenuIcon = item.icon;

        return (
            <div className={classNamesOption} key={index} onClick={() => item.onClick()}>
                <div className='pr-4'>
                    <MenuIcon />
                </div>
                <div>{item.label}</div>
            </div>
        )}, []);

    return (
        <div ref={ref} className={classNames}>
            {title && <div className={classNamesTitle}>{title}</div>}
            {userMenu?.map(renderItemForUserMenu)}
        </div>
    );
});
