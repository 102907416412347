export enum EditorMode {
  WRITE_MODE = 'WRITE_MODE',
  PREVIEW_MODE = 'PREVIEW_MODE',
}

export enum ToolbarAction {
  WRITE_MODE = 'WRITE_MODE',
  PREVIEW_MODE = 'PREVIEW_MODE',

  HEADING = 'HEADING',
  BOLD_TEXT = 'BOLD_TEXT',
  ITALIC_TEXT = 'ITALIC_TEXT',
  LINE_THROUGH = 'LINE_THROUGH',

  UNORDERED_LIST = 'UNORDERED_LIST',
  ORDERED_LIST = 'ORDERED_LIST',
  CHECK_LIST = 'CHECK_LIST',

  QUOTE = 'QUOTE',
}

export const getImageMarkdown = (src: string, alt: string = '') => {
  return `![${alt}](${src})`
}

export const getMarkdownTextByAction = (action: ToolbarAction): string => {
  switch (action) {
    case ToolbarAction.HEADING:
      return '## '
    case ToolbarAction.BOLD_TEXT:
      return '****'
    case ToolbarAction.ITALIC_TEXT:
      return '__'
    case ToolbarAction.LINE_THROUGH:
      return '~~~~'
    case ToolbarAction.UNORDERED_LIST:
      return '- '
    case ToolbarAction.ORDERED_LIST:
      return '1. '
    case ToolbarAction.CHECK_LIST:
      return '- [ ] '
    case ToolbarAction.QUOTE:
      return '> '
    default:
      throw new TypeError('[MarkdownEditor] Uknown toolbar action.')
  }
}

export const getCursorMoveDistanceByAction = (action: ToolbarAction): number => {
  switch (action) {
    case ToolbarAction.ITALIC_TEXT:
      return 1
    case ToolbarAction.HEADING:
    case ToolbarAction.ORDERED_LIST:
      return 3
    case ToolbarAction.CHECK_LIST:
      return 6
    case ToolbarAction.UNORDERED_LIST:
    case ToolbarAction.BOLD_TEXT:
    case ToolbarAction.LINE_THROUGH:
    case ToolbarAction.QUOTE:
      return 2
    default:
      return 0
  }
}

export const uploadImageToBucket = async (name: string, file: File): Promise<string> => {
  try {
    const body = new FormData()

    body.append(name, file)
    const response = await fetch('/api/storage/upload', {
      method: 'POST',
      body,
    })
    const { publicURL } = await response.json()

    return publicURL
  } catch (err) {
    return 'Error uploading file!'
  }
}
