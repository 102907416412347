import { useRouter } from 'next/router'
import Link, { LinkProps } from 'next/link'
import { FC } from 'react'
import { DEFAULT_LANG } from 'web-sdk/constants';

interface CustomLinkProps extends Omit<LinkProps, 'locale'> {
  locale?: string
}

const CustomLink: FC<CustomLinkProps> = ({ href, as, locale, ...props }) => {
  const { locale: currentLocale } = useRouter()
  const lowercasedLocale = (locale || currentLocale || DEFAULT_LANG).toLowerCase()

  return <Link href={href} as={as} locale={lowercasedLocale} {...props} />
};

export default CustomLink
