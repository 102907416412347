import classnames from 'classnames';
import React, { FC, useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { SVGICON } from '../../helpers/svgicon';
import { UserMenu, UserMenuProps } from '../UserMenu';
import { DEFAULT_USER_ICON, DEFAULT_WALLET_ICON } from './User.default';

interface UserInfo {
    uid: string;
    username: string;
    icon?: React.ReactNode;
}
export interface UserProps  {
    /**
     * What class name to use for core
     */
    classNames?: string;
    /**
     * What class name to use
     */
    userMenuProps?: UserMenuProps;
    /**
     * User contents
     */
    userInfo?: UserInfo;
    /**
     * Content
     */
    label?: string;
    /**
     * Is collapesed
     */
    isCollapsed?: boolean;
}

/**
 * User component
 */
export const User: FC<UserProps> = ({
    classNames = 'flex min-w-full',
    userMenuProps,
    userInfo = {
        uid: 'UID3849991497147',
        username: 'Geffen',
        icon: DEFAULT_USER_ICON,
    },
    label,
    isCollapsed,
}: UserProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const mobileButtonClassName = classnames({
        'border-none':  isCollapsed,
    });

    useEffect(() => {
        document.addEventListener('click', outsideClick, false);

        return () => {
            document.removeEventListener('click', outsideClick, false);
        };
    }, []);

    const outsideClick = useCallback((e: any) => {
        if ((ref.current && !ref.current.contains(e.target)) && (buttonRef.current && !buttonRef.current.contains(e.target))) {
            setIsOpen(false);
        }
    }, [isOpen]);

    const renderUserInfo = useMemo(() => {
        const mobileClassName = classnames({
            'hidden':  isCollapsed,
        });

        return (
            <div className="text-left flex items-center">
                <div className="mr-4">{userInfo?.icon || DEFAULT_USER_ICON}</div>
                <div className={mobileClassName}>
                    <div className="font-bold leading-4">{userInfo?.username}</div>
                    <div className="text-xs text-secondary-color">{userInfo?.uid}</div>
                </div>
            </div>
        );
    }, [userInfo, isCollapsed]);

    const renderLabel = useMemo(() => <div>{isCollapsed ? <SVGICON>{DEFAULT_WALLET_ICON}</SVGICON> : label}</div>, [isCollapsed]);

    return (
        <>
            <button
                ref={buttonRef}
                type="button"
                className={classnames(classNames, mobileButtonClassName)}
                onClick={() => setIsOpen(!isOpen)}
            >
                {label ? renderLabel : renderUserInfo}
            </button>
            {isOpen && <UserMenu ref={ref} {...userMenuProps} />}
        </>
    );
};
