import { Dialog, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import React, {
    Fragment,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import Config from '../../configs/app';
import { LoginButton, LoginButtonProps } from '../LoginButton';
import { User, UserProps } from '../User';
import { closeIcon as defaultCloseIcon, defaultLogo } from './Sidebar.constants';
import {
    DEFAULT_BOTTOM_BAR_CLASSES,
    DEFAULT_BUTTONS_LIST,
    DEFAULT_NAVIGATIONS,
    DEFAULT_NAV_OVERLAY_CLASSES,
    DEFAULT_NAV_OVERLAY_TOGGLER_ICON,
    DEFAULT_USER_MENU_PROPS,
    LOGIN_BUTTON_PROPS,
} from './Sidebar.default';
import { Tooltip } from '../Tooltip';
import { useSetMobileDevice } from 'web-sdk/hooks/useMobileDevice';
import { UserSelectorWidget } from 'web-sdk/widgets/UserSelectorWidget';
import CustomLink from 'components/CustomLink';

export interface navigationItem {
    name: string;
    href: string;
    icon: JSX.Element;
    sidebar: boolean;
    mobile: boolean;
    bottom?: boolean;
    groupName?: string;
    groupOrder?: number;
}

export interface navigationApp {
    app: string;
    pathnames: navigationAppItem[];
    bottom?: boolean;
}
export interface navigationAppItem {
    name: string;
    activeIcon?: JSX.Element;
    defaultIcon: JSX.Element;
    path: string;
    bottom?: boolean;
    submenus?: navigationSubItem[];
    disabled?: boolean;
    skipUseCurrentPath?: boolean;
    newTab?: boolean;
}

export interface navigationSubItem {
    name: string;
    path: string;
}

export interface ButtonProps {
    name: string;
    component?: JSX.Element;
    label?: string;
}

export interface adminButtonProps {
    name: string;
    label: string;
    path: string;
    newTab?: boolean;
}

export interface SidebarProps {
    /**
     * A CSS classes to apply to root element of left navbar
     */
    classNames?: string;
    /**
     * A CSS classes to apply to minimized navigation element
     */
    navMinimizedClassNames?: string;
    /**
     * A CSS classes to apply to overlay navigation element
     */
    navOverlayClassNames?: string;
    /**
     * A CSS classes to apply to navigation element in case if the active path
     */
    navActiveClassNames?: string;
    /**
     * A CSS classes to apply to navigation group element in case if the active path
     */
    navGroupActiveClassNames?: string;
    /**
     * A CSS classes to apply to separator of the navigation group
     */
    navGroupSeparatorClassNames?: string;
    /**
     * A CSS classes to apply to navigation element in case if the inactive path
     */
    navInactiveClassNames?: string;
    /**
     * A CSS classes to apply to submenu of navigation element in case if the active path
     */
    navActiveSubmenuClassNames?: string;
    /**
     * A CSS classes to apply to navigation element whent it`s dissabled
     */
    disabledClassNames?: string;
    /**
     * A CSS classes for mobile bottom navigation menu
     */
    bottomClasses?: string;
    /**
     * Group of navigation URLs
     */
    navigations?: navigationApp[];
    /**
     * Group of navigation URLs for mobile
     */
    mobileNavigation?: navigationApp[];
    /**
     * Group of navigation URLs for admin
     */
    adminNavigation?: navigationApp[];
    /**
     * List of buttons only for admin/superadmin
     */
    adminButtonsList?: adminButtonProps[];
    /**
     * Determines whether to show admin navigation
     */
    showAdminNavigation?: boolean;
    /**
     * App name for selecting navigation URLs if u have group navigation to display at bottom of mobile screen
     */
    currentApp?: string;
    /**
     * Site logo
     */
    logo?: JSX.Element;
    /**
     * Link to the page, on which to go on clicking logo
     */
    linkOnLogo?: string;
    /**
     * Properties for login button
     */
    loginButtonProps?: LoginButtonProps;
    /**
     * Is user Logged In
     */
    isLoggedin?: boolean;
    /**
     * Properties for user menu
     */
    userMenu?: UserProps;
    /**
     * buttons list
     */
    buttonsList?: ButtonProps[];
    /**
     * Close icon for navbar overlay
     */
    closeIcon?: JSX.Element;
    /**
     * Show sidebar toggler button in mobile navbar
     */
    showMobileSidebarToggler?: boolean;
    /**
     * CSS classes to apply to mobile navigation item
     */
    mobileNavbarClasses?: string;
    /**
     * CSS classes to apply to mobile navigation item when it is active
     */
    mobileNavbarActiveClasses?: string;
    /**
     * Toggler icon for collapsed navigation
     */
    navOverlayTogglerIcon?: JSX.Element;
    /**
     * A CSS classes to apply to root element of navbar overlay
     */
    navOverlayClasses?: string;
    /**
     * A CSS classes to apply to 'Close' icon
     */
    closeIconClasses?: string;
    /**
     * Label for navigation 'More' icon
     */
    navMoreLabel?: string | ReactNode;
    /**
     * Whether to divide navigation by group titles
     */
    showNavGroupTitles?: string | ReactNode;
    /**
     * Current application theme, could be light or dark
     */
    colorTheme?: string;
    /**
     * Pathes that wouldn`t highlighted as default '/'
     */
    exceptedDefaultActivePathnames?: string[];
    /**
     * Highlighting color of active mobile label
     */
    mobileSpanActiveColor?: string;
    /**
     * Additional link item
     */
    additionalLink?: JSX.Element;
    /**
     * Additional link container classnames
     */
    AdditionalLinkContainerCn?: string;
    /**
     * Classnames for Tooltip component TextWrapperClassname prop
     */
    TooltipTextWrapperClassname?: string;
    /**
     * Classnames for Tooltip component backgroundClassname prop
     */
    tooltipBackgroundClassname?: string;
    /**
     * Classnames for Tooltip component tooltipClassname prop
     */
    tooltipClassname?: string;
    /**
     * Classnames for Tooltip component customArrow prop
     */
    TooltipCustomArrowClassname?: string;
    /**
     * Classnames for Tooltip component boxWrapperClassname prop
     */
    TooltipBoxWrapperClassname?: string;
}

/**
 * Primary UI component for user interaction
 */
export const Sidebar = ({
    classNames,
    bottomClasses = DEFAULT_BOTTOM_BAR_CLASSES,
    navigations = DEFAULT_NAVIGATIONS,
    mobileNavigation,
    adminNavigation,
    showAdminNavigation,
    adminButtonsList,
    currentApp = DEFAULT_NAVIGATIONS[0].app,
    linkOnLogo = '/trading',
    logo,
    loginButtonProps = LOGIN_BUTTON_PROPS,
    isLoggedin = false,
    userMenu = DEFAULT_USER_MENU_PROPS,
    navMinimizedClassNames = 'no-underline duration-150 group flex items-center mt-0.5 px-2 py-2 text-xs font-semibold rounded-md',
    navOverlayClassNames = 'relative no-underline duration-150 group flex items-center mt-0.5 px-2 py-2 text-md font-bold rounded-md',
    navActiveClassNames = 'bg-indigo-50 text-indigo-600',
    navGroupSeparatorClassNames = 'w-10 mx-auto my-2',
    navGroupActiveClassNames = 'bg-navbar-control-bg-color-10 rounded-lg',
    navActiveSubmenuClassNames = 'text-navbar-control-layer-color-60 before:w-0.5 before:bg-primary-cta-color-60 before:rounded-lg before:h-5 before:absolute before:left-0',
    navInactiveClassNames = 'text-gray-500',
    disabledClassNames = 'opacity-20 cursor-default',
    buttonsList = DEFAULT_BUTTONS_LIST,
    closeIcon = defaultCloseIcon,
    showMobileSidebarToggler,
    mobileNavbarClasses = 'text-gray-600 hover:bg-navbar-control-bg-color-10 hover:text-gray-900 text-sm font-medium',
    mobileNavbarActiveClasses = 'pointer-events-none bg-navbar-control-bg-color-10',
    navOverlayTogglerIcon = DEFAULT_NAV_OVERLAY_TOGGLER_ICON,
    navOverlayClasses = DEFAULT_NAV_OVERLAY_CLASSES,
    closeIconClasses = 'flex justify-center w-8 h-8 bg-navbar-control-bg-color-10',
    navMoreLabel,
    showNavGroupTitles,
    colorTheme,
    exceptedDefaultActivePathnames,
    mobileSpanActiveColor = 'text-primary-cta-color-60',
    additionalLink,
    AdditionalLinkContainerCn,
    TooltipBoxWrapperClassname = 'w-fit  bg-body-background-color !opacity-100 shadow-lg z-40 rounded border border-divider-color-20',
    TooltipCustomArrowClassname = 'w-2.5 h-2.5 rotate-45 border-b border-l border-divider-color-20 absolute bg-body-background-color !opacity-100 z-50',
    TooltipTextWrapperClassname = 'py-1.5 px-5',
    tooltipBackgroundClassname = '',
    tooltipClassname = 'flex flex-row-reverse',
}: SidebarProps) => {
    const [hiddenNavGroups, setHiddenNavGroups] = useState<string[]>([]);
    const [showNavOverlay, setShowNavOverlay] = useState<boolean>(false);

    const router = useRouter();

    const [isAdminNavigationSelected, setIsAdminNavigationSelected] =
        useState<boolean>(
            router?.asPath.split('/')[1] === 'super' ||
                router?.asPath.split('/')[1] === 'activation'
        );

    useEffect(() => {
        setIsAdminNavigationSelected(
            router?.asPath.split('/')[1] === 'super' ||
                router?.asPath.split('/')[1] === 'activation'
        );
    }, [router]);

    const walletBtnRef = useRef(null);
    const isMobile = useSetMobileDevice();

    useEffect(() => {
        return () => {
            setHiddenNavGroups([]);
        };
    }, [typeof window]);

    const isActivePath = useCallback(
        (path: string, submenu?: string) => {
            if (submenu) {
                return router?.pathname.includes(submenu);
            }

            if (
                path == '/' &&
                router?.pathname != '/' &&
                !router?.pathname?.startsWith('/[')
            ) {
                return false;
            }

            // If it's admin path, then remove '/super' to make the path unique
            // to detect active path properly
            const processedPath = path.includes('/super')
                ? path.replace('/super', '')
                : path;
            const majorPath = processedPath.split('/')[1];

            return (
                (processedPath == '/' &&
                    router?.pathname.split('/').length <= 2 &&
                    !exceptedDefaultActivePathnames?.includes(
                        router?.pathname
                    )) ||
                (processedPath !== '/' && majorPath &&
                    router?.pathname.includes(majorPath)) ||
                // to detect major path with or without (s)
                (processedPath !== '/' &&
                    majorPath.charAt(majorPath.length - 1) === 's' &&
                    router?.pathname.includes(majorPath.slice(0, -1)))
            );
        },
        [router?.pathname]
    );

    const toggleNavCollapse = useCallback(
        (name: string) => {
            if (hiddenNavGroups.find((n) => n === name)) {
                setHiddenNavGroups([
                    ...hiddenNavGroups.filter((n) => n !== name),
                ]);
            } else {
                setHiddenNavGroups([...hiddenNavGroups, name]);
            }
        },
        [hiddenNavGroups]
    );

    const renderChevron = useCallback(
        (name: string) => {
            return hiddenNavGroups.find((n) => n === name) ? (
                <ChevronDownIcon
                    className="h-6 w-6 ml-2 cursor-pointer"
                    onClick={() => toggleNavCollapse(name)}
                />
            ) : (
                <ChevronUpIcon
                    className="h-6 w-6 ml-2 cursor-pointer"
                    onClick={() => toggleNavCollapse(name)}
                />
            );
        },
        [hiddenNavGroups, toggleNavCollapse]
    );

    const renderNavGroup = useCallback(
        (item: navigationAppItem, asNavOverlay: any) => {
            if (!item) {
                return null;
            }

            const navGroup = (
                <Fragment key={item.name}>
                    {item.newTab ? 
                        <a
                            aria-label={item.name}
                            className={classnames(
                                asNavOverlay
                                    ? navOverlayClassNames
                                    : navMinimizedClassNames,
                                isActivePath(item.path)
                                    ? !item.submenus
                                        ? navActiveClassNames
                                        : !asNavOverlay && navActiveClassNames
                                    : item.disabled
                                    ? disabledClassNames
                                    : navInactiveClassNames,
                                !asNavOverlay && 'flex-col'
                            )}
                            target="_blank"
                            href={
                                item.disabled ||
                                (isActivePath(item.path) &&
                                    !item.skipUseCurrentPath)
                                    ? ''
                                    : item.path
                            }
                            rel="noopener noreferrer"
                        >
                            <div
                                className="w-8 flex justify-center items-center"
                                data-tip
                                data-for={`${item.name}Tooltip`}
                            >
                                {isActivePath(item.path) && item.activeIcon
                                    ? item.activeIcon
                                    : item.defaultIcon}
                            </div>
                            <span
                                className={classnames(
                                    asNavOverlay ? 'ml-4' : 'mt-1 text-center'
                                )}
                            >
                                {asNavOverlay
                                    ? item.name
                                    : item.submenus &&
                                    isActivePath(item.path) &&
                                    isAdminNavigationSelected
                                    ? item.submenus?.map((submenu) => {
                                        if (
                                            isActivePath(
                                                item.path,
                                                submenu.path
                                            )
                                        ) {
                                            return submenu.name;
                                        }
                                    })
                                    : item.name}
                            </span>
                        </a>
                        :
                        <CustomLink
                            legacyBehavior
                            href={
                                item.disabled ||
                                (isActivePath(item.path) &&
                                    !item.skipUseCurrentPath)
                                    ? ''
                                    : item.path
                            }
                            prefetch={false}
                        >
                            <a
                                aria-label={item.name}
                                className={classnames(
                                    asNavOverlay
                                        ? navOverlayClassNames
                                        : navMinimizedClassNames,
                                    isActivePath(item.path)
                                        ? !item.submenus
                                            ? navActiveClassNames
                                            : !asNavOverlay && navActiveClassNames
                                        : item.disabled
                                        ? disabledClassNames
                                        : navInactiveClassNames,
                                    !asNavOverlay && 'flex-col'
                                )}
                            >
                                <div
                                    className="w-8 flex justify-center items-center"
                                    data-tip
                                    data-for={`${item.name}Tooltip`}
                                >
                                    {isActivePath(item.path) && item.activeIcon
                                        ? item.activeIcon
                                        : item.defaultIcon}
                                </div>
                                <span
                                    className={classnames(
                                        asNavOverlay ? 'ml-4' : 'mt-1 text-center'
                                    )}
                                >
                                    {asNavOverlay
                                        ? item.name
                                        : item.submenus &&
                                        isActivePath(item.path) &&
                                        isAdminNavigationSelected
                                        ? item.submenus?.map((submenu) => {
                                            if (
                                                isActivePath(
                                                    item.path,
                                                    submenu.path
                                                )
                                            ) {
                                                return submenu.name;
                                            }
                                        })
                                        : item.name}
                                </span>
                            </a>
                        </CustomLink>
                    }
                    {asNavOverlay &&
                        item.submenus &&
                        item.submenus.map((submenu, index) => (
                            <CustomLink
                                legacyBehavior
                                key={submenu.path}
                                href={submenu.path}
                                prefetch={false}
                            >
                                <a
                                    className={classnames(
                                        asNavOverlay
                                            ? navOverlayClassNames
                                            : navMinimizedClassNames,
                                        isActivePath(item.path, submenu.path)
                                            ? navActiveSubmenuClassNames
                                            : item.disabled
                                            ? disabledClassNames
                                            : navInactiveClassNames
                                    )}
                                    href={submenu.path}
                                    key={`${index}-${submenu.name}`}
                                >
                                    <div className="w-full h-5 pl-12">
                                        {submenu.name}
                                    </div>
                                </a>
                            </CustomLink>
                        ))}
                </Fragment>
            );

            if (asNavOverlay && item.submenus && isActivePath(item.path)) {
                return (
                    <div key={item.name} className={navGroupActiveClassNames}>
                        {navGroup}
                    </div>
                );
            }

            return navGroup;
        },
        [isActivePath]
    );

    const renderNavGroupSeparator = useCallback(
        (name: any, asNavOverlay: any) => {
            if (!showNavGroupTitles && asNavOverlay) {
                return null;
            }

            if (!asNavOverlay) {
                return (
                    <hr
                        key={`group-${name}`}
                        className={navGroupSeparatorClassNames}
                    />
                );
            }

            return (
                <div
                    key={`group-${name}`}
                    className="text-neutral-control-layer-color-60 flex items-center px-0 py-3 px-1"
                >
                    <div
                        className={classnames(
                            'text-xs font-bold uppercase',
                            name && 'mr-3'
                        )}
                    >
                        {name}
                    </div>
                    <div className="flex flex-grow">
                        <hr className="w-full" />
                    </div>
                    {isMobile && renderChevron(name)}
                </div>
            );
        },
        [renderChevron, isMobile, showNavGroupTitles]
    );

    const renderNavGroups = useCallback(
        (asNavOverlay: boolean) => {
            const navigation = isAdminNavigationSelected
            ? (adminNavigation || []).filter(n => !n.bottom)
            : (navigations || []).filter(n => !n.bottom);

            const navList = navigation?.map((g) => {
                if (g.pathnames.length < 2) {
                    return [renderNavGroup(g.pathnames[0], asNavOverlay)];
                }

                if (hiddenNavGroups.find((n) => n === g.app) && isMobile) {
                    return [renderNavGroupSeparator(g.app, asNavOverlay)];
                }

                return [
                    renderNavGroupSeparator(g.app, asNavOverlay),
                    ...g.pathnames.map((n) => renderNavGroup(n, asNavOverlay)),
                ];
            });

            return navList;
        },
        [
            navigations,
            hiddenNavGroups,
            renderNavGroupSeparator,
            renderNavGroup,
            isAdminNavigationSelected,
            adminNavigation,
        ]
    );

    const renderBottomNavGroups = useCallback(
        (asNavOverlay: boolean) => {
            const navigation = isAdminNavigationSelected
                ? (adminNavigation || []).filter(n => n.bottom)
                : (navigations || []).filter(n => n.bottom);

            const navList = navigation?.map((g) => {
                if (g.pathnames.length < 2) {
                    return [renderNavGroup(g.pathnames[0], asNavOverlay)];
                }

                if (hiddenNavGroups.find((n) => n === g.app) && isMobile) {
                    return [renderNavGroupSeparator(g.app, asNavOverlay)];
                }

                return [
                    renderNavGroupSeparator(g.app, asNavOverlay),
                    ...g.pathnames.map((n) => renderNavGroup(n, asNavOverlay)),
                ];
            });

            return navList;
        },
        [
            navigations,
            hiddenNavGroups,
            renderNavGroupSeparator,
            renderNavGroup,
            isAdminNavigationSelected,
            adminNavigation,
        ]
    );

    const renderBottomNavLink = useCallback(
        (pathname: navigationAppItem) => (
            <CustomLink legacyBehavior href={pathname.path} key={pathname.path}>
                <a
                    key={pathname.name}
                    className={classnames(
                        'group flex flex-1 flex-col justify-center items-center',
                        mobileNavbarClasses,
                        isActivePath(pathname.path) && mobileNavbarActiveClasses
                    )}
                >
                    <div className="w-8 h-8 flex justify-center items-center rounded">
                        {isActivePath(pathname.path)
                            ? pathname.activeIcon
                            : pathname.defaultIcon}
                    </div>
                    <span
                        className={classnames(
                            'mt-0.5',
                            isActivePath(pathname.path) && mobileSpanActiveColor
                        )}
                    >
                        {pathname.name}
                    </span>
                </a>
            </CustomLink>
        ),
        [isActivePath]
    );

    const renderBottomNav = useMemo(() => {
        return mobileNavigation?.map((appNav) =>
            appNav?.pathnames.map((pathname) => {
                return renderBottomNavLink(pathname);
            })
        );
    }, [currentApp, mobileNavigation, router?.pathname]);

    const renderLoginButton = useCallback(
        (asNavOverlay: any) => {
            const shouldRenderLogin =
                isLoggedin ||
                (buttonsList || []).some((btn) => btn.name === 'Login');

            if (!shouldRenderLogin) return null;

            const loginButton = (buttonsList &&
                buttonsList.length &&
                buttonsList.find((btn) => btn.name === 'Login')?.component) || (
                <LoginButton
                    {...loginButtonProps}
                    isCollapsed={!asNavOverlay}
                />
            );

            return (
                <div className="px-2 py-2">
                    {isLoggedin ? (
                        <User {...userMenu} isCollapsed={!asNavOverlay} />
                    ) : (
                        loginButton
                    )}
                </div>
            );
        },
        [buttonsList, isLoggedin, userMenu, loginButtonProps]
    );

    const customTooltip = (
        icon: JSX.Element,
        content: JSX.Element,
        customArrowMargins: string = 'ml-[60px] mb-3.5',
        customBoxMargins: string = 'ml-16'
    ): JSX.Element => {
        return (
            <Tooltip
                icon={icon}
                content={content}
                showArrow
                customArrow={
                    <span
                        className={classnames(
                            customArrowMargins,
                            TooltipCustomArrowClassname
                        )}
                    />
                }
                textWrapperClassname={TooltipTextWrapperClassname}
                backgroundClassname={tooltipBackgroundClassname}
                tooltipClassname={tooltipClassname}
                boxWrapperClassname={classnames(
                    customBoxMargins,
                    TooltipBoxWrapperClassname
                )}
            />
        );
    };

    const renderMetamaskButton = useCallback(
        (asNavOverlay: any) => {
            const metamaskButton =
                buttonsList &&
                buttonsList.find((btn) => btn.name === 'Metamask');

            return (
                metamaskButton?.component && (
                    <>
                        <div
                            className={classnames(
                                !asNavOverlay && 'flex justify-center'
                            )}
                            id="walletTooltip"
                        >
                            {!asNavOverlay
                                ? customTooltip(
                                      React.cloneElement(
                                          metamaskButton.component,
                                          {
                                              minimized: true,
                                              onClickCb: () =>
                                                  setShowNavOverlay(false),
                                          }
                                      ),
                                      <span className="text-sm font-semibold text-text-color-60">
                                          {metamaskButton?.label || 'Wallet'}
                                      </span>
                                  )
                                : React.cloneElement(metamaskButton.component, {
                                      minimized: false,
                                      onClickCb: () => setShowNavOverlay(false),
                                  })}
                        </div>
                    </>
                )
            );
        },
        [buttonsList]
    );

    const renderTransferFundsButtons = useCallback(
        (asNavOverlay: any) => {
            const depositButton =
                buttonsList &&
                buttonsList.find((btn) => btn.name === 'Deposit');
            const withdrawButton =
                buttonsList &&
                buttonsList.find((btn) => btn.name === 'Withdraw');

            return (
                depositButton?.component &&
                withdrawButton?.component && (
                    <div
                        className={classnames([
                            'flex justify-between',
                            !asNavOverlay ? 'flex-col items-center' : 'my-2',
                        ])}
                    >
                        <div
                            id="depositTooltip"
                            className={asNavOverlay ? 'w-1/2' : ''}
                        >
                            {!asNavOverlay
                                ? customTooltip(
                                      React.cloneElement(
                                          depositButton.component,
                                          {
                                              minimized: true,
                                              onClickCb: () =>
                                                  setShowNavOverlay(false),
                                          }
                                      ),
                                      <span className="text-sm font-semibold text-text-color-60">
                                          {depositButton?.label || 'Deposit'}
                                      </span>,
                                      'ml-11 mb-3.5',
                                      'ml-12'
                                  )
                                : React.cloneElement(depositButton.component, {
                                      minimized: false,
                                      onClickCb: () => setShowNavOverlay(false),
                                  })}
                        </div>

                        <div
                            id="withdrawTooltip"
                            className={asNavOverlay ? 'w-1/2' : ''}
                        >
                            {!asNavOverlay
                                ? customTooltip(
                                      React.cloneElement(
                                          withdrawButton.component,
                                          {
                                              minimized: true,
                                              onClickCb: () =>
                                                  setShowNavOverlay(false),
                                          }
                                      ),
                                      <span className="text-sm font-semibold text-text-color-60">
                                          {withdrawButton?.label || 'Withdraw'}
                                      </span>,
                                      'ml-11 mb-3.5',
                                      'ml-12'
                                  )
                                : React.cloneElement(withdrawButton.component, {
                                      minimized: false,
                                      onClickCb: () => setShowNavOverlay(false),
                                  })}
                        </div>
                    </div>
                )
            );
        },
        [buttonsList]
    );

    const renderLogo = useMemo(() => {
        const appLogos = Config().appLogos;

        if (appLogos) {
            const newLogos = JSON.parse(appLogos);

            const customLogoUrl =
                colorTheme === 'dark'
                    ? newLogos?.dark_logo?.data?.publicUrl
                    : newLogos?.light_logo?.data?.publicUrl;

            if (customLogoUrl) {
                return (
                    <div className="ml-2 h-7 w-auto relative">
                        <img src={customLogoUrl} alt="App" />
                    </div>
                );
            }
        }

        return logo ? logo : defaultLogo(colorTheme ? colorTheme : 'light');
    }, [logo, colorTheme, Config().appLogos]);

    const renderAdminUserSwitcher = useMemo(() => {
        const cnLink = (isActive?: boolean) =>
            classnames(
                'text-neutral-control-layer-color-50 text-center py-1.5 cursor-pointer',
                {
                    'bg-neutral-control-color-0 shadow-md text-text-color-100 rounded':
                        isActive,
                }
            );

        return (
            <div className="bg-neutral-control-color-20 rounded-md font-semibold text-sm grid grid-cols-2 gap-x-1.5 p-1 mb-3">
                {adminButtonsList?.map(({ name, label, path, newTab }, index) =>
                    newTab ? (
                        <a
                            key={index}
                            className={cnLink(
                                name === 'admin'
                                    ? isAdminNavigationSelected
                                    : !isAdminNavigationSelected
                            )}
                            target="_blank"
                            href={path}
                            rel="noopener noreferrer"
                        >
                            {label}
                        </a>
                    ) : (
                        <CustomLink legacyBehavior key={name} href={path}>
                            <a
                                onClick={() =>
                                    setIsAdminNavigationSelected(
                                        name === 'admin'
                                    )
                                }
                                className={cnLink(
                                    name === 'admin'
                                        ? isAdminNavigationSelected
                                        : !isAdminNavigationSelected
                                )}
                            >
                                {label}
                            </a>
                        </CustomLink>
                    )
                )}
            </div>
        );
    }, [adminButtonsList, isAdminNavigationSelected]);

    const renderAdminUserDesktopSwitcher = useMemo(() => {
        return (
            <div>
                <hr className={navGroupSeparatorClassNames} />
                <UserSelectorWidget
                    buttonClassName="relative
                    left-[12.5%] 
                    w-full
                    mx-auto
                    max-w-[60px]
                    text-neutral-control-layer-color-70 
                    bg-neutral-control-color-30 
                    rounded-md 
                    shadow-sm 
                    px-1
                    text-xs
                    text-left 
                    font-medium
                    cursor-default 
                    focus:outline-none
                    h-7
                    "
                    optionColorClass="text-xs"
                    optionColorSelectedClassName="text-xs"
                    optionColorActiveClassName="text-xs"
                    selectorIconColor="text-xs"
                    selectorIconClass="ml-3 absolute inset-y-0 right-0 flex items-center pointer-events-none"
                />
                {isAdminNavigationSelected && (
                    <hr className={navGroupSeparatorClassNames} />
                )}
            </div>
        );
    }, [adminButtonsList, isAdminNavigationSelected]);

    return (
        <>
            {/* navbar overlay*/}
            <Transition.Root show={showNavOverlay} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className={classnames(
                        'fixed inset-0 flex z-30',
                        isMobile && 'flex-row-reverse'
                    )}
                    initialFocus={walletBtnRef}
                    open={showNavOverlay}
                    onClose={() => setShowNavOverlay(false)}
                >
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-[.15]" />
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-150 transform"
                        enterFrom={
                            isMobile ? 'translate-x-full' : '-translate-x-full'
                        }
                        enterTo={isMobile ? '-translate-x-0' : 'translate-x-0'}
                        leave="transition ease-in-out duration-150 transform"
                        leaveFrom={
                            isMobile ? 'translate-x-0' : '-translate-x-0'
                        }
                        leaveTo={
                            isMobile ? 'translate-x-full' : '-translate-x-full'
                        }
                    >
                        <div className={navOverlayClasses}>
                            <CustomLink
                                legacyBehavior
                                href={linkOnLogo || ''}
                                prefetch={false}
                            >
                                <a>
                                    <div className="cursor-pointer flex-shrink-0 flex px-4">
                                        {renderLogo}
                                    </div>
                                </a>
                            </CustomLink>
                            <div className="mt-5 flex-1 h-0 overflow-y-auto">
                                <nav className="px-2">
                                    {showAdminNavigation &&
                                        renderAdminUserSwitcher}
                                    {renderNavGroups(true)}
                                </nav>
                            </div>
                            <div className={AdditionalLinkContainerCn}>
                                {additionalLink && additionalLink}
                            </div>
                            <div
                                className={classnames(
                                    'px-2',
                                    isMobile && 'py-4'
                                )}
                            >
                                {/* login button */}
                                {renderLoginButton(true)}
                                {/* wallet connect button */}
                                <div className="px-2 py-2" ref={walletBtnRef}>
                                    {renderMetamaskButton(true)}
                                    {renderTransferFundsButtons(true)}
                                </div>
                                {renderBottomNavGroups(true)}
                            </div>
                            {isMobile && (
                                <div className="flex justify-end mr-8 pb-4 text-neutral-control-layer-color-60">
                                    <button
                                        className="ml-1 flex flex-col items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={() => setShowNavOverlay(false)}
                                    >
                                        <span className="sr-only">
                                            Close sidebar
                                        </span>
                                        <span className={closeIconClasses}>
                                            {closeIcon}
                                        </span>
                                        <span className="text-xs font-bold">
                                            Close
                                        </span>
                                    </button>
                                </div>
                            )}
                        </div>
                    </Transition.Child>
                    <div
                        className="flex-shrink-0 w-14"
                        aria-hidden="true"
                     />
                </Dialog>
            </Transition.Root>

            {/* desktop sidebar */}
            <div className={classNames}>
                <div className="hidden md:flex md:flex-shrink-0 h-screen">
                    <div className="flex flex-col w-20">
                        <div className="flex flex-col flex-grow pt-1 overflow-y-auto with-scrollbar">
                            <div className="flex justify-center items-center px-4">
                                <span
                                    aria-label={
                                        showNavOverlay
                                            ? 'sidebarOpen'
                                            : 'sidebarClosed'
                                    }
                                    data-testid="root-collapseButton"
                                    className="p-2 transition duration-150 ease-in rounded-md hover:bg-navbar-control-bg-color-10 cursor-pointer block"
                                    onClick={() => setShowNavOverlay(true)}
                                >
                                    {navOverlayTogglerIcon}
                                </span>
                            </div>
                            {/* TODO: add user/admin switcher */}
                            {showAdminNavigation &&
                                renderAdminUserDesktopSwitcher}
                            <div className="mt-1 flex-grow flex flex-col">
                                <nav className="flex-1 px-2.5">
                                    {renderNavGroups(false)}
                                    <hr
                                        className={navGroupSeparatorClassNames}
                                    />
                                </nav>
                            </div>
                            <nav className="flex-1 px-2 flex flex-col justify-end mb-4">
                                {/* login button */}
                                {renderLoginButton(false)}
                                {/* wallet connect button */}
                                <div className="flex flex-col-reverse">
                                    {renderMetamaskButton(false)}
                                    {renderTransferFundsButtons(false)}
                                </div>
                                {renderBottomNavGroups(false)}
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            {/* mobile sidebar */}
            {isMobile && (
                <div className={bottomClasses}>
                    <div className="flex-1 flex justify-between">
                        <nav className="flex-1 flex flex-row">
                            {renderBottomNav}
                            {showMobileSidebarToggler && (
                                <button
                                    className={classnames(
                                        'group flex flex-1 flex-col justify-center items-center',
                                        mobileNavbarClasses
                                    )}
                                    onClick={() => setShowNavOverlay(true)}
                                >
                                    <div className="w-8 h-8 flex justify-center items-center rounded">
                                        {navOverlayTogglerIcon}
                                    </div>
                                    {navMoreLabel}
                                </button>
                            )}
                        </nav>
                    </div>
                </div>
            )}
        </>
    );
};
