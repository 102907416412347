import fallbackConfig from './fallbackConfig'
import { AppConfig } from './utils'
import { isBrowser } from '../helpers/isBrowser'

const domains = ['storybook-odax-master.v4.uat.opendax.app', 'web-sdk.openware.com']

export const getConfigs = (): AppConfig => {
    if (!isBrowser()) {
        return fallbackConfig
    }

    /**
     * 293 - default height of ordebook widget
     * 60 - 19+41 - height of not rows from OB widget
     * 19.81 - height of one row
     */

    const obWidgetRowsHeight = 60;
    const obWidgetRowHeight = 19.81;
    const isMobile = window.innerHeight <= 567 || window.innerWidth <= 767
    const appConfigs = localStorage.getItem('app_config');

    const savedConfig = (appConfigs && appConfigs !== undefined && appConfigs !== 'undefined' && JSON.parse(appConfigs)) || {};

    if (isMobile) {
        let maxRowsOnPage: number | string = Math.floor(
            ((document.getElementById('orderbook-mobile')?.offsetHeight || 0) - obWidgetRowsHeight) / obWidgetRowHeight
        )

        if (maxRowsOnPage > 0) {
            maxRowsOnPage = maxRowsOnPage < 0 ? '0' : '0' + maxRowsOnPage
            fallbackConfig.orderBookSideLimit = maxRowsOnPage
            savedConfig.orderBookSideLimit = maxRowsOnPage
        }
    }

    if (savedConfig) {
        if (isBrowser() && window.location.host.includes(domains[0])) {
            savedConfig.goTrueUrl = 'https://storybook-odax-master.v4.uat.opendax.app/api/v1/auth'
            savedConfig.finexUrl = 'wss://storybook-odax-master.v4.uat.opendax.app/api/v1/finex/ws'
        }

        if (isBrowser() && window.location.host.includes(domains[1])) {
            savedConfig.goTrueUrl = 'https://web-sdk.openware.com/api/v1/auth'
            savedConfig.finexUrl = 'wss://web-sdk.openware.com/api/v1/finex/ws'
        }

        return { ...fallbackConfig, ...(savedConfig as AppConfig) }
    }

    return fallbackConfig
}

export default getConfigs
