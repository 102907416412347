import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formatTicker } from './helpers';
import { Ticker } from '../../../../types';

export interface TickersState {
    tickers: {
        [pair: string]: Ticker;
    };
    orderBy: TickersOrderBy;
    sortingOrder: TickersSortingOrder;
}

export enum TickersOrderBy {
    UNSPECIFIED = 0,
    VOLUME = 1,
    PRICE_CHANGE = 2,
}

export enum TickersSortingOrder {
    UNSPECIFIED = 0,
    ASCENDING = 1,
    DESCENDING = 2,
}

export interface TickersSorting {
    orderBy: TickersOrderBy,
    sortingOrder: TickersSortingOrder,
}

export const initialTickersState: TickersState = {
    tickers: {},
    orderBy: TickersOrderBy.UNSPECIFIED,
    sortingOrder: TickersSortingOrder.UNSPECIFIED,
};

const tickersSlice = createSlice({
    name: 'tickers',
    initialState: initialTickersState,
    reducers: {
        setTickers(state, action: PayloadAction<(string | number)[][]>) {
            state.tickers = formatTicker(action.payload) as any;
        },
        setTickersSorting(state, action: PayloadAction<TickersSorting>) {
            state.orderBy = action.payload.orderBy;
            state.sortingOrder = action.payload.sortingOrder;
        }
    },
});

export const { setTickers, setTickersSorting } = tickersSlice.actions;
export const tickersReducer = tickersSlice.reducer;
export default tickersSlice.reducer;
