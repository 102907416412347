import classnames from 'classnames'
import React from 'react'

export enum EditorButtonType {
  None = 0,
  Primary = 1,
  Secondary = 2,
  SaveAsDraft = 3,
}

interface EditorButtonProps {
  type?: EditorButtonType
  additionalClassNames?: string
  children: JSX.Element | string
  disabled?: boolean
  hidden?: boolean
  onClick: () => void
}

export const EditorButton: React.FC<EditorButtonProps> = ({
  type = EditorButtonType.None,
  additionalClassNames,
  children,
  disabled,
  hidden,
  onClick,
}: EditorButtonProps) => {
  const primaryBtnClasses = React.useMemo(
    () =>
      classnames('rounded-sm px-2 py-2 w-full md:w-max md:min-w-[150px] h-[40px] duration-200', {
        'cursor-default bg-neutral-control-color-30 text-neutral-control-layer-color-30 pointer-events-none': disabled,
        'border hover:bg-primary-cta-color-80 bg-primary-cta-color-60  text-primary-cta-layer-color-60 border-primary-cta-layer-color-60':
          !disabled,
      }),
    [disabled],
  )

  const saveAsDraftBtnClasses = React.useMemo(
    () =>
      classnames(
        'border rounded-sm px-2 py-2 w-full md:w-max md:min-w-[150px] h-[40px] md:mr-3 duration-200 text-neutral-control-layer-color-80 border-neutral-control-color-70',
        {
          'cursor-default opacity-30 pointer-events-none': disabled,
          'hover:text-neutral-control-layer-color-90 hover:border-neutral-control-color-80': !disabled,
        },
      ),
    [disabled],
  )

  const secondaryBtnClasses = React.useMemo(
    () =>
      classnames(
        'border border-system-red-20 text-system-red-60 hover:border-system-red-40 hover:text-system-red-80 rounded-sm px-3 py-2 w-full md:w-max md:min-w-[120px] h-[40px] duration-200',
        {
          'cursor-default opacity-30 pointer-events-none': disabled,
        },
      ),
    [disabled],
  )

  const buttonClassName = React.useMemo(() => {
    switch (type) {
      case EditorButtonType.None:
        return ''
      case EditorButtonType.Primary:
        return primaryBtnClasses
      case EditorButtonType.Secondary:
        return secondaryBtnClasses
      case EditorButtonType.SaveAsDraft:
        return saveAsDraftBtnClasses
    }
  }, [type, primaryBtnClasses, secondaryBtnClasses, saveAsDraftBtnClasses])

  if (hidden) {
    return <></>
  }

  return (
    <button className={classnames(buttonClassName, additionalClassNames)} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  )
}
