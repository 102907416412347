import React from 'react'
import { Decimal } from 'web-sdk'

export const formatChangeValue: React.FC<any> = (
  change: string | number,
  postfix: string | React.ReactNode = '%',
  precision = 2,
) => {
  // TODO :: eslint
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const numericValue = React.useMemo(
    () => Decimal.format(Number(String(change).replace('+', '').replace('-', '')) || 0, precision),
    [change, precision],
  )

  return (
    <>
      {numericValue}
      {postfix}
    </>
  )
}
