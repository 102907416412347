import { ReactElement, useEffect } from 'react';
import { applyCustomizationSettings } from '../../widgets/CustomizationWidget/ColorThemes/applyCustomizationSettingsColors'
import { CustomizationSettingsInterface } from '../../widgets/CustomizationWidget/presets'
import { useAppSelector } from '../ReduxProvider/app/hooks';
import appConfig from '../../configs/app'
import useDarkMode from '../../hooks/useDarkmode';
import { useCustomization } from '../../hooks/useCustomization';

interface Props {
    children: ReactElement;
}

export const ColorThemeProvider: React.FC<Props> = ({ children }: Props) => {
    const palette = useAppSelector(state => state.globalSettings.palette)

    useCustomization();
    useDarkMode();

    useEffect(() => {
        if (palette) {
            const paletteFromConfigs: CustomizationSettingsInterface = JSON.parse(palette)

            paletteFromConfigs && applyCustomizationSettings(paletteFromConfigs)
        }
    }, [palette])

    useEffect(() => {
        const paletteLocalStorage = appConfig().palette

        if (paletteLocalStorage) {
            const paletteFromConfigs: CustomizationSettingsInterface = JSON.parse(appConfig().palette || '')

            paletteFromConfigs && applyCustomizationSettings(paletteFromConfigs)
        }
    }, [])

    return children;
};
