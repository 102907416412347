import classnames from 'classnames';
import React from 'react';

export interface TabItem {
    value: string;
    label: string | React.ReactNode;
}

export interface TabProps {
    /**
     * Class names of Tab main box
     * Multiple class names can be separated by spaces
     */
    mainClassName?: string;
    /**
     * Class names of basic button
     * Multiple class names can be separated by spaces
     */
    basicClassName?: string;
    /**
     * Class names of selected button
     * Multiple class names can be separated by spaces
     */
    selectedClassName?: string;
    /**
     * Content of each button
     */
    tabsContent: string[] | TabItem[];
    /**
     * Aria label for tab
     */
    ariaLabel?: string;
    /**
     * Content of button to highlight it as Selected
     */
    selectedContent: string | TabItem;
    /**
     * Function that hadles click on button, parameter is the name of tab
     */
    onClick?: (tab: string) => void;
}

export const Tab: React.FC<TabProps> = ({
    mainClassName = 'relative z-0 inline-flex rounded-md shadow-xs -space-x-px',
    basicClassName = 'bg-white border-gray-200 text-gray-500 hover:bg-gray-50 relative inline-flex items-center justify-content px-4 py-1 border text-xs font-medium leading-4',
    selectedClassName = 'z-10 bg-indigo-50 text-indigo-600 relative inline-flex items-center justify-content px-4 py-1 border text-xs leading-4 font-medium',
    ariaLabel = 'Tab',
    tabsContent,
    selectedContent,
    onClick,
}: TabProps) => {
    const buttonsArray = React.useMemo(() => {
        return tabsContent?.map((buttonValue, buttonKey) => {
            const classForCurrent = classnames(
                buttonValue === selectedContent
                    ? selectedClassName
                    : basicClassName,
                {
                    'rounded-l': buttonKey === 0,
                    'rounded-r': buttonKey === tabsContent.length - 1,
                }
            );

            return (
                <button
                    className={classForCurrent}
                    key={buttonKey}
                    onClick={() => {
                        onClick?.(
                            typeof buttonValue === 'string'
                                ? buttonValue
                                : buttonValue.value
                        );
                    }}
                    disabled={buttonValue === selectedContent}
                >
                    {typeof buttonValue === 'string'
                        ? buttonValue
                        : buttonValue.label}
                </button>
            );
        });
    }, [tabsContent, selectedContent]);

    return (
        <nav data-testid="nav" className={mainClassName} aria-label={ariaLabel}>
            {buttonsArray}
        </nav>
    );
};
