import { Combobox, Listbox, Transition } from '@headlessui/react';
import {
    CheckIcon,
    ChevronUpDownIcon as SelectorIcon,
} from '@heroicons/react/24/solid';
import classnames from 'classnames';
import Image from 'next/image';
import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react';

export interface DropdownItem {
    id: string | number;
    value: string;
    icon?: string | JSX.Element;
    label?: string | React.ReactNode;
}

export interface DropdownProps {
    /**
     * List of options
     */
    list: DropdownItem[] | string[];
    /**
     * Selected option of list
     */
    selected: DropdownItem | string;
    /**
     * Label
     */
    label?: string | React.ReactNode;
    /**
     * Label classNames
     * multiple class names can be separated by space
     */
    labelClassNames?: string;
    /**
     * Toggle button classNames
     */
    buttonClassNames?: string;
    /**
     * Dropdown background color
     */
    listOptionClassName?: string;
    /**
     * Dropdown option background color and text color
     */
    optionColorClass?: string;
    /**
     * Dropdown option mouseover background color and text color
     */
    optionColorSelectedClass?: string;
    /**
     * Dropdown option background color if selected
     */
    optionColorActiveClass?: string;
    /**
     * If true, input height 12rem(48px)
     * by default, input height 9rem(36px)
     */
    lg?: boolean;
    /**
     * If true, display icon on the left each option
     */
    hasIcon?: boolean;
    /**
     * Selection callback
     */
    onSelect: (item: DropdownItem | string) => void;

    selectorIconColor?: string;

    selectedIconColor?: string;

    selectorIconClass?: string;

    checkIconOnSelected?: boolean;

    isSmall?: boolean;
    /**
     * Enable search option (auto complete) for the dropdown
     */
    enableSearch?: boolean;
    /**
     * Class for text input of search dropdown
     */
    searchInputClass?: string;
    /**
     * Display text for empty search result
     */
    searchEmptyResultText?: string | React.ReactNode;
    /**
     * Determines whether the selector should be disabled
     */
    disabled?: boolean;

    testLabel?: string;
    /**
     * Custom selector Icon
     */
    customSelectorIcon?: React.ReactNode;

    customButtonHeight?: boolean;
}

export const Dropdown = ({
    list,
    selected,
    label,
    onSelect,
    lg,
    hasIcon,
    labelClassNames = 'mb-1 block text-sm font-medium text-gray-500',
    buttonClassNames = 'relative w-full bg-white border border-gray-200 rounded shadow-lg pl-3 pr-10 text-left cursor-default focus:outline-none',
    listOptionClassName = 'bg-white shadow-lg capitalize',
    optionColorClass = 'text-gray-900',
    optionColorSelectedClass = 'text-white bg-indigo-600',
    optionColorActiveClass = 'text-white bg-indigo-600',
    selectorIconColor = 'text-gray-400',
    checkIconOnSelected = true,
    isSmall,
    enableSearch,
    searchInputClass = 'w-full border-none focus:ring-0 py-2 pl-3 pr-10 leading-5 text-gray-900',
    searchEmptyResultText = 'No assets found.',
    disabled,
    selectedIconColor = 'text-indigo-600',
    testLabel = 'dropDown',
    customSelectorIcon,
    selectorIconClass = 'ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none',
    customButtonHeight = false,
}: DropdownProps) => {
    const [query, setQuery] = useState('');

    const cnBtn = classnames(buttonClassNames, {
        'h-12': lg && !customButtonHeight,
        'h-10': !lg && !customButtonHeight,
        '!bg-neutral-control-color-30 opacity-70': disabled,
    });

    const cnName = classnames('block truncate', { 'ml-3': hasIcon });
    const cnOption = classnames(
        'cursor-default select-none relative py-2 pl-3 pr-9 mx-1 rounded-sm',
        optionColorClass
    );
    const cnOptionActive = classnames(
        'cursor-default select-none relative py-2 pl-3 pr-9 mx-1 rounded-sm',
        optionColorActiveClass
    );
    const cnOptionSelected = classnames(
        'cursor-default select-none relative py-2 pl-3 pr-9 mx-1 rounded-sm',
        optionColorSelectedClass
    );

    const renderIcon = useCallback(
        (icon: any) =>
            typeof icon === 'string' ? (
                <div className="flex-shrink-0 h-6 w-6 rounded-full">
                    <Image src={icon} alt="" layout="fill" />
                </div>
            ) : (
                icon
            ),
        []
    );

    const buttonRef = useRef<HTMLButtonElement>(null);
    const dropdownRef = useRef<HTMLUListElement>(null);

    const openMenu = () => {
        buttonRef?.current?.click();
    };

    const closeMenu = () => {
        dropdownRef?.current?.dispatchEvent(
            new KeyboardEvent('keydown', {
                key: 'Escape',
                bubbles: true,
                cancelable: true,
            })
        );
    };

    const handleSelect = useCallback(
        (item: DropdownItem | string) => {
            closeMenu();
            onSelect(item);
        },
        [onSelect]
    );

    const renderAutoCompleteDropdown = useMemo(() => {
        const filteredList =
            query === ''
                ? list
                : (list as any).filter((item: DropdownItem | string) =>
                      typeof item === 'string'
                          ? item
                                .toLowerCase()
                                .replace(/\s+/g, '')
                                .includes(
                                    query.toLowerCase().replace(/\s+/g, '')
                                )
                          : item?.value
                                .toLowerCase()
                                .replace(/\s+/g, '')
                                .includes(
                                    query.toLowerCase().replace(/\s+/g, '')
                                )
                  );

        return (
            <Combobox
                disabled={disabled}
                value={selected}
                onChange={handleSelect}
            >
                {({ open }) => (
                    <>
                        {label && (
                            <Combobox.Label className={labelClassNames}>
                                {label}
                            </Combobox.Label>
                        )}
                        <div
                            className={classnames('relative', {
                                'w-[40%]': isSmall,
                            })}
                        >
                            <div className={cnBtn}>
                                <div className="flex items-center w-full">
                                    {hasIcon &&
                                        typeof selected !== 'string' &&
                                        renderIcon(selected.icon)}
                                    <Combobox.Input
                                        className={searchInputClass}
                                        displayValue={(item: DropdownItem) =>
                                            item?.value
                                        }
                                        onChange={(event) =>
                                            setQuery(event.target?.value)
                                        }
                                        onClick={openMenu}
                                        onBlur={closeMenu}
                                    />
                                </div>
                                <Combobox.Button
                                    ref={buttonRef}
                                    className="absolute inset-y-0 right-0 flex items-center pr-2"
                                >
                                    {customSelectorIcon ? (
                                        customSelectorIcon
                                    ) : (
                                        <SelectorIcon
                                            className={classnames(
                                                'h-5 w-5',
                                                selectorIconColor
                                            )}
                                            aria-hidden="true"
                                        />
                                    )}
                                </Combobox.Button>
                            </div>
                            <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                                afterLeave={() => setQuery('')}
                            >
                                <Combobox.Options
                                    static
                                    ref={dropdownRef}
                                    className={`absolute z-50 w-full py-1 mt-1 overflow-auto text-base no-scrollbar ${listOptionClassName} rounded max-h-64 ring-1 ring-black ring-opacity-5 focus:outline-none`}
                                >
                                    {filteredList.length === 0 &&
                                    query !== '' ? (
                                        <div className="cursor-default select-none relative py-2 px-4 text-gray-700">
                                            {searchEmptyResultText}
                                        </div>
                                    ) : (
                                        filteredList.map(
                                            (
                                                item: DropdownItem | string,
                                                dropdownItemIndex: number
                                            ) => {
                                                const isSelected =
                                                    typeof item !== 'string' &&
                                                    typeof selected !== 'string'
                                                        ? selected.id ===
                                                          item.id
                                                        : item === selected;

                                                return (
                                                    <Combobox.Option
                                                        key={dropdownItemIndex}
                                                        className={({
                                                            active,
                                                        }) =>
                                                            isSelected
                                                                ? cnOptionSelected
                                                                : active
                                                                ? cnOptionActive
                                                                : cnOption
                                                        }
                                                        value={item}
                                                    >
                                                        <div className="flex flex-row">
                                                            {isSelected &&
                                                            checkIconOnSelected ? (
                                                                <span className="flex items-center pr-1 text-neutral-control-layer-color-100">
                                                                    <CheckIcon
                                                                        className="h-4 w-4"
                                                                        aria-hidden="true"
                                                                    />
                                                                </span>
                                                            ) : null}
                                                            <div className="flex items-center">
                                                                {hasIcon &&
                                                                    typeof item !==
                                                                        'string' &&
                                                                    renderIcon(
                                                                        item.icon
                                                                    )}
                                                                <span
                                                                    className={
                                                                        cnName
                                                                    }
                                                                >
                                                                    {typeof item ===
                                                                    'string'
                                                                        ? item
                                                                        : item.label ||
                                                                          item.value}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Combobox.Option>
                                                );
                                            }
                                        )
                                    )}
                                </Combobox.Options>
                            </Transition>
                        </div>
                    </>
                )}
            </Combobox>
        );
    }, [
        query,
        list,
        selected,
        label,
        isSmall,
        cnBtn,
        cnName,
        searchInputClass,
        selectorIconColor,
        listOptionClassName,
        searchEmptyResultText,
        cnOption,
        cnOptionActive,
    ]);

    const renderDropdown = useMemo(() => {
        return (
            <Listbox value={selected} onChange={onSelect}>
                {label && (
                    <Listbox.Label className={labelClassNames}>
                        {label}
                    </Listbox.Label>
                )}
                <div
                    aria-label={testLabel}
                    data-testid={testLabel}
                    className={classnames('relative', {
                        'w-[40%] mb-2': isSmall,
                    })}
                >
                    {selected && (
                        <Listbox.Button className={cnBtn}>
                            <span className="flex items-center">
                                {hasIcon &&
                                    typeof selected !== 'string' &&
                                    renderIcon(selected.icon)}
                                <span className={cnName}>
                                    {typeof selected === 'string'
                                        ? selected
                                        : selected?.label || selected?.value}
                                </span>
                            </span>
                            <span className={selectorIconClass}>
                                {customSelectorIcon ? (
                                    customSelectorIcon
                                ) : (
                                    <SelectorIcon
                                        className={classnames(
                                            'h-5 w-5',
                                            selectorIconColor
                                        )}
                                        aria-hidden="true"
                                    />
                                )}
                            </span>
                        </Listbox.Button>
                    )}
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options
                            className={`absolute z-50 mt-1 w-full no-scrollbar ${listOptionClassName} max-h-64 rounded py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none`}
                        >
                            {list.map(
                                (
                                    item: DropdownItem | string,
                                    dropdownItemIndex
                                ) => {
                                    const isSelected =
                                        typeof item !== 'string' &&
                                        typeof selected !== 'string'
                                            ? selected.id === item.id
                                            : item === selected;

                                    return (
                                        <Listbox.Option
                                            key={
                                                typeof item === 'string'
                                                    ? dropdownItemIndex
                                                    : item.id
                                            }
                                            className={({ active }) =>
                                                isSelected
                                                    ? cnOptionSelected
                                                    : active
                                                    ? cnOptionActive
                                                    : cnOption
                                            }
                                            value={item}
                                        >
                                            <div className="flex flex-row">
                                                {isSelected &&
                                                checkIconOnSelected ? (
                                                    <span className="flex items-center pr-1 text-black">
                                                        <CheckIcon
                                                            className="h-4 w-4"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                ) : null}
                                                <div className="flex items-center">
                                                    {hasIcon &&
                                                        typeof item !==
                                                            'string' &&
                                                        renderIcon(item.icon)}
                                                    <span className={cnName}>
                                                        {typeof item ===
                                                        'string'
                                                            ? item
                                                            : item?.label ||
                                                              item?.value}
                                                    </span>
                                                </div>
                                            </div>
                                        </Listbox.Option>
                                    );
                                }
                            )}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        );
    }, [
        list,
        selected,
        label,
        isSmall,
        cnBtn,
        cnName,
        selectorIconColor,
        listOptionClassName,
        cnOption,
        cnOptionActive,
    ]);

    return enableSearch ? renderAutoCompleteDropdown : renderDropdown;
};
