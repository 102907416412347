import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import React, { FC, useMemo } from 'react';
import { CrossIcon } from 'web-sdk/assets/images';

export interface ModalProps {
    /*
     * Form title labes
     */
    label?: string | React.ReactNode;
    /*
     * Form body
     */
    modalbody?: React.ReactElement;
    /*
     * Hide/show confirm button
     */
    isConfirmPresent?: boolean;
    /*
     *Hide/show close button
     */
    isClosePresent?: boolean;
    /**
     * Styles for background
     */
    classNameBackground?: string;
    /**
     * Label for Confirm button
     */
    confirmButtonLabel?: string | React.ReactNode;
    /*
     * Label for Close button
     */
    closeButtonLabel?: string | React.ReactNode;
    /*
     * Classes for Confirm button
     */
    classNameConfirmButton?: string;
    /**
     * Classes for Close button
     */
    classNameCloseButton?: string;
    /*
     * Classes for Form label
     */
    classNameFormLabel?: string;
    /**
     * Function for Confirm button
     */
    onConfirm?: (value?: string) => void;
    /**
     * Function for Close button/icon
     */
    onClose?: (value?: string) => void;
    crossButtonClassName?: string;
    crossButtonContent?: React.ReactNode;
    modalClassName?: string;
    /**
     * Hide close icon at top right of the modal
     */
    hideCloseIcon?: boolean;
    /**
     * Icon to display on the modal
     */
    modalIcon?: JSX.Element;
    /**
     * Classes for body section
     */
    bodyClassName?: string;
    /**
     * Classes for footer section
     */
    footerClassName?: string;
    /**
     * Tooltip message
     */
    tooltipMessage?: string | React.ReactNode;
    /**
     * Demo mode
     */
    isDemoMode?: boolean;
    /**
     * Tooltip message style
     */
    tooltipMessageClassName?: string;
    /**
     * Tooltip style
     */
    tooltipClassName?: string;
}

export const Modal: FC<ModalProps> = ({
    label = 'Deposit',
    modalbody,
    classNameBackground = 'fixed inset-0 transition-opacity blur',
    isConfirmPresent = true,
    isClosePresent = true,
    confirmButtonLabel = 'Confirm',
    closeButtonLabel = 'Close',
    classNameConfirmButton = 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-cta-color-60 text-base font-medium text-primary-cta-layer-color-60 hover:bg-primary-cta-color-40 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm',
    classNameCloseButton = 'mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-neutral-control-color-0 text-base font-medium text-neutral-control-layer-color-80 hover:text-primary-cta-layer-color-40 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm',
    classNameFormLabel = 'text-lg leading-6 font-medium text-gray-900',
    onConfirm,
    onClose = () => alert('Close'),
    crossButtonClassName = 'bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
    crossButtonContent,
    modalClassName = 'inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6',
    hideCloseIcon,
    modalIcon,
    bodyClassName = 'flex',
    footerClassName = 'mt-5 sm:mt-4 sm:flex sm:flex-row-reverse',
    tooltipMessage = '“Demo” mode. Functionality is disabled.',
    tooltipMessageClassName = 'items-start w-full font-light text-sm text-text-color-70 leading-snug relative group',
    tooltipClassName = 'shadow-lg border border-neutral-200 rounded bg-body-background-color px-3 py-2',
    isDemoMode = false,
}: ModalProps) => {
    const renderTooltippedButton = useMemo(() => {
        return isDemoMode ? (
            <TooltipPrimitive.TooltipProvider>
                <TooltipPrimitive.Root delayDuration={0}>
                    <TooltipPrimitive.Trigger asChild>
                        <button
                            onClick={() => onConfirm && onConfirm()}
                            type="button"
                            className={classNameConfirmButton}
                        >
                            {confirmButtonLabel}
                        </button>
                    </TooltipPrimitive.Trigger>
                    <TooltipPrimitive.Portal>
                        <TooltipPrimitive.Content sideOffset={5} side="top">
                            <div className={tooltipClassName}>
                                <span className={tooltipMessageClassName}>
                                    {tooltipMessage}
                                </span>
                            </div>
                        </TooltipPrimitive.Content>
                    </TooltipPrimitive.Portal>
                </TooltipPrimitive.Root>
            </TooltipPrimitive.TooltipProvider>
        ) : (
            <button
                onClick={() => onConfirm && onConfirm()}
                type="button"
                className={classNameConfirmButton}
            >
                {confirmButtonLabel}
            </button>
        );
    }, [onConfirm, isDemoMode, classNameConfirmButton]);

    const renderConfirmButton = useMemo(() => {
        return <div>{isConfirmPresent ? renderTooltippedButton : ''}</div>;
    }, [isConfirmPresent, onConfirm, isDemoMode, classNameConfirmButton]);

    const renderCloseButton = useMemo(() => {
        return (
            <div>
                {isClosePresent ? (
                    <button
                        data-testid="root-closeButton"
                        onClick={() => onClose && onClose()}
                        type="button"
                        className={classNameCloseButton}
                    >
                        {closeButtonLabel}
                    </button>
                ) : (
                    ''
                )}
            </div>
        );
    }, [isClosePresent, onClose]);

    return (
        <div
            className="fixed z-40 inset-0 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className={classNameBackground} aria-hidden="true" />
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <div className={modalClassName}>
                    {!hideCloseIcon && (
                        <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                            <button
                                data-testid="root-crossButton"
                                onClick={() => onClose && onClose()}
                                type="button"
                                className={crossButtonClassName}
                            >
                                {crossButtonContent || <CrossIcon />}
                            </button>
                        </div>
                    )}
                    <div className={bodyClassName}>
                        {modalIcon && modalIcon}
                        <div className="mt-3 text-left sm:mt-0 sm:text-left w-full">
                            <h3 className={classNameFormLabel} id="modal-title">
                                {label}
                            </h3>
                            {modalbody}
                        </div>
                    </div>
                    <div className={footerClassName}>
                        {renderConfirmButton}
                        {renderCloseButton}
                    </div>
                </div>
            </div>
        </div>
    );
};
