import React, { useEffect } from 'react';
import { ColorThemeProvider } from '../ColorThemeProvider';
import { ReduxProvider } from '../ReduxProvider';
import { useTranslation } from 'next-i18next';

interface CoreProviderProps {
    children: JSX.Element | JSX.Element[];
}

export const CoreProvider: React.FC<CoreProviderProps> = ({
    children,
}) => {
    const { i18n } = useTranslation('common')
    
    useEffect(() => {
        console.log('Resolved fallback languages:', i18n.services.languageUtils.toResolveHierarchy(i18n.language));
    }, [i18n.language]);

    return (
        <ReduxProvider>
            <ColorThemeProvider>
                <>{children}</>
            </ColorThemeProvider>
        </ReduxProvider>
    );
};
