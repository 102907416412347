import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { alertsReducer } from '../features/alerts/alertsSlice';
import { globalSettingsReducer } from '../features/globalSettings/globalSettingsSlice';
import { marketsReducer } from '../features/markets/marketsSlice';
import { symbolsReducer } from '../features/symbols/symbolsSlice';
import { tickersReducer } from '../features/tickers/tickersSlice';
import { tokensReducer } from '../features/tokens/tokensSlice';
import { userReducer } from '../features/user/userSlice';
import { articleReducer } from '../features/article/articleSlice';
import { assetArticleReducer } from '../features/assetArticle/assetArticleSlice';
import { categoryReducer } from '../features/category/categorySlice';
import { tagReducer } from '../features/tag/tagSlice';

export * from '../features/alerts/alertsSlice';
export * from '../features/globalSettings/globalSettingsSlice';
export * from '../features/markets/marketsSlice';
export * from '../features/symbols/symbolsSlice';
export * from '../features/tickers/tickersSlice';
export * from '../features/tokens/tokensSlice';
export * from '../features/user/userSlice';
export * from '../features/article/articleSlice';
export * from '../features/assetArticle/assetArticleSlice';
export * from '../features/category/categorySlice';
export * from '../features/tag/tagSlice';

export const store = configureStore({
    reducer: {
        alerts: alertsReducer,
        globalSettings: globalSettingsReducer,
        markets: marketsReducer,
        symbols: symbolsReducer,
        tickers: tickersReducer,
        tokens: tokensReducer,
        user: userReducer,
        article: articleReducer,
        assetArticle: assetArticleReducer,
        category: categoryReducer,
        tag: tagReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
