import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Token } from '../../../../types';

export interface TokensState {
    list: Token[];
    currentToken?: Token;
    tokensLoading: boolean;
}

export const initialTokensState: TokensState = {
    list: [],
    tokensLoading: true,
};

const tokensSlice = createSlice({
    name: 'tokens',
    initialState: initialTokensState,
    reducers: {
        saveTokens(state, action: PayloadAction<Token[]>) {
            state.list = action.payload;
            state.tokensLoading = false;
        },
        setCurrentToken(state, action: PayloadAction<Token>) {
            state.currentToken = action.payload;
        },
        initializeCurrentToken(state, action: PayloadAction<Token>) {
            if (!state.currentToken) {
                state.currentToken = action.payload;
            }
        },
        resetTokensLoading(state) {
            state.tokensLoading = false;
        },
    },
});

export const {
    saveTokens,
    setCurrentToken,
    initializeCurrentToken,
    resetTokensLoading,
} = tokensSlice.actions;
export const tokensReducer = tokensSlice.reducer;
export default tokensSlice.reducer;
