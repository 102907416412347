import React, { useMemo } from 'react';
import { SVGICON } from '../../helpers/svgicon';
import { DEFAULT_USER_ICON } from '../User/User.default';
export interface LoginButtonProps  {
    /**
     * What class name to use
     */
    classNames?: string;
    /**
     * Button contents
     */
    label?: string;
    /**
     * Link to login page
     */
    link?: string;
    /**
     * Is collapesed
     */
    isCollapsed?: boolean;
}

/**
 * Login button component
 */
export const LoginButton = ({
    classNames = 'flex items-center justify-center bg-primary-cta-color-main hover:bg-primary-cta-color-hover text-color-contrast font-bold py-2 px-4 rounded min-w-full',
    link = '/',
    label,
    isCollapsed,
}: LoginButtonProps) => {

    const renderLabel = useMemo(() => <div>{isCollapsed ? <SVGICON>{DEFAULT_USER_ICON}</SVGICON> : label}</div>, [isCollapsed]);

    return (
        <a
            type="button"
            className={isCollapsed ? 'pl-2' : classNames}
            href={link}
        >
            {renderLabel}
        </a>
    );
};
