import throttle from 'lodash/throttle'
import { useEffect, useRef, useState } from 'react'

interface HookParams {
  navigationItems: any[]
  navigationContainer: HTMLElement | null
  navigation: HTMLElement | null
  moreMenu: HTMLElement | null
  minimumNumberInNav?: number
}

export function usePriorityNavigation({
  navigationItems = [],
  navigationContainer,
  navigation,
  moreMenu,
  minimumNumberInNav = 4,
}: HookParams) {
  const widthsArray = useRef<number[]>([])
  const [moreItems, setMoreItems] = useState<any[]>([])
  const [priorityItems, setPriorityItems] = useState(navigationItems)

  const howManyItemsInMenuArray = (array: number[], outerWidth: number, initialWidth: number) => {
    let total = initialWidth

    for (let i = 0; i < array.length; i++) {
      if (total + array[i] > outerWidth - 150) {
        return i < minimumNumberInNav ? minimumNumberInNav : i
      } else {
        total += array[i]
      }
    }
    return array.length
  }

  const updateNavigation = () => {
    if (!navigationContainer) {
      return
    }

    const outerWidth = navigationContainer.getBoundingClientRect().width
    const moreMenuWidth = moreMenu ? moreMenu.getBoundingClientRect().width : 0

    const arrayAmount = howManyItemsInMenuArray(widthsArray.current, outerWidth, moreMenuWidth)

    const navItemsCopy = navigationItems.slice()
    const priorityItems = navItemsCopy.slice(0, arrayAmount)
    const moreItems =
      priorityItems.length !== navItemsCopy.length ? navItemsCopy.slice(arrayAmount, navItemsCopy.length) : []

    setPriorityItems(priorityItems)
    setMoreItems(moreItems)
  }

  useEffect(() => {
    if (!navigation) {
      return
    }

    widthsArray.current = Array.from(navigation.children).map(item => item.getBoundingClientRect().width)

    const throttledCallback = throttle(updateNavigation, 500)

    window.addEventListener('resize', throttledCallback)
    updateNavigation()
    return () => {
      window.removeEventListener('resize', throttledCallback)
    }
  }, [navigation, moreMenu])

  return [priorityItems, moreItems]
}
