import { Ticker } from '../../../../types';

export const formatTicker = (events: (string | number)[][]): { [pair: string]: Ticker } => {
    if (!events) {
        return {};
    }

    const tickers: any = {};
    const keys = ['name', 'at', 'min', 'max', 'open', 'last', 'volume', 'amount', 'vwap', 'price_change_percent'];

    for (const i of events) {
        for (const keyIndex in keys) {
            if (keys[keyIndex] === 'price_change_percent') {
                tickers[i[0]] = {
                    ...tickers[i[0]],
                    price_change_percent: i[keyIndex],
                };
            } else {
                tickers[i[0]] = {
                    ...tickers[i[0]],
                    [keys[keyIndex]]: i[keyIndex],
                };
            }
        }
    }

    return tickers;
};
