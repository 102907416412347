import { FC } from 'react'

interface CloudUploadIconProps {
  fill?: string
}

export const CloudUploadIcon: FC<CloudUploadIconProps> = ({ fill = 'var(--app-neutral-control-layer-color-70)' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.39844 12C2.39844 14.651 4.54747 16.8 7.19844 16.8H10.7984V20.4C10.7984 21.0628 11.3357 21.6 11.9984 21.6C12.6612 21.6 13.1984 21.0628 13.1984 20.4V16.8H16.7984C19.4494 16.8 21.5984 14.651 21.5984 12C21.5984 9.34906 19.4494 7.20002 16.7984 7.20002C16.7984 4.54906 14.6494 2.40002 11.9984 2.40002C9.34747 2.40002 7.19844 4.54906 7.19844 7.20002C4.54747 7.20002 2.39844 9.34906 2.39844 12ZM13.1984 16.8H10.7984L10.7984 11.2971L9.24697 12.8486C8.77834 13.3172 8.01854 13.3172 7.54991 12.8486C7.08128 12.3799 7.08128 11.6201 7.54991 11.1515L11.1499 7.5515C11.6185 7.08287 12.3783 7.08287 12.847 7.5515L16.447 11.1515C16.9156 11.6201 16.9156 12.3799 16.447 12.8486C15.9783 13.3172 15.2185 13.3172 14.7499 12.8486L13.1984 11.2971V16.8Z"
        fill={fill}
      />
    </svg>
  )
}
