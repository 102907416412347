export const formatWithSeparators = (value: string, thousSep?: string, precision?: number, floatSep?: string) => {
  let fmtNum = value

  if (!fmtNum) {
    return ''
  }

  if (thousSep !== floatSep) {
    if (isNaN(fmtNum as any)) {
      return ''
    }

    if (floatSep) {
      fmtNum = fmtNum.replace('.', floatSep)
    }

    if ((thousSep && floatSep) || (thousSep && !floatSep && thousSep !== '.')) {
      const fmtNumParts = fmtNum?.toString().split(floatSep || '.')

      fmtNumParts[0] = fmtNumParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousSep)
      if (fmtNumParts[1]) {
        fmtNumParts[1] = precision ? fmtNumParts[1].slice(0, precision) : fmtNumParts[1]
        fmtNum = precision !== 0 ? fmtNumParts.join(floatSep || '.') : fmtNumParts[0]
      } else {
        fmtNum = fmtNumParts[0]
      }
    }
  }

  return fmtNum
}
