import React from 'react';
import { SVGICON } from '../../helpers/svgicon';

export const DEFAULT_USER_ICON = (
    <SVGICON>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="16" fill="#EFEFEF"/>
            <g opacity="0.48">
                <path d="M16 23.2C13.5 23.2 11.29 21.92 10 20C10.03 18 14 16.9 16 16.9C18 16.9 21.97 18 22 20C20.71 21.92 18.5 23.2 16 23.2ZM16 9C16.7956 9 17.5587 9.31607 18.1213 9.87868C18.6839 10.4413 19 11.2044 19 12C19 12.7956 18.6839 13.5587 18.1213 14.1213C17.5587 14.6839 16.7956 15 16 15C15.2044 15 14.4413 14.6839 13.8787 14.1213C13.3161 13.5587 13 12.7956 13 12C13 11.2044 13.3161 10.4413 13.8787 9.87868C14.4413 9.31607 15.2044 9 16 9ZM16 6C14.6868 6 13.3864 6.25866 12.1732 6.7612C10.9599 7.26375 9.85752 8.00035 8.92893 8.92893C7.05357 10.8043 6 13.3478 6 16C6 18.6522 7.05357 21.1957 8.92893 23.0711C9.85752 23.9997 10.9599 24.7362 12.1732 25.2388C13.3864 25.7413 14.6868 26 16 26C18.6522 26 21.1957 24.9464 23.0711 23.0711C24.9464 21.1957 26 18.6522 26 16C26 10.47 21.5 6 16 6Z" fill="#090909"/>
            </g>
        </svg>
    </SVGICON>);

export const DEFAULT_WALLET_ICON = (
    <SVGICON>
        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16.5" r="16" fill="#EFEFEF"/>
            <path opacity="0.48" d="M24.6862 14.8723H24.5851V13.8617C24.5851 12.6894 23.716 11.7191 22.5638 11.5574V10.3245C22.5638 9.05106 21.5128 8 20.2394 8H9.46596C8.13191 8 7.02021 9.07128 7 10.4255V21.8457C7 23.1191 8.05106 24.1702 9.32447 24.1702H22.2606C23.534 24.1702 24.5851 23.1191 24.5851 21.8457V20.734H24.6862C25.4138 20.734 26 20.1479 26 19.4202V16.1862C26 15.4585 25.4138 14.8723 24.6862 14.8723ZM8.41489 10.466C8.41489 9.87979 8.9 9.41489 9.46596 9.41489H20.2191C20.7245 9.41489 21.1287 9.81915 21.1287 10.3245V11.5372H9.46596C8.9 11.5372 8.41489 11.0521 8.41489 10.466ZM24.5851 19.3191H21.25C20.4213 19.3191 19.734 18.6319 19.734 17.8032C19.734 16.9745 20.4213 16.2872 21.25 16.2872H24.5851V19.3191Z" fill="#090909"/>
        </svg>
    </SVGICON>);
