import React from 'react';
import { ButtonProps, navigationApp } from '.';
import { DEFAULT_USER_MENU } from '../UserMenu/UserMenu.default';
import { DEFAULT_USER_ICON } from '../User/User.default';
import { UserProps } from '../User';
import { LoginButtonProps } from '../LoginButton';
import { buttonsList, navigationMobile, navigations } from './Sidebar.constants';
import { SidebarIcons } from '../../assets/images/SidebarIcons';
// @ts-ignore
import * as tw from '../../../tailwind.config'
const textColors = tw.theme.extend.textColor

export const DEFAULT_NAVIGATIONS: navigationApp[] = navigations;
export const DEFAULT_MOBILE_NAVIGATIONS: navigationApp[] = navigationMobile;

export const DEFAULT_NAV_OVERLAY_TOGGLER_ICON: JSX.Element = (
    <SidebarIcons
        name="menu"
        className="neutral-control-layer-color-40 flex-shrink-0 w-5 h-5"
        primaryColor={textColors['neutral-control-layer-color-40']}
    />
);

export const DEFAULT_NAV_OVERLAY_CLASSES = 'relative bg-navbar-background-color flex-1 flex flex-col max-w-[260px] pt-5 pb-4 bg-white';

export const DEFAULT_BOTTOM_BAR_CLASSES = 'fixed w-screen bottom-0 z-10 flex-shrink-0 flex h-16 bg-white border-t border-gray-200 w-full left-0';

export const LOGIN_BUTTON_PROPS: LoginButtonProps = {
  label: 'Login',
  link: '/signin',
  isCollapsed: false,
};

export const DEFAULT_USER_MENU_PROPS: UserProps = {
  userMenuProps: {
      userMenu: DEFAULT_USER_MENU,
  },
  userInfo: {
      username: 'Geffen',
      uid: 'UID3849991497147',
      icon: DEFAULT_USER_ICON,
  },
  isCollapsed: false,
};

export const DEFAULT_BUTTONS_LIST: ButtonProps[] = buttonsList;
