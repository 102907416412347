import { Combobox, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import classnames from 'classnames'
import CustomLink from 'components/CustomLink'
import { usePriorityNavigation } from 'hooks/usePriorityNavigation'
import { useRouter } from 'next/router'
import React, { FC, ReactNode, useCallback, useRef } from 'react'
import { useSetMobileDevice } from 'web-sdk'

interface NavItem {
  name: string | ReactNode
  href: string
}

export interface HeaderOptions {
  navigations: NavItem[]
  customHeaderContent?: React.ReactNode
  /**
   * Response from server for detecting mobile device
   */
  responseIsMobile?: boolean
}

export interface CustomHeaderProps {
  options?: HeaderOptions
  headerLogoIcon?: JSX.Element
  show?: boolean
  actionButtonProps: {
    text: JSX.Element | string
    link: string
  }
  hideOptionsMenu: boolean
}

export const AppHeader: FC<CustomHeaderProps> = ({
  options,
  headerLogoIcon,
  actionButtonProps,
  hideOptionsMenu,
  show = true,
}: CustomHeaderProps) => {
  const router = useRouter()
  const isMobile = useSetMobileDevice(false, 1025)

  const navigationContainer = useRef<HTMLDivElement>(null)
  const navigation = useRef<HTMLElement>(null)
  const moreMenu = useRef<HTMLDivElement>(null)

  const [priorityNavigation, moreNavigation] = usePriorityNavigation({
    navigationItems: options?.navigations || [],
    navigationContainer: navigationContainer.current,
    navigation: navigation.current,
    moreMenu: moreMenu.current,
  })

  const logoClickHandler = () => {
    router.asPath === '/' ? window.scrollTo({ top: 0, behavior: 'smooth' }) : null
  }

  const isActivePath = useCallback(
    (path: string) => {
      return (
        (path === '/' &&
          (router.asPath === '/' || router.asPath === '/assets' || router.asPath.includes('/assets?'))) ||
        (path !== '/' && router.asPath.includes(path))
      )
    },
    [router.asPath],
  )

  const getLinkHref = React.useCallback((item: any) => {
    const destinationPathParts = {
      prefix: '/assets',
      middle: item.href === '/' ? '' : item.href,
      suffix: '',
    }

    return `${destinationPathParts.prefix}${destinationPathParts.middle}${destinationPathParts.suffix}`
  }, [])

  const renderMobileOptions = (optionsList: HeaderOptions | undefined) => {
    if (hideOptionsMenu) {
      return <></>
    }

    return (
      <nav className="flex">
        {optionsList?.navigations?.map((item: NavItem, index: number) => (
          <CustomLink key={`header-${index}`} href={getLinkHref(item)}>
            <a
              className={classnames(
                'flex items-center w-min-fit cursor-pointer rounded-md px-1 font-metro-bold relative mr-1.5 text-base',
                item.href !== '' && isActivePath(item.href)
                  ? 'h-6 bg-primary-cta-color-60 text-primary-cta-layer-color-60 hover:bg-primary-cta-color-60 md:hover:bg-primary-cta-color-40 w-max md:min-w-fit'
                  : 'text-neutral-control-layer-color-60 w-max md:min-w-fit hover:bg-neutral-control-color-30',
              )}
            >
              {item.name}
            </a>
          </CustomLink>
        ))}
      </nav>
    )
  }

  const renderDesktopOptions = () => {
    if (hideOptionsMenu) {
      return <></>
    }

    const dropdownHasActiveItem = moreNavigation.some((option: NavItem) => isActivePath(option.href))

    return (
      <>
        <nav ref={navigation} className="flex flex-wrap">
          {priorityNavigation.map((item: NavItem, index: number) => (
            <CustomLink key={`header-${index}`} href={getLinkHref(item)}>
              <a
                key={`header-${index}`}
                className={classnames(
                  'flex cursor-pointer w-min-fit w-max rounded-md px-1 font-metro-bold relative items-center ml-1 text-base hover:bg-neutral-control-color-30',
                  isActivePath(item.href)
                    ? 'bg-primary-cta-color-60 text-primary-cta-layer-color-60 hover:bg-primary-cta-color-60 md:hover:bg-primary-cta-color-40'
                    : 'text-neutral-control-layer-color-60',
                )}
              >
                {item.name}
              </a>
            </CustomLink>
          ))}
        </nav>

        {moreNavigation.length > 0 && (
          // eslint-disable-next-line
          <Combobox value="" onChange={() => {}}>
            <div ref={moreMenu} className="ml-1 w-[64px] relative">
              <Combobox.Button
                className={classnames(
                  'flex items-center justify-center cursor-pointer rounded-md px-1 font-metro-bold text-base',
                  dropdownHasActiveItem
                    ? 'bg-primary-cta-color-60 text-primary-cta-layer-color-60 hover:bg-primary-cta-color-60'
                    : 'bg-neutral-control-color-30 text-neutral-control-layer-color-60 hover:bg-neutral-control-color-40',
                )}
              >
                <span className="inline-block">More</span>
                <ChevronDownIcon width={20} />
              </Combobox.Button>
              <Transition>
                <Combobox.Options className="absolute right-0 z-50 no-scrollbar mt-2 bg-dropdown-background-color shadow-sm border border-divider-color-20 max-h-96 rounded text-sm ring-1 ring-black ring-opacity-5 overflow-auto">
                  {moreNavigation.map(category => (
                    <CustomLink href={getLinkHref(category)} key={category.href}>
                      <a
                        className={classnames(
                          'm-[6px] px-4 py-1 rounded-sm block hover:text-neutral-control-layer-color-100 font-metro-regular font-normal whitespace-nowrap',
                          {
                            'bg-primary-cta-color-10 text-neutral-control-layer-color-100': isActivePath(category.href),
                            'text-neutral-control-layer-color-70 hover:bg-neutral-control-color-20': !isActivePath(
                              category.href,
                            ),
                          },
                        )}
                      >
                        {category.name}
                      </a>
                    </CustomLink>
                  ))}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
        )}
      </>
    )
  }

  if (!show) {
    return <></>
  }

  return (isMobile === undefined ? options?.responseIsMobile : !!isMobile) ? (
    <header className="sm:ml-6 md:ml-20">
      <div className="leading-8 flex flex-wrap justify-between px-[18px] md:px-6 pt-4 lg:pb-0">
        <CustomLink href="/">
          <a className="h-11 w-32">
            <div className="cursor-pointer block">{headerLogoIcon}</div>
          </a>
        </CustomLink>
        <CustomLink href={actionButtonProps.link}>
          <a className="button button--main h-9">
            <span className="button__inner font-metro-semibold h-9">{actionButtonProps.text}</span>
          </a>
        </CustomLink>
      </div>
      {options && (
        <div className="leading-8 flex pl-[18px] pr-[10px] md:pl-[24px] mt-6 overflow-scroll no-scrollbar lg:pb-0">
          {options.customHeaderContent ? (
            <div className="flex items-start justify-start">{options.customHeaderContent}</div>
          ) : (
            renderMobileOptions(options)
          )}
        </div>
      )}
    </header>
  ) : (
    <header className="flex items-center justify-between md:w-[calc(100%-80px)] w-full border-b border-divider-color-20 px-4 md:px-6 sticky top-0 md:py-[0.34rem] md:cr-header z-20 bg-body-background-color md:ml-20 ml-0 h-[57px]">
      <div className="leading-8 flex flex-grow items-center lg:pb-0 mr-3">
        <CustomLink href="/">
          <a className="h-11 w-32" aria-label="Home page" onClick={logoClickHandler}>
            <div className="cursor-pointer block">{headerLogoIcon}</div>
          </a>
        </CustomLink>
        {options && (
          <div ref={navigationContainer} className="relative flex flex-wrap flex-grow items-center w-full sm:pl-4 pl-0">
            {options.customHeaderContent ? (
              <div className="flex items-center">{options.customHeaderContent}</div>
            ) : (
              renderDesktopOptions()
            )}
          </div>
        )}
      </div>
      <div className="flex items-center justify-center md:order-2">
        {actionButtonProps.link === 'https://wallet.yellow.com' ? (
          <a className="button button--main h-9" href={actionButtonProps.link} target="_blank" rel="noreferrer">
            <span className="button__inner font-metro-bold h-9">{actionButtonProps.text}</span>
          </a>
        ) : (
          <CustomLink href={actionButtonProps.link}>
            <a className="button button--main h-9">
              <span className="button__inner font-metro-bold h-9">{actionButtonProps.text}</span>
            </a>
          </CustomLink>
        )}
      </div>
    </header>
  )
}

export const CustomHeader = React.memo(AppHeader)
