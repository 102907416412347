import { Color } from './types'

export const rgb2hex = ({ r, g, b, a }: Color['rgb']): Color['hex'] => {
    const hex = [r, g, b, a]
        .map((v, i) =>
            v !== undefined
                ? (i < 3 ? v : Math.round(v * 255))
                      .toString(16)
                      .padStart(2, '0')
                : '',
        )
        .join('')

    return `#${hex}`
}

export const hex2rgb = (hex: Color['hex']): Color['rgb'] => {
    hex = hex.slice(1)

    const r = parseInt(hex.slice(0, 2), 16)
    const g = parseInt(hex.slice(2, 4), 16)
    const b = parseInt(hex.slice(4, 6), 16)
    let a = parseInt(hex.slice(6, 8), 16) || undefined

    if (a) a /= 255

    return { r, g, b, a }
}

export const rgb2hsv = ({ r, g, b, a }: Color['rgb']): Color['hsv'] => {
    r /= 255
    g /= 255
    b /= 255

    const max = Math.max(r, g, b)
    const d = max - Math.min(r, g, b)

    const h = d
        ? (max === r
              ? (g - b) / d + (g < b ? 6 : 0)
              : max === g
              ? 2 + (b - r) / d
              : 4 + (r - g) / d) * 60
        : 0
    const s = max ? (d / max) * 100 : 0
    const v = max * 100

    return { h, s, v, a }
}

export const convert2rgb = (color: any): Color['rgb'] => {
    const rgb: Color['rgb'] = {
        r: color.rgb[0],
        g: color.rgb[1],
        b: color.rgb[2],
    }

    if (color.alpha !== 1) {
        rgb.a = color.alpha
    }

    return rgb
}
