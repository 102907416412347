import React from 'react';
import { SVGICON } from '../../helpers/svgicon';

interface SidebarIconsProps {
    name: string;
    className?: string;
    secondaryColor?: string;
    primaryColor: string;
}

export const SidebarIcons: React.FC<SidebarIconsProps> = ({ name, primaryColor, secondaryColor }: SidebarIconsProps) => {
    switch (name) {
        case 'menu':
            return (
                <SVGICON>
                    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.558 4.11H2.428v3.023h19.13V4.11ZM21.558 10.495H2.428v3.023h19.13v-3.023ZM21.558 16.867H2.428v3.023h19.13v-3.023Z" fill={primaryColor}/>
                    </svg>
                </SVGICON>
            );
        case 'trading':
            return (
                <SVGICON>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.44 11.3398H0V17.9998H4.44V11.3398Z" fill={primaryColor} />
                        <path d="M11.22 0H6.78003V18H11.22V0Z" fill={primaryColor} />
                        <path d="M18.0001 5.98047H13.5601V18.0005H18.0001V5.98047Z" fill={primaryColor} />
                        <path d="M4.44 14.6699H0V17.9999H4.44V14.6699Z" fill={secondaryColor} />
                        <path d="M11.22 9H6.78003V18H11.22V9Z" fill={secondaryColor} />
                        <path d="M18.0001 11.9902H13.5601V18.0002H18.0001V11.9902Z" fill={secondaryColor} />
                    </svg>
                </SVGICON>
            );
        case 'orders':
            return (
                <SVGICON>
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 0.240234C7.81331 0.240234 6.65327 0.592131 5.66658 1.25142C4.67988 1.91071 3.91085 2.84778 3.45672 3.94414C3.0026 5.04049 2.88378 6.2469 3.11529 7.41078C3.3468 8.57467 3.91825 9.64376 4.75737 10.4829C5.59648 11.322 6.66558 11.8934 7.82946 12.1249C8.99335 12.3565 10.1998 12.2376 11.2961 11.7835C12.3925 11.3294 13.3295 10.5603 13.9888 9.57365C14.6481 8.58696 15 7.42692 15 6.24023C15 4.64894 14.3679 3.12282 13.2426 1.9976C12.1174 0.872378 10.5913 0.240234 9 0.240234ZM9 8.99023L6.25 6.24023L9 3.50024L11.75 6.24023L9 8.99023Z" fill={primaryColor} />
                        <path d="M15 18.2398V13.7998H3V18.2398H15Z" fill={secondaryColor} />
                    </svg>
                </SVGICON>
            );
        case 'deposit':
            return (
                <SVGICON>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.0757 11.3969C21.8764 11.3269 21.6887 11.2568 21.5011 11.2101C21.4548 11.1996 21.4067 11.1996 21.3604 11.2101C20.9261 11.0813 20.4765 11.0106 20.0235 11C18.5595 11.0031 17.1564 11.5834 16.1212 12.6138C15.086 13.6443 14.5031 15.0411 14.5 16.4984C14.5004 16.9508 14.5555 17.4015 14.6642 17.8409C14.6642 17.8409 14.6642 17.9342 14.6642 17.9926C14.7161 18.1827 14.7788 18.3698 14.8518 18.553C15.1926 19.3967 15.7384 20.1431 16.4402 20.7254C17.142 21.3076 17.9779 21.7075 18.8731 21.8892C19.7682 22.0708 20.6947 22.0287 21.5694 21.7664C22.4442 21.5041 23.2399 21.03 23.8854 20.3864C24.5309 19.7428 25.0059 18.9499 25.2679 18.0787C25.53 17.2075 25.5708 16.2852 25.3868 15.3944C25.2029 14.5036 24.7998 13.6722 24.2138 12.9745C23.6277 12.2769 22.877 11.7348 22.0288 11.3969H22.0757ZM20.0235 19.0316L18.6162 17.6191L17.4435 16.4517L20.0235 13.9885L20.9734 14.9224L21.1493 15.0858L21.8998 15.833L22.5683 16.4867L20.0235 19.0316Z" fill={primaryColor} />
                        <path d="M16.2998 24.98L18.2798 23L19.9998 24.72L21.7298 23L23.7098 24.98L19.9998 28.68L16.2998 24.98Z" fill={secondaryColor} />
                    </svg>
                </SVGICON>
            );
        case 'withdraw':
            return (
                <SVGICON>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M28.14 24.1396H11.85V26.9496H28.14V24.1396Z" fill={secondaryColor} />
                        <path d="M24.38 17.4996L22.4 19.4796L20 17.0196L17.53 19.4796L15.55 17.4996L20 13.0596L24.38 17.4996Z" fill={primaryColor} />
                    </svg>
                </SVGICON>
            );
        case 'settings':
            return (
                <SVGICON>
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.2641 15.1729V18.1691H6.75848V15.1729C8.21106 15.7171 9.81157 15.7171 11.2641 15.1729Z" fill={secondaryColor} />
                        <path d="M11.2641 0V2.99626C9.81426 2.43731 8.20836 2.43731 6.75848 2.99626V0H11.2641Z" fill={secondaryColor} />
                        <path d="M4.85484 4.04402C3.63461 5.02791 2.83017 6.43568 2.60201 7.98647L0 6.48835L2.25283 2.5459L4.85484 4.04402Z" fill={secondaryColor} />
                        <path d="M18 11.6358L15.7472 15.5895L13.1452 14.0914C14.3705 13.107 15.1758 11.6937 15.398 10.1377L18 11.6358Z" fill={secondaryColor} />
                        <path d="M4.85484 14.0914L2.25283 15.5895L0 11.6358L2.60201 10.1377C2.82424 11.6937 3.62955 13.107 4.85484 14.0914Z" fill={secondaryColor} />
                        <path d="M18 6.48835L15.398 7.98647C15.1698 6.43568 14.3654 5.02791 13.1452 4.04402L15.7472 2.5459L18 6.48835Z" fill={secondaryColor} />
                        <path d="M15.8597 8.20021C15.8745 8.0956 15.8745 7.98943 15.8597 7.88482C15.6184 6.21756 14.7477 4.70601 13.4266 3.66077C13.3659 3.58906 13.2936 3.52812 13.2126 3.48054C12.7562 3.11672 12.2473 2.82428 11.7032 2.6132L11.4329 2.50056C9.86364 1.91518 8.13597 1.91518 6.56675 2.50056L6.29642 2.6132C5.75587 2.82451 5.25067 3.11698 4.79827 3.48054C4.71576 3.53071 4.64006 3.59128 4.57301 3.66077C3.25624 4.70979 2.38667 6.21945 2.13995 7.88482C2.13995 7.97493 2.13993 8.07631 2.08361 8.16642C2.00855 8.73844 2.00855 9.31781 2.08361 9.88983C2.08361 9.97995 2.08363 10.0926 2.13995 10.1827C2.38667 11.8481 3.25624 13.3577 4.57301 14.4068C4.63859 14.4767 4.7102 14.5408 4.78703 14.5982C5.24733 14.9562 5.75536 15.2481 6.29642 15.4656L6.56675 15.567C8.13252 16.1711 9.86709 16.1711 11.4329 15.567L11.7032 15.4656C12.2466 15.2458 12.758 14.954 13.2239 14.5982C13.2991 14.5431 13.3672 14.4787 13.4266 14.4068C14.7477 13.3615 15.6184 11.85 15.8597 10.1827C15.8893 10.0875 15.9081 9.98925 15.916 9.88983C15.9664 9.60737 15.989 9.32064 15.9835 9.03376C15.9688 8.75261 15.9273 8.4735 15.8597 8.20021ZM11.4892 10.453L11.3765 10.6445C11.1263 11.0442 10.7818 11.3765 10.3734 11.6121C9.9649 11.8478 9.50488 11.9797 9.0336 11.9962H8.85336C8.38208 11.9797 7.92205 11.8478 7.51358 11.6121C7.10511 11.3765 6.76067 11.0442 6.51044 10.6445C6.46702 10.5843 6.42931 10.5202 6.39779 10.453C6.18032 10.0399 6.06811 9.57948 6.07112 9.11261C6.06811 8.64214 6.18027 8.17808 6.39779 7.76091C6.42957 7.69742 6.4673 7.63708 6.51044 7.58069C6.75977 7.18103 7.10427 6.84941 7.51314 6.61549C7.922 6.38157 8.38247 6.25264 8.85336 6.24026H9.10118C9.57207 6.25264 10.0325 6.38157 10.4414 6.61549C10.8503 6.84941 11.1948 7.18103 11.4441 7.58069C11.4441 7.64827 11.523 7.70459 11.5568 7.76091C11.7725 8.17926 11.8882 8.64196 11.8947 9.11261C11.8746 9.56619 11.7513 10.0092 11.5342 10.408L11.4892 10.453Z" fill={primaryColor} />
                    </svg>
                </SVGICON>
            );
        case 'history':
            return (
                <SVGICON>
                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.2297 4.44V0L0.219734 0V4.44L15.2297 4.44Z" fill={primaryColor}/>
                        <path d="M3.21973 0H0.219727V4.44H3.21973V0Z" fill={secondaryColor}/>
                        <path d="M15.2297 17.9498V13.5098L0.219738 13.5098V17.9498H15.2297Z" fill={primaryColor}/>
                        <path d="M3.21973 13.52H0.219727V17.96H3.21973V13.52Z" fill={secondaryColor}/>
                        <path d="M18.2297 11.2798V6.83984L3.21974 6.83984V11.2798H18.2297Z" fill={primaryColor}/>
                        <path d="M15.2197 11.2798H18.2197V6.83978H15.2197V11.2798Z" fill={secondaryColor}/>
                    </svg>
                </SVGICON>
            );
        case 'balances':
            return (
                <SVGICON>
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.446655 9.16002V6.48002L18.4467 -0.65332V9.16002H0.446655Z" fill={primaryColor}/>
                        <path d="M0.446655 6.42676V23.2268H24.4467V6.42676H0.446655ZM12.7133 17.4534L10.0466 14.7868L12.7133 12.1201L15.38 14.7868L12.7133 17.4534Z" fill={secondaryColor}/>
                        <path d="M12.7132 9.16017C11.5825 9.16281 10.4779 9.50052 9.53904 10.1306C8.60014 10.7608 7.86906 11.655 7.43817 12.7004C7.00728 13.7459 6.89593 14.8955 7.11814 16.0042C7.34035 17.1129 7.88618 18.1309 8.68666 18.9295C9.48715 19.7281 10.5064 20.2716 11.6156 20.4912C12.7248 20.7108 13.8742 20.5968 14.9186 20.1635C15.963 19.7301 16.8556 18.997 17.4835 18.0566C18.1114 17.1163 18.4466 16.0109 18.4466 14.8802C18.4466 14.1279 18.2982 13.383 18.0099 12.6881C17.7216 11.9933 17.2991 11.3621 16.7665 10.8308C16.234 10.2995 15.6018 9.87841 14.9063 9.59174C14.2108 9.30507 13.4655 9.15842 12.7132 9.16017ZM12.7132 17.5068L10.0466 14.8402L12.7132 12.1735L15.3799 14.8402L12.7132 17.5068Z" fill={primaryColor}/>
                    </svg>
                </SVGICON>
            )
        default:
            return null;
    }
};
