import {
    useAppDispatch,
} from '../providers/ReduxProvider/app/hooks';
import { changeTheme, toggleChartRebuild } from '../providers/ReduxProvider/features/globalSettings/globalSettingsSlice';
import { isBrowser } from '../helpers/isBrowser'
import { useEffect, useState, useCallback } from 'react'

export const useDarkMode = () => {
    const [theme, setTheme] = useState(
        isBrowser() && window.localStorage.theme ? window.localStorage.theme : 'light',
    )

    const dispatch = useAppDispatch();

    const setNewTheme = useCallback(() => setTheme(theme === 'dark' ? 'light' : 'dark'), [theme]);

    const colorTheme = theme === 'dark' ? 'light' : 'dark'

    useEffect(() => {
        if (isBrowser()){
            dispatch(changeTheme(window.localStorage.theme ? window.localStorage.theme : 'light'))
            setTheme(window.localStorage.theme ? window.localStorage.theme : 'light')
        }
    }, [isBrowser()])

    useEffect(() => {
        const root = window.document.body

        root.classList.remove(colorTheme)
        root.classList.add(theme)

        if (isBrowser()) {
            dispatch(changeTheme(theme))
            dispatch(toggleChartRebuild())
            window.localStorage.setItem('theme', theme)
            window.localStorage.setItem('tradingview.current_theme.name', theme)
        }
    }, [theme])

    return [theme, setNewTheme]
}

export default useDarkMode
