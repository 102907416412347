export const ActiveStarIcon = (): JSX.Element => (
  <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.04894 0.927052C9.3483 0.00574112 10.6517 0.00573993 10.9511 0.927051L12.4697 5.60081C12.6035 6.01284 12.9875 6.2918 13.4207 6.2918H18.335C19.3037 6.2918 19.7065 7.53141 18.9228 8.10081L14.947 10.9894C14.5966 11.244 14.4499 11.6954 14.5838 12.1074L16.1024 16.7812C16.4017 17.7025 15.3472 18.4686 14.5635 17.8992L10.5878 15.0106C10.2373 14.756 9.7627 14.756 9.41221 15.0106L5.43648 17.8992C4.65276 18.4686 3.59828 17.7025 3.89763 16.7812L5.41623 12.1074C5.55011 11.6954 5.40345 11.244 5.05296 10.9894L1.07722 8.10081C0.293507 7.53141 0.696283 6.2918 1.66501 6.2918H6.57929C7.01252 6.2918 7.39647 6.01284 7.53035 5.60081L9.04894 0.927052Z"
      fill="currentColor"
    />
  </svg>
)

export const EmptyStarIcon = (): JSX.Element => (
  <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.049 1.92664C10.3483 1.00537 11.6517 1.00538 11.951 1.92664L13.4699 6.60055C13.6038 7.01254 13.9877 7.29148 14.4209 7.29149L19.3354 7.29168C20.3041 7.29172 20.7068 8.53127 19.9232 9.10067L15.9474 11.9895C15.5969 12.2441 15.4503 12.6955 15.5841 13.1075L17.1026 17.7815C17.4019 18.7028 16.3475 19.4689 15.5638 18.8995L11.5878 16.011C11.2373 15.7564 10.7627 15.7564 10.4122 16.011L6.43622 18.8995C5.65252 19.4689 4.5981 18.7028 4.8974 17.7815L6.41589 13.1075C6.54974 12.6955 6.40309 12.2441 6.05263 11.9895L2.07683 9.10067C1.29317 8.53127 1.69592 7.29172 2.66461 7.29168L7.57911 7.29149C8.01231 7.29148 8.39623 7.01254 8.53011 6.60055L10.049 1.92664Z"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
)

export const EditIcon = (): JSX.Element => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.303 1.30292C14.2403 0.365661 15.7599 0.365661 16.6972 1.30292C17.6344 2.24018 17.6344 3.75977 16.6972 4.69703L15.7457 5.6485L12.3516 2.25439L13.303 1.30292Z"
      fill="currentColor"
    />
    <path d="M10.6545 3.95145L0.600098 14.0059V17.4H3.99421L14.0486 7.34556L10.6545 3.95145Z" fill="currentColor" />
  </svg>
)

export const KebabIcon = (): JSX.Element => (
  <svg width="20" height="6" viewBox="0 0 20 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.20039 2.99998C5.20039 4.32546 4.12587 5.39997 2.80039 5.39997C1.47491 5.39997 0.400391 4.32546 0.400391 2.99998C0.400391 1.67449 1.47491 0.599976 2.80039 0.599976C4.12587 0.599976 5.20039 1.67449 5.20039 2.99998Z"
      fill="currentColor"
    />
    <path
      d="M12.4004 2.99998C12.4004 4.32546 11.3259 5.39997 10.0004 5.39997C8.67491 5.39997 7.60039 4.32546 7.60039 2.99998C7.60039 1.67449 8.67491 0.599976 10.0004 0.599976C11.3259 0.599976 12.4004 1.67449 12.4004 2.99998Z"
      fill="currentColor"
    />
    <path
      d="M17.2004 5.39997C18.5259 5.39997 19.6004 4.32546 19.6004 2.99998C19.6004 1.67449 18.5259 0.599976 17.2004 0.599976C15.8749 0.599976 14.8004 1.67449 14.8004 2.99998C14.8004 4.32546 15.8749 5.39997 17.2004 5.39997Z"
      fill="currentColor"
    />
  </svg>
)
