import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../../../types/user';

export interface UserState {
    user: User;
    userLoading: boolean;
}

export const EMPTY_USER = {
    created_at: '',
    email: '',
    email_change_confirm_status: 0,
    id: '',
    last_sign_in_at: '',
    phone: '',
    role: '',
    updated_at: '',
}

const initialUserState: UserState = {
    user: EMPTY_USER,
    userLoading: false,
};

const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        setUser(state, action: PayloadAction<User>) {
            state.user = action.payload;
            state.userLoading = false;
        },
        getUser(state) {
            state.userLoading = true;
        },
        resetUserLoading(state) {
            state.userLoading = false;
        },
    },
});

export const { setUser, getUser, resetUserLoading } = userSlice.actions;
export const userReducer = userSlice.reducer;
export default userSlice.reducer;
