import {
    useAppDispatch,
} from '../providers/ReduxProvider/app/hooks';
import { changeCustomization } from '../providers/ReduxProvider/features/globalSettings/globalSettingsSlice';
import { isBrowser } from '../helpers/isBrowser'
import { useEffect, useState, useCallback } from 'react'

export const useCustomization = () => {
    const [customization, setCustomization] = useState(
        isBrowser() && window.localStorage.customization ? window.localStorage.customization : 'off',
    )

    const dispatch = useAppDispatch();

    const setNewCustomization = useCallback(() => setCustomization(customization === 'on' ? 'off' : 'on'), [customization]);

    useEffect(() => {
        if (isBrowser()){
            dispatch(changeCustomization(window.localStorage.connectcustomizationionType ? window.localStorage.customization : 'off'));
            setCustomization(window.localStorage.customization ? window.localStorage.customization : 'off');
        }
    }, [isBrowser()]);

    useEffect(() => {
        if (isBrowser()) {
            dispatch(changeCustomization(customization));
            window.localStorage.setItem('customization', customization);
        }
    }, [customization]);

    return [customization, setNewCustomization];
};
