import { FC } from 'react'

interface AlertIconProps {
  fill?: string
  stroke?: string
}

export const AlertIcon: FC<AlertIconProps> = ({ fill = 'FFFAE3', stroke = '#ECAA00' }) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
        fill={fill}
      />
      <path
        d="M20.0015 17V19M20.0015 23H20.0115M13.0733 27H26.9297C28.4693 27 29.4316 25.3333 28.6618 24L21.7336 12C20.9637 10.6667 19.0392 10.6667 18.2694 12L11.3412 24C10.5714 25.3333 11.5337 27 13.0733 27Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
