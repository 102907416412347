import { fetchCategories } from 'hooks'
import { NextApiRequest, NextApiResponse } from 'next'
import { convertCategoryFromStrapiCategory } from './convertStrapiTypes';

// 1 day
const refreshPeriod = 24 * 60 * 60 * 1000

export const extractCached = async (
  req: NextApiRequest,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  res: NextApiResponse,
) => {
  const cached = JSON.parse(req.cookies['cached'] || 'null')

  if (!cached || cached.expiredAt > Date.now()) {
    const { data: categoriesStrapi } = await fetchCategories({})
    const categories = categoriesStrapi?.map(convertCategoryFromStrapiCategory)

    const cookies = {
      expiredAt: Date.now() + refreshPeriod,
      data: {
        categories,
      },
    }

    // res.setHeader('set-cookie', [
    //     `cookies=${JSON.stringify(cookies ? cookies : '')}; path=/`,
    // ]);
    return cookies.data
  }
  return cached.data
}
