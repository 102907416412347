import React, { useState, useEffect } from 'react';
import { isBrowser } from '../../../helpers/isBrowser';
import classnames from 'classnames';

export interface BorderRadius {
    name: string;
    radius: number;
    type: string;
}

export const TailwindBorderRadiusClassNames: BorderRadius[] = [
    { name: 'rounded-full', radius: 9999, type: 'px' },
    { name: 'rounded', radius: 0.25, type: 'rem' },
    { name: 'rounded-md', radius: 0.375, type: 'rem' },
    { name: 'rounded-none', radius: 0, type: 'px' },
    { name: 'rounded-xl', radius: 0.75, type: 'rem' },
    { name: 'rounded-3xl', radius: 1.5, type: 'rem' },
    { name: 'rounded-t-lg', radius: 0.5, type: 'rem'},
    { name: 'rounded-l-md', radius: 0.375, type: 'rem'},
    { name: 'rounded-r-md', radius: 0.375, type: 'rem'},
    { name: 'rounded-l', radius: 0.25, type: 'rem'},
    { name: 'rounded-lg', radius: 0.5, type: 'rem'},
    { name: 'rounded-r', radius: 0.25, type: 'rem'},
    { name: 'rounded-b-lg', radius: 0.5, type: 'rem'},
];

interface CornersProps {
    globalCoefficient: string;
    setGlobalCoefficient: (value: string) => void;
}

export const Corners: React.FC<CornersProps> = ({
    globalCoefficient,
    setGlobalCoefficient,
}: CornersProps) => {
    const MAX_COEFFICIENT = 25;
    const [isOpenAdvance, setIsOpenAdvance] = useState<boolean>(false);
    const [tailwindBorderRadius, setTailwindBorderRadius] = useState<BorderRadius[]>(TailwindBorderRadiusClassNames);

    const advanceBlockClassName = classnames('duration-200 mt-2', {
        'h-0 overflow-hidden': !isOpenAdvance,
        'h-[320px] overflow-hidden': isOpenAdvance
    });

    useEffect(() => {
        if (isBrowser() && document.readyState !== 'loading') {
            const rootElement = document.documentElement;

            const newTailwindBorderRadius = TailwindBorderRadiusClassNames.map((item, index) => {
                if (item.name !== 'rounded-none' && item.name !== 'rounded-full')
                  rootElement && rootElement.style.setProperty(
                      `--${item.name}`,
                      `${+item.radius * +globalCoefficient}${item.type}`,
                  )

                return {
                    ...item,
                    radius: item.name !== 'rounded-none' && item.name !== 'rounded-full' ? +item.radius * +globalCoefficient : item.radius,
                };
            });

            setTailwindBorderRadius(newTailwindBorderRadius)
        }
    }, [globalCoefficient, isBrowser(), document]);

    const renderAdvance = (item: any, index: number) => {
        return (
            <div className="pt-1 flex justify-between">
                <span>{item.name}</span>
                <span>{item.name === 'rounded-full' ? item.radius : Math.round(item.radius * +globalCoefficient * 1000) / 1000} {item.type}</span>
            </div>
        );
    };

    const validateMaxValue = () => {
        if (+globalCoefficient > MAX_COEFFICIENT) {
            setGlobalCoefficient(MAX_COEFFICIENT.toString());
        }
    };

    return (
        <div className="">
            <div className="px-6 py-3">
                <h3 className="text-customization-primary-text-color text-lg">
                    Border radius
                </h3>
                <p className="text-customization-contrast-text-color text-sm">
                    Control the border radius of the interface by changing
                    global coefficient.
                </p>
            </div>

            <div className="px-6 pt-1.5 pb-3">
                <label htmlFor="globalBorderRadius" className="pb-1.5 text-xs text-customization-contrast-text-color">
                    Global border radius
                </label>
                <input
                    id="globalBorderRadius"
                    value={globalCoefficient}
                    onChange={(e: any) => setGlobalCoefficient(e.target.value)}
                    type="text"
                    className="focus:ring-primary-cta-color-60 focus:outline-none text-customization-primary-text-color placeholder:text-customization-contrast-text-color bg-white focus:border-primary-cta-color-60 block w-full pl-4 py-2 sm:text-sm border border-gray-200 rounded-md"
                    onBlur={() => validateMaxValue()}
                />
            </div>

            <div className="px-6 pt-1.5 pb-3">
                <input
                    onChange={(e: any) => setGlobalCoefficient(e.target.value)}
                    value={globalCoefficient || 0}
                    type="range"
                    min="0"
                    max={MAX_COEFFICIENT}
                    step="0.01"
                    className="styled-slider slider-progress py-3"
                    aria-label="Set global coefficient for border radius"
                />
            </div>

            <div className="px-6 pt-1.5 pb-3 text-customization-contrast-text-color text-sm">
                <div className="flex w-full justify-between items-center" onClick={() => setIsOpenAdvance(!isOpenAdvance)}>
                    <span>Advance</span>
                    <div className="w-40 relative">
                        <span className="before:block before:border-t before:w-full before:h-px before:absolute before:top-2/4 before:border-customization-theme" />
                    </div>
                </div>
                <div className={advanceBlockClassName}>
                    {tailwindBorderRadius.map(renderAdvance)}
                </div>
            </div>
        </div>
    );
};

export default Corners;
