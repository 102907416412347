import classnames from 'classnames';
import React, { useState } from 'react';

export type Align = 'center' | 'right' | 'left';
export type VerticalAlign = 'top' | 'bot';
export interface TooltipProps {
    showArrow?: boolean;
    align?: Align;
    textWrapperClassname?: string;
    backgroundClassname?: string;
    boxWrapperClassname?: string;
    tooltipClassname?: string;
    wrapperClassname?: string;
    verticalAlign?: VerticalAlign;
    icon?: JSX.Element;
    customArrow?: JSX.Element;
    content: JSX.Element;
    arrowColor?: string;
}

export const Tooltip: React.FC<TooltipProps> = (
    {
        showArrow = false,
        align = 'center',
        textWrapperClassname = 'text-text-color-70 shadow-lg px-3 py-2 rounded',
        backgroundClassname = 'bg-body-background-color',
        boxWrapperClassname = 'mb-[26px] z-20 w-96',
        tooltipClassname = '',
        wrapperClassname = 'flex flex-col',
        verticalAlign = 'top',
        icon = defaultIcon,
        customArrow,
        content,
        arrowColor = 'border-neutral-control-layer-color-50',
    }: TooltipProps
): JSX.Element => {
    const [showTooltip, setShowTooltip] = useState(false);

    const cxTextWrapper = classnames(
        backgroundClassname,
        textWrapperClassname
    );

    const boxWrapperClassnames = classnames(
        `absolute flex whitespace-no-wrap ${
            (align === 'center' && '-ml-[180px] justify-center') ||
            (align === 'right' && 'justify-start ml-px') ||
            (align === 'left' && '-ml-[374px] justify-end')
        }`,
        boxWrapperClassname
    );

    const cxTooltip = classnames(
        `flex ${
            verticalAlign === 'top'
                ? 'flex-col-reverse'
                : 'flex-col mt-2.5'
        } ${
            (align === 'right' && 'ml-2') ||
            (align === 'left' && '-ml-[2px]')
        }`,
        tooltipClassname
    );

    const arrow = (
        <span
            data-testid="arrow"
            className={`z-0 w-0 h-0 absolute
            border-l-[20px] border-l-transparent
            border-t-[20px] ${arrowColor}
            border-r-[20px] border-r-transparent  
            ${
                (align === 'right' &&
                    (verticalAlign === 'top'
                        ? '-ml-[9px] mb-7 -rotate-90'
                        : '-ml-[9px] mt-[2px] -rotate-90')) ||
                (align === 'left' &&
                    (verticalAlign === 'top'
                        ? '-ml-5 mb-7 rotate-90'
                        : '-ml-5 mt-[2px] rotate-90')) ||
                (verticalAlign === 'top'
                    ? '-ml-3 mb-5'
                    : '-ml-3 -mt-1.5 rotate-180')
            }`}
         />
    );

    return (
        <div
            data-testid="wrapper"
            className={wrapperClassname}
            onMouseOver={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
        >
            <div>{icon}</div>

            {showTooltip && (
                <div data-testid="tooltip-wrapper" className={cxTooltip}>
                    <div
                        data-testid="box-wrapper"
                        className={boxWrapperClassnames}
                    >
                        <div
                            data-testid="tooltip-content"
                            className={cxTextWrapper}
                        >
                            {content}
                        </div>
                    </div>
                    {showArrow
                        ? customArrow
                            ? customArrow
                            : arrow
                        : null}
                </div>
            )}
        </div>
    );
};

const defaultIcon = (
    <svg
        className="w-4 h-4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
    >
        <path
            fill="gray"
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
            clipRule="evenodd"
        />
    </svg>
);
