import classnames from 'classnames';
import * as React from 'react';
// import './Table.scss';

export type TableCellData = string | number | React.ReactNode | undefined;

export interface TableProps {
    /**
     * Additional styles class to the root element
     */
    classNames?: string;
    /**
     * styles class for divider (thead)
     */
    tableDividerColor?: string;
    /**
     * Additional styles class to the thead of table
     */
    tableHeaderClassNames?: string;
    /**
     * Style calsses for table header row
     */
    tableHeaderRowClassnames?: string;
    /**
     * Property which is used to render Table's header. Length of this array should be equal as data's item length
     */
    tableHeaderTitles?: React.ReactNode[];
    /**
     * Data which is used to render Table. All the elements of an array should have the same length
     */
    data: TableCellData[][];
    /**
     * Defines from what side row background starts `(left, right)`
     * @default 'left'
     */
    side?: 'left' | 'right';
    /**
     * Defines whether row background shows or not, and calculates width of it
     */
    rowBackground?: (row: number) => React.CSSProperties;
    /**
     * Sets row background color
     */
    rowBackgroundColor?: string;
    /**
     * Property which is used to render table with stripes background
     * @default 'false'
     */
    isStripedTableEnabled?: boolean;
    stripedRowClassName?: string;
    defaultRowClassName?: string;
    /**
     * Value for setting table's header height. This field is required to be set for orderbook component
     * @default 'undefined'
     */
    headerHeight?: number;
    rowHeight?: number;
    /**
     * Value for specifing table's header rows paddings
     * @default 'undefined'
     */
    paddingHeaderClass?: string;
    /**
     * Value for specifing table's rows paddings
     * @default 'undefined'
     */
    paddingTableClass?: string;
    /**
     * Value for specifing table's last element class
     * @default 'undefined'
     */
    lastElementClass?: string;
    /**
     * No data message for table
     */
    noDataMessage?: string | React.ReactNode;
    /**
     * Property which is used to render table with sticky header
     * @default 'false'
     */
    isHeaderSticky?: boolean;
    /**
     * Callback called when a cell is selected
     */
    onCellClick?: (
        cell: any,
        index: number,
        previousCell?: any,
        nextCell?: any
    ) => void;
    isLarge?: boolean;
    onlyHeader?: boolean;
    headerColumnClassNames?: string[];
    onRowClick?: (row: TableCellData[], rowIndex: number) => void;
    tableHeaderCellClassNames?: string;
    handleClickDependency?: any[];
}

/**
 * Table component
 */
export const Table = ({
    classNames = '',
    tableHeaderClassNames = 'bg-gray-50',
    tableHeaderTitles = [],
    tableHeaderCellClassNames = 'px-6 uppercase tracking-wider',
    tableHeaderRowClassnames = 'border-b border-divider-color-20',
    data = [],
    rowBackground,
    rowBackgroundColor,
    isStripedTableEnabled,
    stripedRowClassName = 'bg-gray-50',
    defaultRowClassName = 'bg-white',
    side = 'left',
    headerHeight,
    paddingHeaderClass = '',
    paddingTableClass = '',
    noDataMessage = 'There is no data to show',
    isHeaderSticky = false,
    onCellClick,
    tableDividerColor = 'border-color-200',
    lastElementClass,
    isLarge,
    onlyHeader,
    headerColumnClassNames,
    onRowClick,
    rowHeight,
    handleClickDependency,
}: TableProps) => {
    // root component className with classNames prop
    const rootClassName = classnames(
        'flex',
        'flex-col',
        'fd-table',
        classNames
    );

    // Define whether the table starts its rows from the bottom
    const isTableRowsBottom = classNames?.includes('fd-table_rows-bottom');

    // Callback function to handle cell click event
    const handleCellClick = React.useCallback(
        (
            cell: TableCellData,
            index: number,
            previousCell?: TableCellData,
            nextCell?: TableCellData
        ) => {
            onCellClick?.(cell, index, previousCell, nextCell);
        },
        [handleClickDependency]
    );

    // method to render cells of table's header
    const renderHeaderCells = React.useMemo(() => {
        return tableHeaderTitles?.map((tableHeaderTitle, titleItemIndex) => {
            const classIfLastEl =
                titleItemIndex === tableHeaderTitles.length - 1
                    ? lastElementClass || 'flex justify-end'
                    : '';

            const cnHeaderCell = classnames(
                tableHeaderCellClassNames,
                paddingHeaderClass,
                classIfLastEl,
                headerColumnClassNames && headerColumnClassNames[titleItemIndex]
            );

            const style = {
                ...(headerHeight && { height: headerHeight }),
            };

            return (
                <th
                    style={style}
                    key={titleItemIndex}
                    scope="col"
                    className={cnHeaderCell}
                >
                    {tableHeaderTitle ? tableHeaderTitle : <>&nbsp;</>}
                </th>
            );
        });
    }, [tableHeaderTitles, paddingHeaderClass, lastElementClass]);

    // method to render table's header if tableHeaderTitles is defined and it's length more than 0
    const renderTableHeadNode = React.useMemo(() => {
        if (tableHeaderTitles?.length) {
            const style = {
                ...(headerHeight && { height: headerHeight }),
            };

            return (
                <thead
                    style={style}
                    className={classnames(tableHeaderClassNames, {
                        'sticky top-0': isHeaderSticky,
                    })}
                >
                    <tr className={tableHeaderRowClassnames}>
                        {renderHeaderCells}
                    </tr>
                </thead>
            );
        }

        return null;
    }, [tableHeaderTitles, renderHeaderCells]);

    // method to return the row's cells
    const renderTableRowCells = React.useCallback(
        (rowCells: TableCellData[]) => {
            const style = {
                ...((headerHeight && { height: headerHeight }) ||
                    (rowHeight && { height: rowHeight })),
            };

            return rowCells.map(
                (
                    cell: TableCellData,
                    cellIndex: number,
                    cellsInRow: TableCellData[]
                ) => {
                    const firstElement = cellIndex === 0;
                    const lastElement = cellIndex === rowCells.length - 1;
                    const classIfLastEl = lastElement
                        ? lastElementClass || 'flex justify-end'
                        : '';

                    const cnCell = classnames(
                        'px-6 whitespace-nowrap',
                        paddingTableClass,
                        classIfLastEl
                    );

                    return (
                        <td
                            className={cnCell}
                            key={cellIndex}
                            style={style}
                            onClick={() =>
                                handleCellClick(
                                    cell,
                                    cellIndex,
                                    firstElement
                                        ? ''
                                        : cellsInRow[cellIndex - 1],
                                    lastElement ? '' : cellsInRow[cellIndex + 1]
                                )
                            }
                        >
                            {cell}
                        </td>
                    );
                }
            );
        },
        [
            paddingTableClass,
            headerHeight,
            handleCellClick,
            lastElementClass,
            rowHeight,
            handleClickDependency,
        ]
    );

    // method to return the body's rows
    const renderTableBodyRows = React.useMemo(() => {
        return data.map((tableRow: TableCellData[], tableRowIndex) => {
            const rowClassNames = classnames(
                isStripedTableEnabled &&
                    tableRowIndex % 2 === 0 &&
                    stripedRowClassName,
                isStripedTableEnabled &&
                    tableRowIndex % 2 === 1 &&
                    defaultRowClassName,
                !isStripedTableEnabled && defaultRowClassName
            );

            return (
                <tr
                    key={tableRowIndex}
                    className={rowClassNames}
                    onClick={() => onRowClick?.(tableRow, tableRowIndex)}
                >
                    {renderTableRowCells(tableRow)}
                </tr>
            );
        });
    }, [data, isStripedTableEnabled, tableHeaderTitles, handleClickDependency]);

    // method to render table's body
    const renderTableBodyNode = React.useMemo(() => {
        const style = {
            ...(isTableRowsBottom &&
                headerHeight && { height: `calc(100% - ${headerHeight}px)` }),
            border: 'none',
        };

        return <tbody style={style}>{renderTableBodyRows}</tbody>;
    }, [
        renderTableBodyRows,
        isTableRowsBottom,
        headerHeight,
        handleClickDependency,
    ]);

    // method to return background row
    const renderBackgroundRowItem = React.useCallback(
        (rowIndex: number) => {
            const style = {
                ...rowBackground?.(rowIndex),
                backgroundColor: rowBackgroundColor,
            };

            return (
                <span
                    key={rowIndex}
                    style={style}
                    className={classnames(
                        'fd-table-background__row',
                        paddingTableClass
                    )}
                >
                    &nbsp;
                </span>
            );
        },
        [rowBackground, rowBackgroundColor, paddingTableClass]
    );

    // method to return memorized rows using renderBackgroundRowItem method
    const renderBackgroundRows = React.useMemo(() => {
        return data.map((row: TableCellData[], rowIndex: number) =>
            renderBackgroundRowItem(rowIndex)
        );
    }, [data]);

    // method to render table background rows
    const renderTableBackground = React.useMemo(() => {
        if (rowBackground) {
            const rowBackgroudClassName = classnames('fd-table-background', {
                'fd-table-background--left': side === 'left',
                'fd-table-background--right': side === 'right',
            });

            const style = {
                ...(headerHeight &&
                    !isTableRowsBottom &&
                    !isLarge && { top: headerHeight }),
                ...(isTableRowsBottom && !isLarge && { bottom: 0 }),
                ...(isLarge && { bottom: 0 }),
            };

            return (
                <div className={rowBackgroudClassName} style={style}>
                    {renderBackgroundRows}
                </div>
            );
        }
        return null;
    }, [
        rowBackground,
        renderBackgroundRows,
        isTableRowsBottom,
        data,
        side,
        classNames,
        isLarge,
    ]);

    if (onlyHeader) {
        return (
            <div className={rootClassName}>
                <table
                    className={classnames(
                        'min-w-full divide-y border-collapse',
                        tableDividerColor
                    )}
                >
                    {renderTableHeadNode}
                </table>
            </div>
        );
    }

    return (
        <div className={rootClassName}>
            <table
                className={classnames(
                    'min-w-full divide-y border-collapse',
                    tableDividerColor
                )}
            >
                {renderTableHeadNode}
                {renderTableBodyNode}
            </table>
            {!data.length && (
                <div className="my-8 text-neutral-control-layer-color-50 w-full">
                    {noDataMessage}
                </div>
            )}
            {renderTableBackground}
        </div>
    );
};
