import { useEffect, useMemo, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { SOCKET_URL } from '../../configs/socketConfig';
import { useAppSelector } from '../../providers/ReduxProvider/app/hooks';

export const useCustomWebSocket = (app: string, url: any, options?: any, connect?: any) => {
    const [socketURL, setSocketURL] = useState<string | null>(null);

    const user = useAppSelector((state) => state.user.user);
    const userLoading = useAppSelector((state) => state.user.userLoading);
    const connectionType = useAppSelector((state) => state.globalSettings.connectionType);

    const userLoggedIn = useMemo(() => {
        return Boolean(user?.id);
    }, [user?.id]);

    // reconnect if user login/logout
    useEffect(() => {
        if (!userLoading) {
            setSocketURL(null)
        }
    }, [userLoggedIn, userLoading])

    useEffect(() => {
        if (socketURL === null && !userLoading) {
            setSocketURL(SOCKET_URL)
        }
    }, [socketURL, userLoading])

    const {
        sendMessage,
        sendJsonMessage,
        lastMessage,
        lastJsonMessage,
        readyState,
        getWebSocket
    // Temporary disable websocket connection
    // } = useWebSocket(connectionType === 'gRPC' ? null : socketURL, options);
    } = useWebSocket(null, options);

    // For debugging
    // useEffect(() => {
    //     switch (readyState) {
    //         case ReadyState.CLOSED:
    //             window.console.log(app, ': closed');
    //             break;
    //         case ReadyState.CLOSING:
    //             window.console.log(app, ': closing');
    //             break;
    //         case ReadyState.CONNECTING:
    //             window.console.log(app, ': connecting');
    //             break;
    //         case ReadyState.OPEN:
    //             window.console.log(app, ': opened');
    //             break;
    //     }
    // }, [readyState]);

    return {
        socketURL,
        sendMessage,
        sendJsonMessage,
        lastMessage,
        lastJsonMessage,
        readyState,
        getWebSocket,
    };
};