import classNames from 'classnames'
import Image from 'next/image'
import React from 'react'
import ReactCrop, { Crop } from 'react-image-crop'
import { useSetMobileDevice } from 'web-sdk'

const MAX_COVER_WIDTH = 1920
const MAX_COVER_HEIGHT = 1080
const MAX_COVER_SIZE = 819200

interface ImageCropProps {
  coverURL: string
  setCoverURL: any
  setCropOpen: any
  setCropApplied: any
  setCoverFile: any
  setCoverWidth: any
  setCoverHeight: any
  setCoverSize: any
  setIsCoverValid: any
  coverWidth: any
  coverHeight: any
  coverSize: any
  defaultCoverSize: any
  coverFile: any
}

export const ImageCrop: React.FC<ImageCropProps> = ({
  coverURL,
  setCoverURL,
  setCropOpen,
  setCropApplied,
  setCoverFile,
  setCoverWidth,
  setCoverHeight,
  setCoverSize,
  setIsCoverValid,
  coverWidth,
  coverHeight,
  coverSize,
  defaultCoverSize,
  coverFile,
}: ImageCropProps) => {
  const isMobile = useSetMobileDevice(false, 767)
  const [crop, setCrop] = React.useState<Crop>({
    unit: '%',
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  })
  const [completedCrop, setCompletedCrop] = React.useState<Crop>()
  const [isPreview, setIsPreview] = React.useState<boolean>(false)
  const imgRef = React.useRef<HTMLImageElement>(null)
  const canvasRef = React.useRef<HTMLCanvasElement>(null)
  const modalRef = React.useRef<HTMLDivElement>(null)

  const renderCanvas = () => {
    const imgWidth = imgRef.current?.width || 0
    const imgHeight = imgRef.current?.height || 0

    if (imgRef.current && canvasRef.current && modalRef.current)
      canvasPreview(
        imgRef.current,
        canvasRef.current,
        modalRef.current,
        completedCrop
          ? completedCrop
          : {
              unit: 'px',
              x: imgWidth * 0.25,
              y: imgHeight * 0.25,
              width: imgWidth * 0.5,
              height: imgHeight * 0.5,
            },
      )
  }

  const onApply = async () => {
    renderCanvas()
    if (canvasRef.current && imgRef.current) {
      const cover = coverURL.split('/').slice(-1)[0]
      const blob = await toBlob(canvasRef.current, coverFile ? coverFile.type : 'image/' + cover.split('.')[1])
      const file = new File([blob], coverFile ? coverFile.name : cover.split('.')[0], {
        type: blob.type,
      })

      setCoverURL(URL.createObjectURL(file))
      setCoverFile(file)
      setCropApplied(true)
      setCropOpen(false)

      const CoverValid = coverWidth <= MAX_COVER_WIDTH && coverHeight <= MAX_COVER_HEIGHT && coverSize <= MAX_COVER_SIZE

      setIsCoverValid(CoverValid)
    }
  }

  const onClose = () => {
    setIsPreview(false)
    setCropOpen(false)
  }

  const onPreview = () => {
    renderCanvas()
    setIsPreview(!isPreview)
  }

  const canvasPreview = async (
    image: HTMLImageElement,
    canvas: HTMLCanvasElement,
    modal: HTMLDivElement,
    crop: Crop,
  ) => {
    const ctx = canvas.getContext('2d')

    if (!ctx) {
      throw new Error('No 2d context')
    }

    const scaleX = image.naturalWidth / modal.offsetWidth
    const scaleY = image.naturalHeight / modal.offsetHeight
    const pixelRatio = window.devicePixelRatio

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

    ctx.scale(pixelRatio, pixelRatio)
    ctx.imageSmoothingQuality = 'high'

    const cropX = crop.x * scaleX
    const cropY = crop.y * scaleY

    const centerX = image.naturalWidth / 2
    const centerY = image.naturalHeight / 2

    ctx.save()

    ctx.translate(-cropX, -cropY)
    ctx.translate(centerX, centerY)
    ctx.translate(-centerX, -centerY)
    ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight)

    ctx.restore()

    setCoverHeight((Math.floor(crop.height) * image.naturalHeight) / modal.offsetHeight)
    setCoverWidth((Math.floor(crop.width) * image.naturalWidth) / modal.offsetWidth)
    const changeSize =
      (image.naturalWidth * image.naturalHeight) /
      (((crop.height * image.naturalHeight) / modal.offsetHeight) *
        ((crop.width * image.naturalWidth) / modal.offsetWidth))

    setCoverSize(defaultCoverSize.size / changeSize)
  }

  function toBlob(canvas: HTMLCanvasElement, imageType?: string): Promise<Blob> {
    return new Promise(resolve => {
      canvas.toBlob(
        blob => {
          if (blob) resolve(blob)
        },
        imageType?.includes('webp') ? 'image/webp' : 'image/jpeg',
        0.75,
      )
    })
  }

  const cropStyles = classNames({
    'min-w-[100vw]': isMobile,
  })

  const modalStyles = classNames('flex items-center justify-center min-h-screen pt-4 pb-20 text-center', {
    'px-0': isMobile,
    'px-4': !isMobile,
  })

  return (
    <div className="fixed z-40 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className={modalStyles}>
        <div
          className="fixed inset-0 transition-opacity backdrop-blur-sm bg-black/75"
          aria-hidden="true"
          onClick={onClose}
        />
        <div className="flex flex-col justify-center items-center p-5 overflow-hidden transform sm:my-8 align-middle min-h-full sm:w-full lg:w-[75%] bg-transparent">
          <div className="flex flex-auto" ref={modalRef}>
            <div
              hidden={!isPreview}
              style={{
                width: modalRef.current?.offsetWidth,
                height: modalRef.current?.offsetHeight,
              }}
              className="relative"
            >
              <canvas
                ref={canvasRef}
                style={{
                  objectFit: 'contain',
                  width: coverWidth,
                  height: coverHeight,
                }}
                className="m-auto absolute inset-0 max-h-[100%] max-w-[100%]"
              />
            </div>
            <div hidden={isPreview} className={cropStyles}>
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={c => setCompletedCrop(c)}
                aspect={16 / 9}
              >
                {/* <img ref={imgRef} alt="preview image" src={coverURL || ''} crossOrigin="anonymous" /> */}
                <Image
                  src={coverURL || ''}
                  alt="preview image"
                  layout="fill"
                  crossOrigin="anonymous"
                />
              </ReactCrop>
            </div>
          </div>
          <div className="flex flex-row justify-between items-center text-white bg-black/25 backdrop-blur-sm transition-opacity rounded w-full sm:w-full lg:w-1/2 h-12 mt-4">
            <div className="flex w-1/3 items-center h-full hover:bg-black/30 hover:cursor-pointer" onClick={onClose}>
              <span className="flex-auto border-r-2 border-white">Close</span>
            </div>
            <div className="flex w-1/3 items-center h-full hover:bg-black/30 hover:cursor-pointer" onClick={onPreview}>
              <span className="flex-auto">Preview</span>
            </div>
            <div className="flex w-1/3 items-center h-full hover:bg-black/30 hover:cursor-pointer">
              <span className="flex-auto border-l-2 border-white" onClick={onApply}>
                Apply
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
