import { AxiosResponse } from 'axios'
import { JsonBody, makeRequest } from './requestBuilder'

export type RequestBody = JsonBody | FormData

export type RequestMethod = (url: string, body?: RequestBody, headers?: {}) => Promise<AxiosResponse['data']>

export interface ApiWrapper {
  get: RequestMethod
  post: RequestMethod
  patch: RequestMethod
  put: RequestMethod
  delete: RequestMethod
}

export const API: ApiWrapper = {
  get: (url: string, body?: JsonBody) =>
    makeRequest({
      method: 'get',
      body,
      url,
    }),

  post: (url: string, body?: JsonBody, headers?: {}) =>
    makeRequest({
      method: 'post',
      headers,
      body,
      url,
    }),

  patch: (url: string, body?: JsonBody) =>
    makeRequest({
      method: 'patch',
      body,
      url,
    }),

  put: (url: string, body?: JsonBody) =>
    makeRequest({
      method: 'put',
      body,
      url,
    }),

  delete: async (url: string, body?: JsonBody) =>
    makeRequest({
      method: 'delete',
      body,
      url,
    }),
}
