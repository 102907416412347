import { useTranslation } from 'next-i18next'
import { Category } from 'types'

export const getHeaderNavigation = (categories: Category[]) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation('common')

  if (categories == null) {
    return {
      navigations: [],
    }
  }
  const headerNavigation = [
    { name: t('page.tab.header.all'), href: '/' },
    ...categories.map(category => {
      return {
        name: category.name,
        href: `/${category.category_id}`,
      }
    }),
  ]

  return {
    navigations: headerNavigation,
  }
}
