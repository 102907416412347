import { SidebarIcons } from 'assets/images/SidebarIcons'
import * as tw from 'tailwind.config'
const textColors = tw.theme.extend.textColor

const navigationElements = {
  home: {
    name: 'Home',
    path: '/',
    defaultIcon: <SidebarIcons name="home" primaryColor={textColors['neutral-control-layer-color-40']} />,
    activeIcon: <SidebarIcons name="home" primaryColor={textColors['navbar-control-layer-color-60']} />,
  },
  assets: {
    name: 'Assets',
    path: '/assets',
    defaultIcon: <SidebarIcons name="assets" primaryColor={textColors['neutral-control-layer-color-40']} />,
    activeIcon: <SidebarIcons name="assets" primaryColor={textColors['navbar-control-layer-color-60']} />,
  },
  news: {
    name: 'News',
    path: '/news',
    defaultIcon: <SidebarIcons name="news" primaryColor={textColors['neutral-control-layer-color-40']} />,
    activeIcon: <SidebarIcons name="news" primaryColor={textColors['navbar-control-layer-color-60']} />,
  },
  articles: {
    name: 'Articles',
    path: '/articles',
    defaultIcon: <SidebarIcons name="articles" primaryColor={textColors['neutral-control-layer-color-40']} />,
    activeIcon: <SidebarIcons name="articles" primaryColor={textColors['navbar-control-layer-color-60']} />,
  },
  trade: {
    name: 'Trade',
    path: 'https://terminal.yellow.com',
    activeIcon: <SidebarIcons name="trading" primaryColor={textColors['neutral-control-layer-color-40']} />,
    defaultIcon: <SidebarIcons name="trading" primaryColor={textColors['neutral-control-layer-color-40']} />,
    newTab: true
  },
  settings: {
    name: 'Settings',
    path: '/settings',
    activeIcon: <SidebarIcons name="settings" primaryColor={textColors['navbar-control-layer-color-60']} />,
    defaultIcon: <SidebarIcons name="settings" primaryColor={textColors['neutral-control-layer-color-40']} />,
    bottom: true,
  },
  wallet: {
    name: 'Wallet',
    path: 'https://wallet.yellow.com',
    activeIcon: <SidebarIcons name="wallet" primaryColor={textColors['neutral-control-layer-color-40']} />,
    defaultIcon: <SidebarIcons name="wallet" primaryColor={textColors['neutral-control-layer-color-40']} />,
    newTab: true
  },
}

export const navigationLoggedin = [navigationElements['trade'], navigationElements['wallet']]

export const navigation = [navigationElements['trade'], navigationElements['wallet'], navigationElements['settings']]

export const navigationMobile = [navigationElements['home'], navigationElements['news'], navigationElements['assets']]

export const navigationYellow = [
  navigationElements['home'],
  navigationElements['news'],
  navigationElements['assets'],
  navigationElements['articles'],
]

export const navigationAuthorizedYellow = [
  navigationElements['home'],
  navigationElements['news'],
  navigationElements['assets'],
  navigationElements['articles'],
]
