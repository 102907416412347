import classnames from 'classnames';
import * as React from 'react';

interface NavTab {
    name: string;
    isCurrentTab: boolean;
    label?: string;
}

export interface NavTabProps {
    tabs: NavTab[];
    onClick: (route: string) => void;
    rootClassName?: string;
    desktopTabsClassName?: string;
    mobileTabsClassName?: string;
    activeTabClassName?: string;
    defaultTabClassName?: string;
    shouldRenderDropdownMobile?: boolean;
    tabsContainerClassName?: string;
}

export const NavTabs: React.FC<NavTabProps> = ({
    tabs = [],
    onClick = (_route: string) => { return; },
    rootClassName = '',
    desktopTabsClassName,
    mobileTabsClassName = 'block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md',
    activeTabClassName = 'bg-indigo-100 text-indigo-700',
    defaultTabClassName = 'text-gray-500 hover:text-gray-700',
    shouldRenderDropdownMobile = true,
    tabsContainerClassName = 'flex space-x-4',
}: NavTabProps) => {
    const handleTabOnClick = React.useCallback((route: string) => {
        onClick(route);
    }, []);

    const desktopClassName = React.useCallback((tab: NavTab) => {
        if (desktopTabsClassName) {
            return `${desktopTabsClassName} ${tab.isCurrentTab ? activeTabClassName : defaultTabClassName}`;
        }

        return `px-3 py-2 font-medium text-sm rounded-md ${tab.isCurrentTab ? activeTabClassName : defaultTabClassName}`;
    }, [desktopTabsClassName, activeTabClassName, defaultTabClassName]);

    const cxNavTabs = React.useMemo(() => {
        return classnames({
            'hidden sm:block': shouldRenderDropdownMobile,
        });
    }, [shouldRenderDropdownMobile]);

    return (
        <div className={rootClassName}>
            {shouldRenderDropdownMobile && (
                <div className="sm:hidden">
                    <select
                        id="tabs"
                        name="tabs"
                        className={mobileTabsClassName}
                        defaultValue={tabs.find((tab: NavTab) => tab.isCurrentTab)?.name}
                        onChange={e => handleTabOnClick(e.target?.value.toLowerCase())}
                    >
                        {tabs.map((tab: NavTab) => (
                            <option key={tab.name}>{tab.label}</option>
                        ))}
                    </select>
                </div>
            )}
            <div className={cxNavTabs}>
                <nav className={tabsContainerClassName} aria-label="Tabs">
                    {tabs.map((tab: NavTab) => (
                        <button
                            key={tab.name}
                            className={desktopClassName(tab)}
                            onClick={() => !tab.isCurrentTab && handleTabOnClick(tab.name.toLowerCase())}
                        >
                            {tab.label || tab.name}
                        </button>
                    ))}
                </nav>
            </div>
        </div>
    );
};
