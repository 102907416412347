import { FeaturedStar } from 'assets/images/FeaturedStar'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import React from 'react'

interface FeaturedBadgeProps {
  additionalClassName?: string
  badgeColor?: string
  starColor?: string
}

export const FeaturedBadge: React.FC<FeaturedBadgeProps> = ({
  additionalClassName,
  badgeColor = 'bg-primary-cta-color-20 text-primary-cta-layer-color-60',
  starColor = 'text-primary-cta-color-90',
}: FeaturedBadgeProps) => {
  const { t } = useTranslation('common')

  return (
    <span
      className={classNames(
        'pl-[6px] pr-[10px] py-px w-auto flex items-center text-sm font-metro-semibold rounded w-fit whitespace-nowrap',
        additionalClassName,
        badgeColor,
      )}
    >
      <FeaturedStar className={`mr-1 ${starColor}`} />
      {t('page.asset.badge.featured.article')}
    </span>
  )
}
