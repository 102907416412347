export interface ThemeColorTitleInterface {
    key: string
    title: string
    tailwindKey: string
}

export const AVAILABLE_COLOR_TITLES: ThemeColorTitleInterface[] = [
    { key: 'app-main-background-color', title: 'Background', tailwindKey: 'bg-main-background-color' },
    { key: 'app-navbar-color', title: 'Sidebar', tailwindKey: 'bg-navbar-color' },
    { key: 'app-text-color', title: 'Text', tailwindKey: 'bg-text-color' },
    { key: 'app-primary-cta-color', title: 'Primary CTA', tailwindKey: 'bg-primary-cta-color' },
    { key: 'app-secondary-cta-color', title: 'Secondary CTA', tailwindKey: 'bg-secondary-cta-color' },
]

export const BACKGROUND_COLOR_GROUP = [
    { key: 'main-background-color', weight: 0, type: 'main' },
    { key: 'subheader-background-color', weight: 0, type: 'main' },
    { key: 'body-background-color', weight: 0, type: 'main' },
    { key: 'input-background-color', weight: 0, type: 'main' },
    { key: 'dropdown-background-color', weight: 0, type: 'main' },
    { key: 'shadow-color', weight: 0, type: 'main' },
    { key: 'navbar-background-color', weight: 0, type: 'main' },
    { key: 'footer-background-color', weight: 0, type: 'main' },
]

export const SIDEBAR_COLOR_GROUP = {
    layerLight: [
        { key: 'navbar-control-layer-color-10', weight: 75, type: 'tint' },
        { key: 'navbar-control-layer-color-20', weight: 45, type: 'tint' },
        { key: 'navbar-control-layer-color-40', weight: 15, type: 'tint' },
        { key: 'navbar-control-layer-color-60', weight: 0, type: 'main' },
        { key: 'navbar-control-layer-color-80', weight: 25, type: 'shade' },
        { key: 'navbar-control-layer-color-90', weight: 50, type: 'shade' },
    ],
    layerDark: [
        { key: 'navbar-control-layer-color-10', weight: 30, type: 'tint' },
        { key: 'navbar-control-layer-color-20', weight: 45, type: 'tint' },
        { key: 'navbar-control-layer-color-40', weight: 60, type: 'tint' },
        { key: 'navbar-control-layer-color-60', weight: 75, type: 'tint' },
        { key: 'navbar-control-layer-color-80', weight: 90, type: 'tint' },
        { key: 'navbar-control-layer-color-90', weight: 100, type: 'tint' },
    ],
    backgroundLight: [
        { key: 'navbar-control-bg-color-10', weight: 85, type: 'tint' },
        { key: 'navbar-control-bg-color-20', weight: 70, type: 'tint' },
        { key: 'navbar-control-bg-color-40', weight: 50, type: 'tint' },
        { key: 'navbar-control-bg-color-60', weight: 0, type: 'main' },
        { key: 'navbar-control-bg-color-80', weight: 30, type: 'shade' },
        { key: 'navbar-control-bg-color-90', weight: 50, type: 'shade' },
    ],
    backgroundDark: [
        { key: 'navbar-control-bg-color-10', weight: 65, type: 'shade' },
        { key: 'navbar-control-bg-color-20', weight: 30, type: 'shade' },
        { key: 'navbar-control-bg-color-40', weight: 0, type: 'main' },
        { key: 'navbar-control-bg-color-60', weight: 15, type: 'tint' },
        { key: 'navbar-control-bg-color-80', weight: 30, type: 'tint' },
        { key: 'navbar-control-bg-color-90', weight: 45, type: 'tint' },
    ],
}

export const TEXT_COLOR_GROUP = {
    textColorLight: [
        { key: 'text-color-0', weight: 100, type: 'tint' },
        { key: 'text-color-10', weight: 90, type: 'tint' },
        { key: 'text-color-20', weight: 70, type: 'tint' },
        { key: 'text-color-30', weight: 50, type: 'tint' },
        { key: 'text-color-40', weight: 35, type: 'tint' },
        { key: 'text-color-50', weight: 20, type: 'tint' },
        { key: 'text-color-60', weight: 0, type: 'secondary' },
        { key: 'text-color-70', weight: 20, type: 'shade' },
        { key: 'text-color-80', weight: 50, type: 'shade' },
        { key: 'text-color-90', weight: 70, type: 'shade' },
        { key: 'text-color-100', weight: 100, type: 'shade' },
    ],
    textColorDark: [
        { key: 'text-color-0', weight: 100, type: 'shade' },
        { key: 'text-color-10', weight: 75, type: 'shade' },
        { key: 'text-color-20', weight: 60, type: 'shade' },
        { key: 'text-color-30', weight: 45, type: 'shade' },
        { key: 'text-color-40', weight: 30, type: 'shade' },
        { key: 'text-color-50', weight: 15, type: 'shade' },
        { key: 'text-color-60', weight: 0, type: 'main' },
        { key: 'text-color-70', weight: 30, type: 'tint' },
        { key: 'text-color-80', weight: 60, type: 'tint' },
        { key: 'text-color-90', weight: 90, type: 'tint' },
        { key: 'text-color-100', weight: 100, type: 'tint' },
    ],
    neutralControlLight: [
        { key: 'neutral-control-color-0', weight: 100, type: 'tint' },
        { key: 'neutral-control-color-10', weight: 80, type: 'tint' },
        { key: 'neutral-control-color-20', weight: 70, type: 'tint' },
        { key: 'neutral-control-color-30', weight: 60, type: 'tint' },
        { key: 'neutral-control-color-40', weight: 45, type: 'tint' },
        { key: 'neutral-control-color-50', weight: 20, type: 'tint' },
        { key: 'neutral-control-color-60', weight: 0, type: 'main' },
        { key: 'neutral-control-color-70', weight: 5, type: 'shade' },
        { key: 'neutral-control-color-80', weight: 10, type: 'shade' },
        { key: 'neutral-control-color-90', weight: 20, type: 'shade' },
        { key: 'neutral-control-color-100', weight: 40, type: 'shade' },
    ],
    neutralControlDark: [
        { key: 'neutral-control-color-0', weight: 100, type: 'shade' },
        { key: 'neutral-control-color-10', weight: 55, type: 'shade' },
        { key: 'neutral-control-color-20', weight: 45, type: 'shade' },
        { key: 'neutral-control-color-30', weight: 35, type: 'shade' },
        { key: 'neutral-control-color-40', weight: 25, type: 'shade' },
        { key: 'neutral-control-color-50', weight: 15, type: 'shade' },
        { key: 'neutral-control-color-60', weight: 0, type: 'main' },
        { key: 'neutral-control-color-70', weight: 5, type: 'tint' },
        { key: 'neutral-control-color-80', weight: 10, type: 'tint' },
        { key: 'neutral-control-color-90', weight: 15, type: 'tint' },
        { key: 'neutral-control-color-100', weight: 20, type: 'tint' },
    ],
    neutralContolLayerLight: [
        { key: 'neutral-control-layer-color-0', weight: 100, type: 'tint' },
        { key: 'neutral-control-layer-color-10', weight: 80, type: 'tint' },
        { key: 'neutral-control-layer-color-20', weight: 65, type: 'tint' },
        { key: 'neutral-control-layer-color-30', weight: 55, type: 'tint' },
        { key: 'neutral-control-layer-color-40', weight: 40, type: 'tint' },
        { key: 'neutral-control-layer-color-50', weight: 25, type: 'tint' },
        { key: 'neutral-control-layer-color-60', weight: 0, type: 'main' },
        { key: 'neutral-control-layer-color-70', weight: 30, type: 'shade' },
        { key: 'neutral-control-layer-color-80', weight: 75, type: 'shade' },
        { key: 'neutral-control-layer-color-90', weight: 90, type: 'shade' },
        { key: 'neutral-control-layer-color-100', weight: 100, type: 'shade' },
    ],
    neutralContolLayerDark: [
        { key: 'neutral-control-layer-color-0', weight: 100, type: 'shade' },
        { key: 'neutral-control-layer-color-10', weight: 75, type: 'shade' },
        { key: 'neutral-control-layer-color-20', weight: 60, type: 'shade' },
        { key: 'neutral-control-layer-color-30', weight: 45, type: 'shade' },
        { key: 'neutral-control-layer-color-40', weight: 30, type: 'shade' },
        { key: 'neutral-control-layer-color-50', weight: 15, type: 'shade' },
        { key: 'neutral-control-layer-color-60', weight: 0, type: 'main' },
        { key: 'neutral-control-layer-color-70', weight: 30, type: 'tint' },
        { key: 'neutral-control-layer-color-80', weight: 60, type: 'tint' },
        { key: 'neutral-control-layer-color-90', weight: 90, type: 'tint' },
        { key: 'neutral-control-layer-color-100', weight: 100, type: 'tint' },
    ],
    dividerLight: [
        { key: 'divider-color-10', weight: 70, type: 'tint' },
        { key: 'divider-color-20', weight: 40, type: 'tint' },
        { key: 'divider-color-40', weight: 0, type: 'main' },
        { key: 'divider-color-60', weight: 10, type: 'shade' },
        { key: 'divider-color-80', weight: 30, type: 'shade' },
        { key: 'divider-color-90', weight: 50, type: 'shade' },
    ],
    dividerDark: [
        { key: 'divider-color-10', weight: 40, type: 'shade' },
        { key: 'divider-color-20', weight: 20, type: 'shade' },
        { key: 'divider-color-40', weight: 0, type: 'main' },
        { key: 'divider-color-60', weight: 5, type: 'tint' },
        { key: 'divider-color-80', weight: 15, type: 'tint' },
        { key: 'divider-color-90', weight: 25, type: 'tint' },
    ],
}

export const CTA_COLOR_GROUP = {
    primaryLight: [
        { key: 'primary-cta-color-10', weight: 90, type: 'tint' },
        { key: 'primary-cta-color-20', weight: 70, type: 'tint' },
        { key: 'primary-cta-color-40', weight: 35, type: 'tint' },
        { key: 'primary-cta-color-60', weight: 0, type: 'main' },
        { key: 'primary-cta-color-80', weight: 25, type: 'shade' },
        { key: 'primary-cta-color-90', weight: 40, type: 'shade' },
    ],
    primaryDark: [
        { key: 'primary-cta-color-10', weight: 65, type: 'shade' },
        { key: 'primary-cta-color-20', weight: 55, type: 'shade' },
        { key: 'primary-cta-color-40', weight: 30, type: 'shade' },
        { key: 'primary-cta-color-60', weight: 0, type: 'main' },
        { key: 'primary-cta-color-80', weight: 15, type: 'tint' },
        { key: 'primary-cta-color-90', weight: 30, type: 'tint' },
    ],
    primaryLayer: [
        { key: 'primary-cta-layer-color-10', weight: 0, type: 'tint' },
        { key: 'primary-cta-layer-color-20', weight: 0, type: 'tint' },
        { key: 'primary-cta-layer-color-40', weight: 0, type: 'tint' },
        { key: 'primary-cta-layer-color-60', weight: 0, type: 'main' },
        { key: 'primary-cta-layer-color-80', weight: 0, type: 'tint' },
        { key: 'primary-cta-layer-color-90', weight: 0, type: 'tint' },
    ],
    secondaryLight: [
        { key: 'secondary-cta-color-10', weight: 90, type: 'tint' },
        { key: 'secondary-cta-color-20', weight: 70, type: 'tint' },
        { key: 'secondary-cta-color-40', weight: 35, type: 'tint' },
        { key: 'secondary-cta-color-60', weight: 0, type: 'main' },
        { key: 'secondary-cta-color-80', weight: 25, type: 'shade' },
        { key: 'secondary-cta-color-90', weight: 40, type: 'shade' },
    ],
    secondaryDark: [
        { key: 'secondary-cta-color-10', weight: 65, type: 'shade' },
        { key: 'secondary-cta-color-20', weight: 55, type: 'shade' },
        { key: 'secondary-cta-color-40', weight: 30, type: 'shade' },
        { key: 'secondary-cta-color-60', weight: 0, type: 'main' },
        { key: 'secondary-cta-color-80', weight: 15, type: 'tint' },
        { key: 'secondary-cta-color-90', weight: 30, type: 'tint' },
    ],
    secondaryLayer: [
        { key: 'secondary-cta-layer-color-10', weight: 0, type: 'tint' },
        { key: 'secondary-cta-layer-color-20', weight: 0, type: 'tint' },
        { key: 'secondary-cta-layer-color-40', weight: 0, type: 'tint' },
        { key: 'secondary-cta-layer-color-60', weight: 0, type: 'main' },
        { key: 'secondary-cta-layer-color-80', weight: 0, type: 'shade' },
        { key: 'secondary-cta-layer-color-90', weight: 0, type: 'shade' },
    ],
}

export const AVAILABLE_COLORS_VARIABLES = [
    { key: 'navbar-color', title: 'Sidebar' },
    { key: 'text-color', title: 'Text' },
    { key: 'primary-cta-color', title: 'Primary CTA' },
    { key: 'secondary-cta-color', title: 'Secondary CTA' },
    ...BACKGROUND_COLOR_GROUP,
    ...SIDEBAR_COLOR_GROUP.backgroundLight,
    ...SIDEBAR_COLOR_GROUP.layerLight,
    ...SIDEBAR_COLOR_GROUP.backgroundDark,
    ...SIDEBAR_COLOR_GROUP.layerDark,
    ...TEXT_COLOR_GROUP.dividerLight,
    ...TEXT_COLOR_GROUP.dividerDark,
    ...TEXT_COLOR_GROUP.neutralContolLayerLight,
    ...TEXT_COLOR_GROUP.neutralContolLayerDark,
    ...TEXT_COLOR_GROUP.neutralControlLight,
    ...TEXT_COLOR_GROUP.neutralControlDark,
    ...TEXT_COLOR_GROUP.textColorLight,
    ...TEXT_COLOR_GROUP.textColorDark,
    ...CTA_COLOR_GROUP.primaryLight,
    ...CTA_COLOR_GROUP.primaryDark,
    ...CTA_COLOR_GROUP.primaryLayer,
    ...CTA_COLOR_GROUP.secondaryLight,
    ...CTA_COLOR_GROUP.secondaryDark,
    ...CTA_COLOR_GROUP.secondaryLayer,
]
