import { Symbol } from '../../../../types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SymbolsState {
    list: Symbol[];
    loading: boolean;
}

const initialState: SymbolsState = {
    list: [],
    loading: true,
};

const symbolsSlice = createSlice({
    name: 'symbols',
    initialState,
    reducers: {
        saveSymbols(state, action: PayloadAction<Symbol[]>) {
            state.list = action.payload;
            state.loading = false;
        },
    },
});

export const { saveSymbols } = symbolsSlice.actions;
export const symbolsReducer = symbolsSlice.reducer;
export default symbolsSlice.reducer;
