import React from 'react';
import { SVGICON } from '../../helpers/svgicon';

interface ThemeIconProps {
    classNames?: string;
    theme?: string;
}

export const ThemeIcon: React.FC<ThemeIconProps> = ({ classNames = '', theme = '' }: ThemeIconProps): any => {
    switch (theme) {
        case 'dark':
            return (
                <SVGICON>
                    <svg className={classNames} width="25" height="25" viewBox="0 0 25 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.8542 15.8539L21.7821 16.2268C21.9314 15.855 21.8446 15.4301 21.5613 15.1468C21.278 14.8635 20.853 14.7766 20.4813 14.926L20.8542 15.8539ZM9.14581 4.14551L10.0737 4.51839C10.2231 4.14667 10.1362 3.72168 9.85292 3.4384C9.56964 3.15512 9.14465 3.06824 8.77293 3.21763L9.14581 4.14551ZM20.4813 14.926C19.5613 15.2957 18.5557 15.4997 17.5 15.4997V17.4997C18.8155 17.4997 20.0739 17.2452 21.2271 16.7818L20.4813 14.926ZM17.5 15.4997C13.0817 15.4997 9.5 11.918 9.5 7.49969H7.5C7.5 13.0225 11.9772 17.4997 17.5 17.4997V15.4997ZM9.5 7.49969C9.5 6.44401 9.70396 5.43842 10.0737 4.51839L8.21794 3.77262C7.75452 4.92575 7.5 6.18423 7.5 7.49969H9.5ZM4.5 12.4997C4.5 9.13683 6.57526 6.25627 9.5187 5.07338L8.77293 3.21763C5.09771 4.69459 2.5 8.29257 2.5 12.4997H4.5ZM12.5 20.4997C8.08172 20.4997 4.5 16.918 4.5 12.4997H2.5C2.5 18.0225 6.97715 22.4997 12.5 22.4997V20.4997ZM19.9263 15.481C18.7434 18.4244 15.8629 20.4997 12.5 20.4997V22.4997C16.7071 22.4997 20.3051 19.902 21.7821 16.2268L19.9263 15.481Z"/>
                    </svg>
                </SVGICON>
            );
        case 'light':
            return (
                <SVGICON>
                    <svg className={classNames} width="25" height="25" viewBox="0 0 25 25" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.5 3.5V4.5M12.5 20.5V21.5M21.5 12.5H20.5M4.5 12.5H3.5M18.864 18.864L18.1569 18.1569M6.84315 6.84315L6.13604 6.13604M18.864 6.13609L18.1569 6.8432M6.8432 18.1569L6.13609 18.864M16.5 12.5C16.5 14.7091 14.7091 16.5 12.5 16.5C10.2909 16.5 8.5 14.7091 8.5 12.5C8.5 10.2909 10.2909 8.5 12.5 8.5C14.7091 8.5 16.5 10.2909 16.5 12.5Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </SVGICON>
            );
        default:
            return null;
    }
}
