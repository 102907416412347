import React from 'react';
import { useAppSelector } from '../../providers/ReduxProvider/app/hooks';
import useDarkMode from '../../hooks/useDarkmode';
import { ThemeIcon } from '../../assets/images/Theme';
import classnames from 'classnames';

export interface ThemeSwitcherProps {
    /**
     * Classnames for styles of Switch Theme Button
     */
    switchThemeButtonClasses?: string,
    /**
     * Classnames for changing style of Div Containing ThemeIcon element
     */
    iconContainerClasses?: string,
    /**
     * Color classes for changing colors of div Containing ThemeIcon element
     */
    iconContainerColorClasses?: string,
    /**
     * Classses for changing style of ThemeIcon
     */
    switcherIconClasses?: string,
}

export const ThemeSwitcherWidget: React.FC<ThemeSwitcherProps> = ({
    switchThemeButtonClasses = 'pointer w-20 h-10 p-1 rounded-3xl relative bg-neutral-control-layer-color-10 focus:outline-none',
    iconContainerClasses = 'h-8 w-8 top-1 rounded-3xl absolute items-center flex justify-center duration-200',
    iconContainerColorClasses = 'bg-neutral-control-layer-color-0 text-neutral-control-layer-color-80',
    switcherIconClasses,
}) => {
    const [_, setNewTheme] = useDarkMode();

    const themeSwitcher: string = useAppSelector((state) => state.globalSettings.color)

    const switcherClassname = React.useMemo(() => {
        const cnSwitcher = classnames(iconContainerColorClasses, {
            'left-1': themeSwitcher === 'light',
            'left-11': themeSwitcher === 'dark',
        });

        return cnSwitcher
    }, [themeSwitcher]);

    return (
        <button aria-label="Switch theme button" onClick={setNewTheme} className="pointer w-20 h-10 p-1 rounded-3xl relative bg-neutral-control-layer-color-10 focus:outline-none">
            <div aria-label="themeSwitcher" className={`${switcherClassname} h-8 w-8 top-1 rounded-3xl absolute items-center flex justify-center duration-200`}>
                <ThemeIcon theme={themeSwitcher} classNames={switcherIconClasses}/>
            </div>
        </button>
    );
};

export default ThemeSwitcherWidget;
