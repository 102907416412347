import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { RootState, useAppSelector } from 'web-sdk';
import { Dropdown, DropdownItem } from 'web-sdk/components';
import getConfigs from 'web-sdk/configs/app';
import { DEFAULT_LANG } from 'web-sdk/constants';
import { isBrowser } from 'web-sdk/helpers';

export interface LanguageSelectorWidgetProps {
    /**
     * Classes for button
     */
    buttonClassName?: string;
    /**
     * Classes for list option items
     */
    optionColorClassName?: string;
    /**
     * Classes for selected list option item
     */
    optionColorSelectedClassName?: string;
    /**
     * Classes for active list option item
     */
    optionColorActiveClassName?: string;
    /**
     * Classes for list options
     */
    listOptionClassName?: string;
    /**
     * Color for selector icon
     */
    selectorIconColor?: string;
}

export const LanguageSelectorWidget: React.FC<LanguageSelectorWidgetProps> = ({
    buttonClassName = 'relative w-full text-text-color-90 bg-input-background-color border border-divider-color-20 rounded-md shadow-sm pl-3 pr-12 text-left cursor-default focus:outline-none',
    optionColorClassName = 'text-neutral-control-layer-color-70',
    optionColorSelectedClassName = 'bg-primary-cta-color-10 text-neutral-control-layer-color-70',
    optionColorActiveClassName = 'text-neutral-control-layer-color-100 bg-neutral-control-color-20',
    listOptionClassName = 'bg-dropdown-background-color shadow-sm border border-divider-color-20',
    selectorIconColor = 'text-gray-400',
}: LanguageSelectorWidgetProps) => {
    const router = useRouter()
    const { t } = useTranslation('common')
    const article = useAppSelector((state: RootState) => state.article.article)
    const assetArticle = useAppSelector((state: RootState) => state.assetArticle.article)
    const category = useAppSelector((state: RootState) => state.category.category)
    const tag = useAppSelector((state: RootState) => state.tag.tag)

    const [dropdownSelected, setDropdownSelected] = useState<DropdownItem>({
        id: DEFAULT_LANG,
        value: t(
            `page.body.settings.rows.options.languages.${DEFAULT_LANG}`
        ),
    });

    useEffect(() => {
        if (isBrowser()) {
            const selectedLanguage =router.locale || DEFAULT_LANG;

            setDropdownSelected({
                id: selectedLanguage,
                value: t(
                    `page.body.settings.rows.options.languages.${selectedLanguage}`
                ),
            });
        }
    }, []);

    const findLocalePath = useCallback((pathname: string, asPath: string, toLocale: string) => {
        let resultAsPath = asPath

        switch (pathname) {
            case '/news/[slug]':
                const localizedArticle = article.localizations?.find(l => l.locale === toLocale)

                if (localizedArticle) {
                    resultAsPath = `/news/${localizedArticle.slug}`
                }
                break
            case '/asset/[code]/[type]/[slug]':
                const localizedAssetArticle = assetArticle.localizations?.find(l => l.locale === toLocale)

                if (localizedAssetArticle) {
                    resultAsPath = [...asPath.split('/').slice(0, -1), localizedAssetArticle.slug].join('/')
                }
                break
            case '/assets/[categoryID]':
                const localizedCategory = category.localizations?.find(l => l.locale === toLocale)

                if (localizedCategory) {
                    resultAsPath = `/assets/${localizedCategory.category}`
                }
                break
            case '/tag/[tag]':
                const localizedTag = tag.localizations?.find(l => l.locale === toLocale)

                if (localizedTag) {
                    resultAsPath = `/tag/${localizedTag.slug}`
                }
                break
            default:
                break
            }

        return resultAsPath
    }, [article, assetArticle, category, tag])

    const handleChangeLanguage = useCallback((item: any) => {
        window.localStorage.setItem('locale', item.id.toLowerCase());
        window.dispatchEvent(new Event('changeLanguage'));
        setDropdownSelected({
            id: item.id,
            value: t(
                `page.body.settings.rows.options.languages.${item.id}`
            ),
        });

        const asPath = findLocalePath(router.pathname, router.asPath, item.id.toLowerCase())

        router.push(router.pathname, asPath, { locale: item.id.toLowerCase() })
        .then(() => {
            window.location.reload();
        });
    }, [router, findLocalePath]);

    const dropdownList = () => {
        return getConfigs().languages.map<DropdownItem>((lang: string) => {
            return {
                id: lang,
                value: t(
                    `page.body.settings.rows.options.languages.${lang}`
                ),
            };
        });
    };

    return (
        <Dropdown
            list={dropdownList()}
            selected={dropdownSelected}
            onSelect={handleChangeLanguage}
            buttonClassNames={buttonClassName}
            optionColorClass={optionColorClassName}
            optionColorSelectedClass={optionColorSelectedClassName}
            optionColorActiveClass={optionColorActiveClassName}
            listOptionClassName={listOptionClassName}
            selectorIconColor={selectorIconColor}
            checkIconOnSelected={false}
            testLabel="LanguageSelect"
        />
    );
};
